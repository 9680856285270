import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {Card as MuiCard} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import axios from "axios";
import HorizontalSubMenuComponent from "./HorizontalSubMenuComponent";
import {getBaseUrlNodeServer} from "../../config";

const fileSizeLimitInMb = 4.0

const Contacts = () => {
	const verifiedemail = sessionStorage.getItem("verifiedemail");
	const [isLoading, setIsLoading] = useState(true);
	const [userContacts, setuserContacts] = useState([]);
	const [userData, setUserData] = useState([
			{'title': 'Your Saved Contacts', 'value': 0},
			{'title': 'New Contacts Added', 'value': 0},
		]
	);
	
	function fillTableWithData(data, fileInfo) {
		if (fileInfo.size / (1024 * 1024) <= fileSizeLimitInMb) {
			// console.log('File Size ', fileInfo.size / (1024 * 1024))
			// console.log('I have the data here: ', fileInfo)
			// console.log('I have the data here: ', data)
			// setData(data)
		} else {
			console.log('File Size more than ' + fileSizeLimitInMb + ' Mb')
		}
	}
	
	useEffect(() => {
		async function updateContacts() {
			try {
				axios.defaults.baseURL = getBaseUrlNodeServer()
				const response = await axios.post('getusercontacts', {'verifiedemail': verifiedemail})
				const usercontacts = response.data
				setuserContacts(usercontacts);
				setIsLoading(false);
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		updateContacts();
	}, userContacts);
	
	if (isLoading) {
		return <div style={{minHeight: '1000px', textAlign: 'center', marginTop: '50px', marginLeft: "50px"}}> .... </div>
	} else {
		return (<div style={{minHeight: '1000px', textAlign: 'center', marginTop: '10px', marginLeft: "50px"}}>
				<Grid container spacing={20}>
					{/*{userData.map((item, index) => (*/}
					{/*	<Grid item xs={8} sm={4} md={2} key={index}>*/}
					{/*		<MuiCard sx={{*/}
					{/*			width: 200,*/}
					{/*			margin: 0,*/}
					{/*			height: 90,*/}
					{/*			marginLeft: 0,*/}
					{/*			backgroundColor: "rgb(133,201,231)",*/}
					{/*			boxShadow: "5px 5px 10px rgba(0,0,0,0.2)"*/}
					{/*		}}>*/}
					{/*			<CardContent>*/}
					{/*				<Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>*/}
					{/*					{item.title}*/}
					{/*				</Typography>*/}
					{/*				<Typography variant="h5" component="h2">*/}
					{/*					{item.value}*/}
					{/*				</Typography>*/}
					{/*			</CardContent>*/}
					{/*		</MuiCard>*/}
					{/*	</Grid>*/}
					{/*))}*/}
					<Grid item xs={8} sm={4} md={2}>
						<MuiCard sx={{
							width: 200,
							margin: 0,
							height: 90,
							marginLeft: 0,
							backgroundColor: "white",
							boxShadow: "1px 1px 1px rgba(0,0,0,0.2)",
						}}>
							<CardContent>
								<Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
									Your Saved Contacts
								</Typography>
								<Typography variant="h5" component="h2" style={{color: "purple"}}>
									{userContacts.length}
								</Typography>
							</CardContent>
						</MuiCard>
					</Grid>
				</Grid>
				<HorizontalSubMenuComponent userContacts={userContacts}/>
			</div>
		)
	}
};

export default Contacts;
