// @ts-ignore
import {Envelope} from '@phosphor-icons/react'
import {Input} from '../atoms/Input'
import {Button} from '../atoms/Button'
import {RegisterModalTexts} from '../particles/DataLists'
import * as React from 'react'
import {useEffect} from 'react'
import axios from "axios";
import {getAxiosBaseUrl} from "../../config";
// @ts-ignore
import {toast} from "react-toastify";

export const RegisterForm = ({onClose}) => {
    function onCloseInternal() {
        document.removeEventListener('keydown', handleEsc);
        onClose();
    }

    const handleEsc = (event: { keyCode: number; }) => {
        if (event.keyCode === 27) {
            onCloseInternal();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleEsc);
    }, []);

    function validEmailAddress(email: String) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function onSubmit(event: { preventDefault: () => void }) {
        event.preventDefault();
        // @ts-ignore
        const registrationEmail = document.getElementById('registrationEmail').value.trim()

        if (validEmailAddress(registrationEmail)) {
            axios.defaults.baseURL = getAxiosBaseUrl();
            axios.post('/onboard_email', {'user_email': registrationEmail})
                .then(response => {
                        toast.success(response.data.identities)
                    }
                ).catch(err => console.log(err));
        } else {
            toast.error("Invalid Email Entered", registrationEmail)
        }
    }
    return (
        <form className='flex flex-col gap-4' onSubmit={onSubmit}>
            <div className='flex md:flex-row flex-col gap-2' style={{justifyContent: 'center'}}>
                <Input id='registrationEmail' containerClass='relative'
                       inputClass='border rounded-lg outline-none  h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1'
                       style={{width: "300px"}}
                       type="email" placeholder={RegisterModalTexts.emailPlaceholderText} required={true}>
                    <div className='absolute top-4 left-3 text-color3/80'>
                        <Envelope size={18} color="currentColor" weight="fill"/>
                    </div>
                </Input>
            </div>
            <div className='flex md:flex-row flex-col justify-center gap-2'>
                <Button type='submit'
                        className='border outline-none bg-color1 py-2 px-6 text-white font-light text-base rounded-lg'>
                    {RegisterModalTexts.buttonText}
                </Button>
            </div>
            <div className='flex md:flex-row flex-col justify-center gap-2'>
                <p>Go to your gmail / email / outlook etc. client and check for an email from Amazon.com AWS click on
                    verification link.</p>
            </div>
        </form>
    )
}
