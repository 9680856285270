import {EmailEditor, EmailEditorProvider} from "easy-email-editor";
import {MjmlToJson, StandardLayout} from "easy-email-extensions";
import React, {useEffect, useState} from "react";
import {BasicType, BlockManager, JsonToMjml} from "easy-email-core";
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from "@mui/material";
import axios from "axios";
import {getAxiosBaseUrl, getBaseUrlNodeServer} from "../../config";
import {SigninForm} from "../molecules/SigninForm";
import CategorySelectionButtons from "../Campaign/CategorySelectionButtons";

export default function EditorAlone(props) {
	var signedin = sessionStorage.getItem("signedin");
	const isAdmin = sessionStorage.getItem('isAdmin');
	
	var hostPath = window.location.host
	hostPath = hostPath.replace(/:.*/, "");
	const {templateId} = useParams();
	const navigate = useNavigate();
	const [parentDocId, setparentDocId] = useState(templateId);
	const [mjmlString, setMjmlString] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	
	const [initialValues, setInitialValues] = useState({
		subject: 'Welcome to MailChamp.Ai',
		subTitle: '',
		content: BlockManager.getBlockByType(BasicType.PAGE).create({})
	});
	const [subject, setSubject] = useState('');
	
	function setMjmlStrAndSubject(values) {//:IEmailTemplate
		//The following two blocks of code requires investigation otherwise our code could be shut down by these guys??
		const mjmlString = JsonToMjml({
			data: values.content, mode: 'production', context: values.content
		});
		setMjmlString(mjmlString)
		setSubject(values.subject)
	}
	
	function setMjmlStrAndSubject(values) {//:IEmailTemplate
		//The following two blocks of code requires investigation otherwise our code could be shut down by these guys??
		const mjmlString = JsonToMjml({
			data: values.content, mode: 'production', context: values.content
		});
		setMjmlString(mjmlString)
		setSubject(values.subject)
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		async function getUserSelectedTemplate() {
			try {
				if (parentDocId == null || parentDocId == undefined) {
					setInitialValues(initialValues)
				} else {
					setparentDocId(templateId);
					const response = await fetch(getBaseUrlNodeServer() + 'getusertemplate/' + parentDocId, {
						method: 'post', headers: {'Content-Type': 'application/json'}
					})
					const mgmlTxt1 = await response.json();
					const mgmlTxt = mgmlTxt1[0];
					const pageData = MjmlToJson(mgmlTxt.content);
					const subject = mgmlTxt.subject;
					if (mgmlTxt.selectedCategories != undefined) {
						setSelectedCategories(mgmlTxt.selectedCategories)
					}
					setInitialValues({
						subject: subject, subTitle: '', content: pageData
					});
				}
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		
		getUserSelectedTemplate();
	}, initialValues);
	
	const sendTestEmail = async () => {
		saveUserTemplate(); //Always save template before sending the test email
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('/emailcampaign', {
			templateIdSelected: parentDocId,
			sendToList: sessionStorage.getItem("verifiedemail"),
			sendFrom: 'admin@mailchamp.ai'
		})
		.then(response => {
			}
		).catch(err => console.log(err));
	}
	const saveUserTemplate = async () => {
		var verifiedemail = sessionStorage.getItem("verifiedemail");
		const mjmlStr = {mjmlString}.mjmlString
		const subject1 = {subject}.subject
		if (parentDocId == null) {
			//Create a new template
			try {
				const response = await fetch(getBaseUrlNodeServer() + 'createtemplate', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({
						'mjmlStr': mjmlStr,
						'subject': subject1,
						'verifiedemail': verifiedemail
					})
				})
				const result = await response.json();
				setparentDocId(result.insertedId);
				navigate('/editor/' + result.insertedId);
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		} else {
			try {
				await fetch(getBaseUrlNodeServer() + 'saveusertemplate', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({
						'mjmlStr': mjmlStr,
						'subject': subject1, 'templateId': {parentDocId: parentDocId},
						'verifiedemail': verifiedemail,
						'selectedCategories': selectedCategories
					})
				})
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		// window.location.reload();
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (
			<div style={{minHeight: '1000px'}}>
				<div style={{width: "85vw", marginLeft: '0px'}}>
					<EmailEditorProvider data={initialValues}
						// height={'calc(100vh - 72px)'}
						                 autoComplete
						                 dashed={true}>
						{({values}) => {
							return (<StandardLayout
								// compact={!smallScene}
								compact={!false}
								showSourceCode={true}
								onClick={setMjmlStrAndSubject(values)} //To Save the mjmlstring to the state variable for persisting to database
							>
								<EmailEditor/>
							</StandardLayout>);
						}}
					</EmailEditorProvider>
				</div>
				<div style={{marginTop: '0px', textAlign: 'center'}}>
					<label>Template Id: {parentDocId ? parentDocId : '-'}</label>
				</div>
				<div style={{textAlign: 'center'}}>
					<h6>Subject: {initialValues.subject}</h6>
				</div>
				{/*, display: 'block'*/}
				<div style={{textAlign: 'center'}}>
					<div
						style={{
							float: "left",
							display: isAdmin ? 'block' : 'none',
							marginTop: '10px',
							marginLeft: '20px',
							maxWidth: "500px",
							overflow: "scroll"
						}}>
						<CategorySelectionButtons selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} singleSelectionOnly={false}>
						</CategorySelectionButtons></div>
					<div style={{display: isAdmin ? 'flex' : 'flow'}}><Button id='savemydesign'
					                                                          className={"btn"}
					                                                          onClick={saveUserTemplate}
					                                                          style={{
						                                                          backgroundColor: "beige",
						                                                          lineHeight: "1.5",
						                                                          marginLeft: '20px',
						                                                          border: '2px solid green'
					                                                          }}
					>Save My Design
					</Button>
						<Button id='SendTestEmail' className={"btn"} onClick={sendTestEmail}
						        style={{backgroundColor: "beige", lineHeight: "1.5", marginLeft: '20px', border: '2px solid green'}}
						>Send Test Email
						</Button>
					</div>
				</div>
			</div>)
	}
}