import React, {useState} from 'react';

import './imagecomp.css'
import {toast} from 'react-toastify';
import Modal from './Modal'; // Adjust the path as necessary

const ImageComponent = ({imageName}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const imageUrl = `http://localhost:8080/image/${imageName}`;
	const handleImageClick = () => {
		setIsModalOpen(true);
	};
	
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};
	return (
		<div style={{borderRadius: '20px'}}>
			<img src={imageUrl} alt={imageName} style={{
				maxHeight: '300px',
				borderRadius: '20px', marginRight: '20px', cursor: 'pointer'
			}}
			     alt="Clickable" onClick={handleImageClick}
			/>
			<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
				<img src={imageUrl} alt="In Modal"
				     style={{maxHeight: '700px'}}
				/>
			</Modal>
			
			<code onClick={() => {
				navigator.clipboard.writeText(imageUrl);
				toast.success('Copied To Clipboard');
			}} style={{
				maxHeight: '300px', borderRadius: '0px', maxWidth: '300px',
				wordWrap: 'break-word', overflowWrap: 'break-word', color: 'grey', marginRight: '20px',
				display: 'flow', flexWrap: 'wrap'
			}}>{imageUrl}
			</code>
			{/*<p>ImageName: {imageName}</p>*/}
		</div>
	);
};

export default ImageComponent;
