function DropdownList({labelText, selectedValue, setSelectedValue, options, defaultValue = 'None'}) {
	const handleSelectChange = (e) => {
		setSelectedValue(e.target.value);
	};
	
	return (<div style={{maxWidth: "500px", marginTop: "10px", display: "flex", marginLeft: '0px'}}>
			<div style={{border: "none", marginRight: "20px", marginTop: "4px"}}>{labelText}</div>
			<div style={{border: '1px solid lightgrey', borderRadius: '5px', minWidth: '150px', padding: "2px"}}>
				<select value={selectedValue} onChange={handleSelectChange} style={{width: "100%", textAlign: "center"}}>
					<option value="">{defaultValue}</option>
					{options.map((option) => (<option key={option.value} value={option.value}>
						{option.label}
					</option>))}
				</select>
			</div>
			{/*<p>Selected Value: {selectedValue}</p>*/}
		</div>
	);
}

export default DropdownList;
