import React, {useEffect, useState} from 'react';
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";

const AddLeads = ({currentCampaign, setParentSelectedContacts, parentSelectedContacts}) => {
	
	const verifiedemail = sessionStorage.getItem("verifiedemail");
	const [usercontacts, setusercontacts] = useState([]);
	const [selectedIndexes, setselectedIndexes] = useState({});
	const [isLoading, setisLoading] = useState(true);
	// if (!isLoading) {
	// 	document.getElementById('selectedCount').innerText = getValidSectedContactsCount();
	// }
	useEffect(() => {
		if (!isLoading) {
			document.getElementById('selectedCount').innerText = getValidSectedContactsCount();
		}
	}, [isLoading]);
	
	useEffect(() => {
		const initialSelectedIndexes = {};
		if (parentSelectedContacts != undefined) {
			parentSelectedContacts.forEach((contact, index) => {
				const contactIndex = usercontacts.findIndex(c => c._id === contact._id);
				if (contactIndex !== -1) {
					initialSelectedIndexes[contactIndex] = true;
				}
			});
			setselectedIndexes(initialSelectedIndexes);
			Object.keys(initialSelectedIndexes).forEach(rowIndex => {
				document.getElementsByName(rowIndex.toString())[0].style['background-color'] = getBackgroundColor(rowIndex);
			})
		}
	}, [parentSelectedContacts, usercontacts]);
	
	useEffect(() => {
		async function getCampaigns() {
			try {
				axios.defaults.baseURL = getBaseUrlNodeServer();
				axios.post('getusercontacts', {'verifiedemail': verifiedemail}).then(response => {
						const usercontacts = response.data
						setusercontacts(usercontacts);
						setisLoading(false);
					}
				).catch(err => console.log(err));
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		
		getCampaigns();
	}, []);
	
	// useEffect(() => {
	// 	async function changeColorFunction() {
	//
	// 	}
	// 	changeColorFunction();
	// }, selectedIndexes);
	
	// function getBackgroundColor(rowIndex) {
	const getBackgroundColor = (rowIndex) => {
		try {
			if (selectedIndexes[rowIndex] === true) {
				return '#bcc0c0'
			} else {
				return 'white'
			}
		} catch (e) {
			return 'white'
		}
	}
	
	function getValidSectedContactsCount() {
		return Object.keys(selectedIndexes).filter(key => selectedIndexes[key] === true).length;
	}
	
	function getValidSectedContacts() {
		return Object.keys(selectedIndexes).filter(key => selectedIndexes[key] === true).map(key => usercontacts[key])
	}
	
	
	function clickedContact(rowIndex) {
		// usercontacts[rowIndex]
		if (rowIndex in selectedIndexes) {
			selectedIndexes[rowIndex] = !selectedIndexes[rowIndex]
			setselectedIndexes(selectedIndexes);
		} else {
			if (selectedIndexes === undefined) {
				const selectedIndexes = {}
				selectedIndexes[rowIndex] = true;
				setselectedIndexes(selectedIndexes);
			} else {
				selectedIndexes[rowIndex] = true
				setselectedIndexes(selectedIndexes);
			}
		}
		document.getElementsByName(rowIndex.toFixed())[0].style['background-color'] = getBackgroundColor(rowIndex);
		document.getElementById('selectedCount').innerText = getValidSectedContactsCount();
		setParentSelectedContacts(getValidSectedContacts());
	}
	
	
	return (
		<div style={{
			backgroundColor: 'white', width: '800px', height: '700px', borderRadius: '30px', textAlign: 'center', overflow: 'scroll'
		}}>
			<h5 style={{marginTop: '20px'}}>Add Leads</h5> <span
			style={{color: 'grey'}}>Campaign Name: {currentCampaign.campaignname}</span>
			<h6>Total Contacts Added: <label id='selectedCount'></label></h6>
			{usercontacts.map((contact, rowIndex) => (
				<div name={rowIndex} key={rowIndex} title="Click to Add Lead" onClick={() => clickedContact(rowIndex)}
				     style={{maxWidth: '700px', borderRadius: '50px', display: 'inline-block', marginTop: '5px'}}>
					<label className="table-cell" style={{marginRight: '100px'}}> {contact.Email}</label>
					<label className="table-cell"> {contact['First Name']}</label>
					<label className="table-cell"> {contact['Last Name']}</label>
					<label className="table-cell"> {contact['Position']}</label>
				</div>
			))}
		</div>)
}
export default AddLeads;