import React, {useEffect, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import {getAxiosBaseUrl} from "../../config";
import axios from "axios";
import Button from '@mui/material/Button';

export default function AdminPanel({verifiedemail}) {
	var signedin = sessionStorage.getItem("signedin");
	const [userData, setUserData] = useState([]);
	
	async function onClickShowStats() {
		try {
			axios.defaults.baseURL = getAxiosBaseUrl()
			const response = await axios.post('fetchusersinfo', {'verifiedemail': verifiedemail})
			const result = await response;
			// console.log(result.data);
			setUserData(result.data)
		} catch (error) {
			console.error('Error during fetch:', error.message);
		}
	}
	useEffect(() => {
		async function fetchUsersInformation() {
		}
		
		fetchUsersInformation();
	}, []);
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div style={{marginTop: "20px", marginLeft: "20px", minHeight: "900px"}}>
			<div>
				Motto: Never Stop Building, never stop pushing forward, no matter what
				<p>In Work:
					<li>2nd order chat stroage (ex. msg continuity, state management full, use elasticsearch if needed)</li>
					<li>Save chat history / sessions / longest in the market</li>
					<li>Save chat history / sessions / longest in the market</li>
					<li>B2C personal assistant kind of thing, personas, meeting setups etc.</li>
					<li>Organization representative chat bot for answering key questions</li>
					<br/>
					<hr/>
					{/*<li><s>Fix the side menu clicked or unclicked state</s></li>*/}
					<li>CAN-SPAM Compliance is MUST</li>
					<a>https://www.cyberoptik.net/blog/can-spam-act-the-us-anti-spam-laws/#:~:text=Every%20individual%20email%20that%20violates,to%20serve%20time%20behind%20bars.</a>
					<li><s>Fix the side menu clicked or unclicked state</s></li>
					<li><s>Buttons in all templates page for category and give user ability to select the categories (could be removed in the future)
						search button</s></li>
					<li><s>Edit delete button on hover on the templates in the saved templates and all templates page</s></li>
					<li>Email rendering replace {"{fname}"}, {"{lname}"} in the email templates</li>
					<li>Unsubscribe button in all the emails</li>
					<li>Generate templates with help of AI, it's a must that will help establish the variety of capabilities</li>
					<li>Session across tabs</li>
				</p>
				<p>TODO:
					<li>Frameworks frameworks frameworks has the $$$$$$$</li>
					<li>Add more automations</li>
					<li>Landing page capability for clients to garner the leads / lead magnets</li>
					<li>Make buttons active / inactive based on the state</li>
					<li>MailChamp logo and branding in all free emails at the footer of the email</li>
					<li>Prevent sending emails to bounced emails / unsubscribed emails, test the scenarios</li>
					<li>User hotness score, premium feature</li>
					<li>Auto sending at the optimal times</li>
					<li>User segmentation and dna creation</li>
					<li>Template Score based on the algorithm - Good Template, Heavy Template, Bad Template</li>
					<li>Show guidelines at the template creation page</li>
					<li>Prevent repeated sending to a client within 7 days (must be more than 7 days to be able to resend to such a sender-client
						pair)
					</li>
					<li>Contact list creation and sending to the list capability</li>
					<li>Area based list crawling capability and saving the contacts information</li>
					<li>Add credate to the user creation process</li>
					<li>Add last login to the user data</li>
					<li>Excel sheet upload feature</li>
					<li>Crawling data for brham??</li>
					<li>Campaign level dashboards</li>
					<li>Single email for signup (1 link for amazon verification, 1 link for quantus verification)</li>
					<li>Media upload in the template page and showing the capability to link those images</li>
					<li>Essential templates saved per account when they verify (ex. registration template to clients)</li>
					<li>AB Testing / multiple headlines emails creation and results dashboard per campaign to make a decision to go next what to do
					</li>
					<li>API capability for transactional email integration (Satya could be a candidate for this)</li>
					<li></li>
					<li>Fetch Stats (Sent, good standing) from Amazon AWS</li>
					<li>Admin should be able to save the template for personal and default account (for everyone)</li>
					<li>Move the functionality of admin checking to app.js so that anyone assigned admin can do the required things</li>
					<li>Share template feature, load template feature from file</li>
					<li>LLM integration and advancd feature</li>
					<li>Per campaign stats, stash campaign for later, fix the bug in delete campaign process showing higher number of emails</li>
					<li>Enable account functionalities</li>
					<li>Speed up every page (admin panel, dashboard etc.)</li>
					<li>Refine, restructure the code</li>
				</p>
				<br/>
				<Button style={{backgroundColor: "grey", color: "white"}} onClick={() => onClickShowStats()}>Load Stats from DB</Button>
				<br/><br/>
				Users Info: Total {userData.length}, Confirmed: {userData.filter(record => record.confirmed == '1').length},
				NotConfirmed: {userData.filter(record => record.confirmed == '0').length}
				<div style={{
					border: "1px solid grey", borderRadius: "20px", padding: "20px", maxWidth: "1000px",
					textAlign: "center"
				}}>
					<p style={{color: "grey", marginBottom: "10px"}}>Confirmed</p>
					<table>
						<tr>
							<td>Row</td>
							<td>Email</td>
							<td>Status</td>
							<td>VerificationId</td>
							<td>CreDate</td>
							<td>Emails</td>
							<td>Open</td>
							<td>Bounce</td>
							<td>Click</td>
							<td>Other</td>
							<td>templateCount</td>
						</tr>
						{userData.filter(record => record.confirmed == '1').map((record, index) => {
							return (<tr style={{backgroundColor: "lightgrey", height: "30px"}}>
								<td>{index + 1}</td>
								<td>{record.email}</td>
								<td>{record.confirmed}</td>
								<td>{record.verificationId}</td>
								<td>{record.creDate}</td>
								<td>{record.emailsDataLen}</td>
								<td>{record.openCount}</td>
								<td>{record.bounceCount}</td>
								<td>{record.clickCount}</td>
								<td>{record.otherCount}</td>
								<td>{record.templateCount}</td>
							</tr>)
						})}
					</table>
					<br/>
					<hr/>
					<br/>
					<p style={{color: "grey", marginBottom: "10px"}}>Not Confirmed</p>
					<table>
						<tr>
							<td>Row</td>
							<td>Email</td>
							<td>Status</td>
							<td>VerificationId</td>
							<td>CreDate</td>
						</tr>
						{userData.filter(record => record.confirmed == '0').map((record, index) => {
							return (<tr>
								<td>{index + 1}</td>
								<td>{record.email}</td>
								<td>{record.confirmed}</td>
								<td>{record.verificationId}</td>
								<td>{record.creDate}</td>
							</tr>)
						})}
					</table>
				</div>
			</div>
		</div>)
	}
}