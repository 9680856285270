import React, {useEffect, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import axios from "axios";
import {getAxiosBaseUrl, getBaseUrlNodeServer} from "../../config";
import './whatsapp.css'
import whatsappBkgImage from '../../assets/whatsappbackground.png'
import './editor.css'
import ImageList from "@mui/material/ImageList";

export default function WhatsappTemplates() {
	var signedin = sessionStorage.getItem("signedin");
	const [msgLog, setmsgLog] = useState([]);
	const accessToken = 'EAAZAQxne5hCMBO1AcVXhG0j0WQizOfGS5W14eBHnppxtutM4EZBZAPW6WayaAXCG2z2FuewilZAMEgGafdH2XCtsIElEgMLf75voetZCZCXoFcZAMPCmalIiQayZB4USZArQLCE5fQ6NxkhITZACA5iep5ZCInVyQstb8uGSMgXnYpCXXWt60OAuMCdR34ycgxyYqZCkoWBUglbg7Nz7eP46FulXoKyhZBnUZD'
	const [templateData, settemplateData] = useState([]);
	
	function sendWhatsappMsg() {
		const sendToNumber = document.getElementById('sendToPhoneNo').value
		// const templateName = 'hello_world'
		const templateName = 'sdnewspublished'
		try {
			axios.defaults.baseURL = getBaseUrlNodeServer()
			axios.post('sendWhatsappMsgTemplate', {
				'accessToken': accessToken, 'sendToNumber': sendToNumber, 'templateName': templateName
			}).then(response => {
				const responseData = response.data
				// console.log('responseData', responseData);
			}).catch(err => console.log(err));
		} catch (error) {
			console.error('Error during fetch:', error.message);
		}
	}
	const fetchDataFromMongoDB = async () => {
		const response = await fetch(getBaseUrlNodeServer() + 'getWhatsappTemplates', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({accessToken: accessToken, whatsappBusinessAccountId: '243644282146980'})
		})
		const tmpltData = await response.json()
		// console.log(tmpltData)
		// Use Promise.all to await all the async operations inside map
		const outputArray = await Promise.all(tmpltData.map(async (rec) => {
			axios.defaults.baseURL = getAxiosBaseUrl();
			const result = await axios.post('/gethtmlfromwhatsapptemplate', {'templateTextArr': rec.components});
			const htmlArray = result.data.msg;
			return {'id': rec.id, 'name': rec.name, 'status': rec.status, 'components': rec.components, 'htmlArray': htmlArray};
		}));
		settemplateData(outputArray)
	};
	
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	
	useEffect(() => {
		fetchDataFromMongoDB()
		// const intervalId = setInterval(fetchDataFromMongoDB, 20000); // Polling every 5 seconds
		// Cleanup the interval when the component unmounts
		// return () => clearInterval(intervalId);
	}, []);
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div style={{marginLeft: "10px", marginTop: "10px"}}>
			Approved Templates
			<hr style={{marginTop: "5px", marginBottom: "10px"}}/>
			{/*<div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", gap: "10px"}}>*/}
			<ImageList sx={{width: '100%', height: "100%", marginTop: "5px"}} cols={4}>
				{templateData.map((rec, index) => (
					<div key={rec.id} id={`element-${index}`} style={{
						marginTop: "0px", maxHeight: "400px", overflow: "scroll",
						minWidth: "300px", marginLeft: "10px"
					}}>
						{/*--- {rec.id}*/}
						<p style={{textAlign: "center"}}>{capitalizeFirstLetter(rec.name)}
							{/*<span style={{fontSize: "10px", marginLeft: "50px"}}>{rec.status}</span>*/}
						</p>
						<div id='specialbuttonContainer' title='Click to Edit' style={{
							display: "flex",
							border: "1px solid grey",
							borderRadius: "20px",
							padding: "20px",
							maxWidth: "400px",
							overflow: "scroll",
							// maxHeight: "400px",
							backgroundImage: `url(${whatsappBkgImage})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							// transform: "scale(0.8)",
							verticalAlign: "top"
						}}>
							<div>
								<div
									style={{backgroundColor: "white", padding: "10px"}}
									dangerouslySetInnerHTML={{__html: rec.htmlArray.join(" ").replace("\n", "<br/>")}}
								/>
								{/*<div id='specialbutton' style={{overflow: "scroll", justifyContent: "center"}}>*/}
								{/*	<label id='specialbuttonChild'*/}
								{/*	       style={{backgroundColor: "green"}}*/}
								{/*	       onClick={() => userWantsToEdit(templateId)}>*/}
								{/*		Edit</label>*/}
								{/*</div>*/}
							</div>
						</div>
					</div>
				))}
				{/*</div>*/}
			</ImageList>
			
			{/*<div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))", gap: "20px"}}>*/}
			{/*	{templateData.map((rec) => (*/}
			{/*		<div key={rec.id} style={{marginTop: "20px"}}>*/}
			{/*			<p>{rec.name} {rec.status} --- {rec.id}</p>*/}
			{/*			<div style={{*/}
			{/*				border: "1px solid grey",*/}
			{/*				borderRadius: "20px",*/}
			{/*				padding: "20px",*/}
			{/*				maxWidth: "400px",*/}
			{/*				backgroundImage: `url(${whatsappBkgImage})`,*/}
			{/*				backgroundRepeat: "no-repeat",*/}
			{/*				backgroundSize: "cover"*/}
			{/*			}}>*/}
			{/*				<div*/}
			{/*					style={{backgroundColor: "white", padding: "10px"}}*/}
			{/*					dangerouslySetInnerHTML={{__html: rec.htmlArray.join(" ").replace("\n", "<br/>")}}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	))}*/}
			{/*</div>*/}
			
			{/*{templateData.map(rec => {*/}
			{/*		return (*/}
			{/*			<div style={{marginTop: "20px"}}>*/}
			{/*				<p>{rec.name} {rec.status} --- {rec.id}</p>*/}
			{/*				<div style={{*/}
			{/*					border: "1px solid grey", borderRadius: "20px", padding: "20px",*/}
			{/*					maxWidth: "400px",*/}
			{/*					backgroundImage: `url(${whatsappBkgImage})`,*/}
			{/*					backgroundRepeat: "no-repeat",*/}
			{/*					backgroundSize: "cover"*/}
			{/*				}}>*/}
			{/*					<div*/}
			{/*						style={{backgroundColor: "white", padding: "10px"}}*/}
			{/*						dangerouslySetInnerHTML={{__html: rec.htmlArray.join(" ").replace("\n", "<br/>")}}>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		)*/}
			{/*	}*/}
			{/*)}*/}
		</div>)
	}
}