import React, {useEffect, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import {getBaseUrlNodeServer} from "../../config";
import './whatsapp.css'
import './editor.css'
import WhatsappPreview from "./TemplateBuilder/WhatsappPreview";
import {useNavigate} from "react-router-dom";


export default function MyTemplates() {
	const navigate = useNavigate();
	var signedin = sessionStorage.getItem("signedin");
	const [templateData, settemplateData] = useState([]);
	
	const fetchDataFromMongoDB = async () => {
		const response = await fetch(getBaseUrlNodeServer() + 'getMyWhatsappTemplates', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({'verifiedemail': sessionStorage.getItem("verifiedemail")})
		})
		const tmpltData = await response.json();
		settemplateData(tmpltData);
	};
	useEffect(() => {
		fetchDataFromMongoDB()
	}, []);
	
	function handleClickOnTemplate(tmplt) {
		// console.log('I got here', tmplt._id);
		navigate('/WhatsappEditor/' + tmplt._id);
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div id="mytemplateswhatsapp" title="Click to Edit" style={{marginLeft: "10px", marginTop: "10px", display: "inline-flex"}}>
			{templateData.map(tmplt => {
				return (<div style={{maxWidth: "410px"}} onClick={() => handleClickOnTemplate(tmplt)}>
					<WhatsappPreview inputValue={tmplt.inputValue} inputBodyValue={tmplt.inputBodyValue} inputFtrValue={tmplt.inputFtrValue}
					                 qckBtnText={tmplt.qckBtnText}
					/>
				</div>)
			})}
		</div>)
	}
}