import React, {useState} from "react";

export default function CallToActionButton({
	                                           uniqueKey,
	                                           uniqueKeyUrl,
	                                           deleteCurrentButton,
	                                           handleInputOnChange,
	                                           initializedValue0 = '',
	                                           initializedValue = ''
                                           }) {
	const [localState, setlocalState] = useState(initializedValue);
	const [localState0, setlocalState0] = useState(initializedValue0);
	
	function handleInputOnChangeLocal(e) {
		setlocalState(e.target.value);
		handleInputOnChange(e);
	}
	
	function handleInputOnChange0(e) {
		setlocalState0(e.target.value);
		handleInputOnChange(e);
	}
	
	
	return (<div id={uniqueKey + '_parent'} style={{marginTop: "5px", minWidth: "710px", marginLeft: "-10px"}}>
		<div style={{fontWeight: "normal", padding: "0px", marginLeft: "14px"}}>Call to Action</div>
		<div style={{
			border: "1px solid lightgrey",
			borderRadius: "10px",
			marginTop: "0px",
			minHeight: "80px",
			backgroundColor: "rgb(248,250,251)",
			marginLeft: "10px",
			marginRight: "10px",
			marginBottom: "10px"
		}}>
			<table style={{margin: "10px"}}>
				<tr style={{fontWeight: "bold"}}>
					<td>Type</td>
					<td>Button Text</td>
					<td style={{paddingLeft: "22px"}}>Url Type</td>
					<td style={{paddingLeft: "22px"}}>Website Url</td>
				</tr>
				<tr>
					<td style={{minWidth: "100px"}}>
						<p style={{
							border: "1px solid lightgrey",
							minHeight: "40px",
							padding: "5px",
							borderRadius: "10px",
							marginRight: "20px",
							marginLeft: "0px",
							textAlign: "center",
							verticalAlign: "middle"
						}}>
							Custom
						</p>
					</td>
					<td><input id={uniqueKey}
					           value={localState0}
					           onChange={(e) => handleInputOnChange0(e)}
					           style={{
						           minHeight: "40px", borderRadius: "10px", border: "1px solid lightgrey", padding: "5px"
					           }}/>
					</td>
					<td><input id='btnText2' value='static' disabled={true}
					           style={{
						           minHeight: "40px", borderRadius: "10px", border: "1px solid lightgrey", padding: "5px",
						           maxWidth: "80px", marginLeft: "20px"
					           }}/>
					</td>
					<td><input id={uniqueKeyUrl}
					           value={localState}
					           onChange={(e) => handleInputOnChangeLocal(e)}
					           style={{
						           minHeight: "40px", borderRadius: "10px", border: "1px solid lightgrey", padding: "5px",
						           marginLeft: "20px", minWidth: "220px"
					           }}/>
					</td>
					{/*<td><label style={{*/}
					{/*	padding: "5px",*/}
					{/*	paddingLeft: "3px",*/}
					{/*	borderRadius: "10px",*/}
					{/*	border: "1px solid lightgrey",*/}
					{/*	marginLeft: "5px",*/}
					{/*	cursor: "pointer"*/}
					{/*}} onClick={() => deleteCurrentButton(uniqueKey)}>Delete</label>*/}
					{/*</td>*/}
				</tr>
			</table>
		</div>
	</div>)
}