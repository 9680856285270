import React, {useEffect, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import {upsertToDb} from "../Whatsapp/dbUtils/simpleDbUtils";
import Button from "@mui/material/Button";
import PdfUpload from "./PdfUpload";
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";
import './myapplication.css'
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";

export default function MyApplications() {
	var signedin = sessionStorage.getItem("signedin");
	const [pdfUrl, setPdfUrl] = useState('');
	const [pdfText, setpdfText] = useState('');
	const [skillText, setSkillText] = useState('');
	const [experienceText, setexperienceText] = useState('');
	const [educationText, seteducationText] = useState('');
	const [pdfData, setPdfData] = useState(null);
	const [pdfNamesAndIds, setpdfNamesAndIds] = useState(null);
	const [currentPdfName, setcurrentPdfName] = useState('');
	const [currentPdfId, setcurrentPdfId] = useState('');
	
	function loadPdfText(pdfId) {
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getPdfText/' + pdfId).then(response => {
			setpdfText(response.data);
		})
	}
	
	function loadPdfFile(rec) {
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getPdf/' + rec._id, {responseType: 'blob'}).then(response => {
			const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			setPdfUrl(pdfUrl);
		})
		setcurrentPdfName(rec.filename)
		setcurrentPdfId(rec._id)
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getPdfText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			setpdfText(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getSkillText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			setSkillText(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getExperienceText/' + rec._id).then(response => {
			// document.getElementById('resumeText') ? document.getElementById('resumeText').value = response.data : null;
			setexperienceText(response.data);
		});
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getEducationText/' + rec._id).then(response => {
			seteducationText(response.data);
		});
		
	}
	
	function getAllPdfData() {
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/getAllPdfs').then(response => {
			setpdfNamesAndIds(response.data)
			if (response.data.length > 0) {
				loadPdfFile(response.data[0])
			}
		})
	}
	
	// useEffect(() => {
	// 	loadPdfText(currentPdfId);
	// }, [pdfText]);
	
	useEffect(() => {
		getAllPdfData();
	}, []);
	
	function deletePdfFromDb(rec) {
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.get('/deletePdf/' + rec._id).then(response => {
			getAllPdfData();
			alert(response.data.message);
		})
	}
	
	function saveTextChangeToDb(e) {
		setpdfText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/setPdfText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function skillChangeEvent(e) {
		setSkillText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateSkillText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function experienceChangeEvent(e) {
		setexperienceText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateExperienceText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function educationChangeEvent(e) {
		seteducationText(e.target.value);
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('/updateEducationText/' + currentPdfId, {'newText': e.target.value})
	}
	
	function handleInputChange(e) {
		const localToken = e.target.value
		setaccessToken(localToken);
		upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'shopifyStoreName': localToken})
	}
	
	const textStyle = {
		fontSize: '16px',
		fontWeight: 'bold',
		backgroundImage: 'linear-gradient(90deg, rgb(155, 66, 255), rgb(246, 75, 255), rgb(235, 123, 163))',
		WebkitBackgroundClip: 'text', // Note the camelCase and the Webkit prefix
		color: 'transparent',
		backgroundClip: 'text',
		// display: 'flex',
	};
	
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div style={{marginTop: "0px", marginLeft: "0px", minHeight: "0px"}}>
			<div style={{marginTop: "5px"}}>
				<div style={{display: "flex", verticalAlign: "middle", alignItems: "center", marginTop: "10px"}}>
					<div style={{}}>Your Files:</div>
					<div style={{
						marginLeft: "10px",
						border: "1px solid lightgrey",
						marginTop: "10px",
						padding: "10px",
						borderRadius: "10px",
						maxHeight: "200px",
						overflowY: "scroll",
						marginRight: "20px"
					}}>
						{pdfNamesAndIds ? pdfNamesAndIds.map(rec => {
							return (<div style={{display: "flex"}}>
								<li className='pdfListItem' onClick={() => loadPdfFile(rec)}>
									{rec.filename}
								</li>
								<DeleteOutlined className='deleteButton' onClick={() => deletePdfFromDb(rec)}
								                style={{color: "lightskyblue", marginLeft: "40px"}}/>
							</div>)
						}) : <li>Loading Names...</li>}
					
					</div>
					<div style={{marginRight: "20px"}}>Add More Pdf Files / Resumes</div>
					<PdfUpload getAllPdfData={getAllPdfData}/>
				</div>
				<div style={{marginTop: "10px"}}>
					<div style={{marginBottom: "20px", marginTop: "10px", display: "flex", alignItems: "center"}}>
					</div>
					<div>
						<p style={{height: "30px", textAlign: "center", marginLeft: "-20px"}}>Selected File Name:
							<span style={{fontWeight: "bold", marginLeft: "10px"}}>{currentPdfName ? currentPdfName : ''}</span>
						</p>
						{pdfUrl ? (<div style={{display: "flex"}}>
							<div style={{marginRight: "10px", marginTop: "-20px"}}>
								<div style={textStyle}>Skills</div>
								<textarea onChange={(e) => skillChangeEvent(e)} style={{
									border: "1px solid lightgrey",
									height: "160px", borderRadius: "5px", padding: "10px", minWidth: "300px"
								}}
								          placeholder='Add Skills Here' value={skillText}/>
								<div style={textStyle}>Experience</div>
								<textarea style={{
									marginLeft: "0px",
									border: "1px solid lightgrey",
									height: "240px",
									borderRadius: "5px",
									padding: "10px",
									minWidth: "300px"
								}}
								          onChange={(e) => experienceChangeEvent(e)}
								          value={experienceText}
								          placeholder='Add Experience Text Here'/>
								<div style={textStyle}>Education</div>
								<textarea style={{
									marginLeft: "0px",
									border: "1px solid lightgrey",
									height: "100px",
									borderRadius: "5px",
									padding: "10px",
									minWidth: "300px"
								}}
								          onChange={(e) => educationChangeEvent(e)}
								          value={educationText}
								          placeholder='Add Education Text Here'/>
							</div>
							<textarea id='resumeText' style={{
								border: "1px solid black", borderRadius: "10px", width: '30%', height: '60vh', padding: "20px", marginRight: "10px"
							}} onChange={(e) => saveTextChangeToDb(e)}
							          value={pdfText}
							/>
							<iframe src={pdfUrl} style={{
								width: '50%', height: '60vh', borderRadius: "10px", border: "1px solid lightgrey", padding: "20px", paddingTop: "0px"
							}} frameBorder="0"></iframe>
						</div>) : (
							<p>Loading PDF...</p>)}
					</div>
				</div>
				<div style={{marginTop: "15px", alignItems: "center", display: "flex"}}>
				</div>
				{/*<div style={{marginTop: "10px"}}>*/}
				{/*	<input style={{*/}
				{/*		marginLeft: "10px", border: "1px solid lightgrey", height: "36px", borderRadius: "5px", padding: "10px"*/}
				{/*	}}*/}
				{/*	       placeholder='Update Keywords'/>*/}
				{/*	<input style={{*/}
				{/*		marginLeft: "10px", border: "1px solid lightgrey", height: "36px", borderRadius: "5px", padding: "10px"*/}
				{/*	}}*/}
				{/*	       placeholder='Add Locations'/>*/}
				{/*</div>*/}
			</div>
			<p style={{fontSize: "20px", fontWeight: "bold", marginTop: "20px"}}>Custom Cover Letter for Every Job Generated With AI</p>
			<p style={{fontSize: "20px", fontWeight: "bold", marginTop: "20px"}}>Matched Jobs</p>
			<p style={{fontSize: "20px", fontWeight: "bold", marginTop: "20px"}}>Similar Jobs</p>
			<p style={{fontSize: "20px", fontWeight: "bold", marginTop: "20px"}}>Your Dashboard</p>
			<div style={{marginTop: "20px"}}>
				<Button variant='outlined'>Select Candidate</Button>
				<Button variant='outlined' style={{marginLeft: "10px"}}>Applied Jobs</Button>
				<Button variant='outlined' style={{marginLeft: "10px"}}> In Progress < /Button>
				<Button variant='outlined' style={{marginLeft: "10px"}}>Call back count</Button>
				<Button variant='outlined' style={{marginLeft: "10px"}}>Add Quota</Button>
			</div>
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
			{/*<div style={{marginTop: "20px"}}></div>*/}
			{/*<p style={{fontWeight: "bold"}}>My Queue</p>*/}
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
			{/*<div style={{marginTop: "20px"}}></div>*/}
			{/*<p style={{fontWeight: "bold"}}>Applied Jobs</p>*/}
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
			{/*<div style={{marginTop: "20px"}}></div>*/}
			{/*<p style={{fontWeight: "bold"}}>Discarded Jobs</p>*/}
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
		</div>)
	}
}