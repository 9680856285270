import React, {useEffect, useState} from 'react';

const UnSubscribe = () => {
	return (
		//TODO Send the events to the database
		<div style={{minHeight: '1000px', textAlign: 'center'}}>
			<h1 style={{marginTop: '100px', backgroundColor: 'lightgrey'}}>
				We got you !! You are now Un-Subscribed in our system.
			</h1>
		</div>
	)
};

export default UnSubscribe;
