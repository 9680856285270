import React, {useCallback} from 'react';
import ReactFlow, {useNodesState, useEdgesState, addEdge} from 'reactflow';

import 'reactflow/dist/style.css';

const initialNodes = [
    {
        id: '1', position: {x: 0, y: 100},
        data: {label: 'Onboard Your Domain With MailChamp.Ai'},
        sourcePosition: 'right',
        targetPosition: 'left'
    },
    {
        id: '2', position: {x: 250, y: 100}, data: {label: 'You Choose Templates from our Pool'},
        sourcePosition: 'right',
        targetPosition: 'left'
    },
    {
        id: '3', position: {x: 500, y: 100}, data: {label: 'You bring your contacts and add in our system'},
        targetPosition: 'left',
        sourcePosition: 'right'
    },
    {
        id: '4', position: {x: 750, y: 100}, data: {label: 'We launch a mini campaign for you'},
        targetPosition: 'left'
    },
    {id: '5', position: {x: 750, y: 250}, data: {label: 'We share dashboard and stats with you'}},
    {
        id: '6',
        position: {x: 750, y: 350},
        data: {label: 'You choose more templates, we work with you on finding the right content'},
        sourcePosition: 'left'
    },
    {
        id: '7', position: {x: 450, y: 380}, data: {label: 'We launch bigger campaign for you'},
        targetPosition: 'right',
        sourcePosition: 'top'
    },
];
const initialEdges = [{
    id: 'e1-2', source: '1', target: '2', label: 'step 1', type: 'step',
    style: {
        strokeWidth: 2,
        stroke: '#0037ff'
    }
},
    {
        id: 'e2-3', source: '2', target: '3', label: 'step 2', type: 'step',
        style: {
            strokeWidth: 2,
            stroke: '#0037ff'
        }
    },
    {
        id: 'e3-4', source: '3', target: '4', label: 'step 3', type: 'step',
        style: {
            strokeWidth: 2,
            stroke: '#0037ff'
        }
    },
    {
        id: 'e4-5', source: '4', target: '5', label: 'step 4', type: 'step',
        style: {
            strokeWidth: 2,
            stroke: '#0037ff'
        }
    },
    {
        id: 'e5-6', source: '5', target: '6', label: 'step 5', type: 'smoothstep',
        style: {
            strokeWidth: 2,
            stroke: '#0037ff'
        }
    },
    {
        id: 'e6-7', source: '6', target: '7', label: 'step 6', type: 'smoothstep',
        style: {
            strokeWidth: 2,
            stroke: '#0037ff'
        }
    },
    {
        id: 'e7-5', source: '7', target: '5', label: 'step 7 / Feedback Loop', type: 'smoothstep',
        style: {
            strokeWidth: 2,
            stroke: '#49bb08'
        }
    }
];

export default function Flows() {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );

    return (
        <div style={{marginTop: "200px"}}>
            <p style={{
                textAlign: 'center',
                marginLeft: "50px",
                marginTop: "0px",
                fontSize: "1.5em",
                color: "#c100ffbf",
                width :'500px'
            }}>Onboarding Flow</p>
            <div style={{backgroundColor: "#00000029", height: "600px", borderRadius: "50px"}}>
                <div style={{width: '1100px', height: '100vh'}}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                    />
                </div>
            </div>
        </div>
    );
}