import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from 'react-toastify';
import {getAxiosBaseUrl} from "../../../config";

export default function Login() {
	const [serverMessage, setserverMessage] = useState("");
	const [requestingVerificationId, setrequestingVerificationId] = useState(false);
	const [signedin, setsignedin] = useState(false);
	const [verificationid, setverificationid] = useState('');
	const [verifiedemail, setverifiedemail] = useState('');
	
	function validEmailAddress(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	
	useEffect(() => {
		var signedin = sessionStorage.getItem("signedin");
		var verificationid = sessionStorage.getItem("verificationid");
		var verifiedemail = sessionStorage.getItem("verifiedemail");
		
		if (signedin == null || signedin == false) {
			setsignedin(false);
		} else {
			setsignedin(true);
			setverificationid(verificationid);
			setverifiedemail(verifiedemail)
			document.getElementById('sendFromField').value = verifiedemail
			document.getElementById('verificationId').value = verificationid
			document.getElementById('sendFromField').style.color = 'green'
		}
	}, []);
	
	function signOut() {
		//reset form
		setsignedin(false);
		setrequestingVerificationId(false);
		document.getElementById('userform').reset();
		document.getElementById('sendFromField').value = '...'
		
		sessionStorage.removeItem("signedin");
		sessionStorage.removeItem("verificationid");
		sessionStorage.removeItem("verifiedemail");
		
		toast.success('Good Bye', {
			position: "top-right",
			autoClose: 2000
		});
		window.location.reload()
	}
	
	function onSubmit(event) {
		event.preventDefault();
		if (signedin) { //User wants to sign out
			signOut()
			return
		}
		toast.success('Signing In', {
			position: "top-right",
			autoClose: 2000
		});
		
		setrequestingVerificationId(true)
		const varId = document.getElementById('verificationId').value.trim()
		if (varId.length > 20) {
			setrequestingVerificationId(true)
			axios.defaults.baseURL = getAxiosBaseUrl();
			axios.post('/idtoemail', {'verification_id': varId})
			.then(response => {
					setrequestingVerificationId(false);
					if (validEmailAddress(response.data.msg)) {
						toast.success('Logged In', {
							position: "top-right",
							autoClose: 2000
						});
						document.getElementById('sendFromField').value = response.data.msg
						document.getElementById('sendFromField').style.color = 'green'
						setsignedin(true);
						sessionStorage.setItem("signedin", true);
						sessionStorage.setItem("verificationid", varId);
						sessionStorage.setItem("verifiedemail", response.data.msg);
						window.location.reload()
					} else {
						toast.error('Failed Logged In', {
							position: "top-right",
							autoClose: 2000
						});
						document.getElementById('sendFromField').value = response.data.msg;
						document.getElementById('sendFromField').style.color = 'red';
						sessionStorage.setItem("signedin", false);
					}
				}
			).catch(err => console.log(err));
		} else {
			this.setState({requestingVerificationId: false})
			document.getElementById('sendFromField').value = 'Invalid Verification Id'
		}
	}
	
	function getUserLoginStateText() {
		if (requestingVerificationId) {
			return "Verifying ... "
		} else if (signedin) {
			return "Logout"
		} else {
			return "Login"
		}
	}
	
	return (
		<div className="BookingSteps" style={{
			boxShadow: "10px 10px 12px rgba(0,0,0,0.1)",
			padding: "10px",
			border: "1px solid lightgray",
			marginTop: "20px",
			borderRadius: "30px",
			backgroundColor: 'rgba(70, 95, 195, 0.71)'
		}}>
			<p style={{textAlign: 'left', marginTop: 0}}><label
				style={{color: 'white', fontSize: 25, marginLeft: "30px", fontWeight: "bolder"}}>Sign in:</label></p>
			<div style={{marginTop: 0, marginLeft: 0}}>
				<div>
					<form id='userform' onSubmit={onSubmit} style={{marginBottom: "10px"}}>
						<div className='row' style={{textAlign: 'center', marginLeft: "30px"}}>
							<input id='verificationId' placeholder='Enter Verification Id sent from MailChamp.Ai'
							       required style={{
								lineHeight: 2, textAlign: "center", marginLeft: 10, width: 350, borderRadius: "10px", border: "1px solid gray"
							}}/>
							<button type='submit' className='btn' disabled={requestingVerificationId}
							        style={{backgroundColor: "#e8f1ff", marginLeft: 10, lineHeight: 1.5, width: '100px'}}>
								{getUserLoginStateText()}
							</button>
						</div>
					</form>
					<label style={{width: 200, marginLeft: '40px', marginTop: '20px', fontSize: 18, fontWeight: "bold", color: "white"}}>Registered
						Email: </label>
					<input type='email' name='sendFromField' id='sendFromField' disabled='true'
					       placeholder=' ... '
					       style={{
						       lineHeight: 2,
						       textAlign: "left",
						       marginLeft: '-40px',
						       width: 350,
						       fontSize: 14
					       }}/>
				</div>
				<div style={{textAlign: 'center', color: 'blue'}}><span>{serverMessage}</span></div>
			</div>
		</div>)
}
