import React from 'react';
import Button from '@mui/material/Button';

function CategorySelectionButtons({selectedCategories, setSelectedCategories, singleSelectionOnly}) {
	const categories = ['new', 'welcome', 'coupons', 'sales', 'holidays', 'newsletter', 'retail', 'industry',
		'plain', 'announcement', 'events', 'marketing'
	];
	const handleToggle = (category) => {
		if (singleSelectionOnly) {
			setSelectedCategories(prevSelected =>
				prevSelected.includes(category) ? [] : [category]
			);
		} else {
			setSelectedCategories((prevSelected) => {
				if (prevSelected.includes(category)) {
					// If the category is already selected, remove it from the list
					return prevSelected.filter((selected) => selected !== category);
				} else {
					// If the category is not selected, add it to the list
					return [...prevSelected, category];
				}
			});
		}
	};
	
	return (
		<>
			{/*<label htmlFor="category-select">Select Categories:</label>*/}
			{categories.map((category) => (<Button
				key={category}
				variant="outlined"
				style={{
					backgroundColor: selectedCategories.includes(category) ? 'lightblue' : 'lightgrey', margin: '5px', height: "25px"
				}}
				onClick={() => handleToggle(category)}
			>
				{category}
			</Button>))}
		</>);
}

export default CategorySelectionButtons;
