// Modal.js
import React, {useEffect} from 'react';
import './imagecomp.css';
import AddLeads from "../Campaign/AddLeads"; // Import your CSS styles

const Modal = ({isOpen, children, onClose}) => {
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.keyCode === 27) onClose(); // 27 is the escape key
		};
		
		if (isOpen) {
			// Add event listener
			document.addEventListener('keydown', handleEsc);
		}
		
		// Clean up
		return () => {
			document.removeEventListener('keydown', handleEsc);
		};
	}, [isOpen, onClose]); // Only re-run effect if isOpen or onClose changes
	
	if (!isOpen) return null;
	
	return (
		<div className="modal-overlay" onClick={onClose}>
			<div className="modal-body" onClick={(e) => e.stopPropagation()}
			     style={{display: 'contents', background: 'none', zIndex: '10000'}}
			>
				{children}
				<button onClick={onClose}>Done Adding Contacts</button>
			</div>
		</div>
	);
};

export default Modal;
