import whatsappBkgImage from "../../../assets/whatsappbackground.png";
import Button from "@mui/material/Button";
import React from "react";
import ReplyIcon from '@mui/icons-material/Reply';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CallIcon from '@mui/icons-material/Call';

export default function WhatsappPreview({inputValue, inputBodyValue, inputFtrValue, qckBtnText}) {
	function getButtonNumber(inputString) {
		const number = inputString.match(/\d+/);
		const extractedNumber = number ? number[0] : -1;
		return extractedNumber
	}
	
	function handleCallButton(text) {
		alert('Call will be made to: ' + text);
	}
	
	function handleCopyToClipboard(text) {
		// Create a temporary textarea element
		const textarea = document.createElement('textarea');
		textarea.value = text;
		textarea.setAttribute('readonly', '');
		textarea.style.position = 'absolute';
		textarea.style.left = '-9999px';
		
		document.body.appendChild(textarea);
		
		// Select the text and copy it to the clipboard
		textarea.select();
		document.execCommand('copy');
		
		// Remove the temporary textarea
		document.body.removeChild(textarea);
		
		// Optionally, show a notification or perform other actions to indicate success
		alert('Copied to clipboard: ' + text);
	}
	
	return (
		<div style={{flex: 1, marginLeft: "10px", maxWidth: "410px"}}>
			<p style={{textAlign: "center"}}>Preview</p>
			<div title='Click to Edit' style={{
				display: "block",
				border: "1px solid grey",
				borderRadius: "20px",
				padding: "20px",
				maxWidth: "400px",
				overflow: "scroll",
				backgroundImage: `url(${whatsappBkgImage})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				verticalAlign: "top"
			}}>
				<div style={{backgroundColor: "white", padding: "10px", borderRadius: "10px"}}>
					<div id='headerText'>{inputValue}</div>
					<div id='bodyText' dangerouslySetInnerHTML={{__html: inputBodyValue}}/>
					<div id='footerText'>{inputFtrValue}</div>
					
					{Object.keys(qckBtnText).map((key) => {
							if (key.startsWith("quick_reply")) {
								return (<>
									<hr style={{margin: "10px"}}/>
									<div id='buttonTextUrl' key={key}>
										<ReplyIcon style={{height: "16px", marginBottom: "2px", marginRight: "10px"}}/>
										{qckBtnText[key]}
										<br/>
									</div>
								</>);
							} else if (key.startsWith("call_to_action_")) {
								return (<>
									<hr style={{margin: "10px"}}/>
									<a href={qckBtnText['url_call_to_action_' + getButtonNumber(key)]} target='_blank'>
										<div id='buttonTextUrl' key={key}>
											<OpenInNewIcon style={{height: "16px", marginBottom: "2px", marginRight: "10px"}}/>
											{qckBtnText[key]}
											<br/>
										</div>
									</a>
								</>)
							} else if (key.startsWith("copy_code_btn_")) {
								return (<>
									<hr style={{margin: "10px"}}/>
									<div id='buttonTextUrl' key={key}
									     onClick={() => handleCopyToClipboard(qckBtnText['coupon_code_' + getButtonNumber(key)])}
									>
										<ContentCopyIcon style={{height: "16px", marginBottom: "2px", marginRight: "10px"}}/>
										{qckBtnText[key]}
										<br/>
									</div>
								</>)
							} else if (key.startsWith("call_phone_btn_")) {
								return (<>
									<hr style={{margin: "10px"}}/>
									<div id='buttonTextUrl' key={key}
									     onClick={() => handleCallButton(qckBtnText['phone_no_' + getButtonNumber(key)])}
									>
										<CallIcon style={{height: "16px", marginBottom: "2px", marginRight: "10px"}}/>
										{qckBtnText[key]}
										<br/>
									</div>
								</>)
							}
							return null;
						}
					)}
				</div>
			</div>
		</div>
	)
}