import React, {Component} from 'react'
import {Link} from 'react-router-dom'
// import {notify} from 'react-notify-toast'
import Spinner from './Spinner'
import {API_URL} from '../config'
import {Card as BSCard} from "react-bootstrap";

export default class Pricing extends Component {
	state = {
		confirming: true, hoverStates: {},
	};
	
	componentDidMount = () => {
		this.setState({confirming: false});
	};
	
	handleHover = (cardId) => {
		this.setState((prevState) => ({
			hoverStates: {
				...prevState.hoverStates, [cardId]: !prevState.hoverStates[cardId],
			},
		}));
	};
	
	render = () => {
		return (
			
			<div style={{marginTop: '250px'}}>
				<label style={{color: 'gray', fontWeight: 600, fontSize: '18px', marginTop: '10px'}}>
					We know one size doesn't fit all, let's chat if you have custom requirements.
				</label>
				<br/><br/>
				{/*<Card*/}
				{/*  style={{*/}
				{/*    width: '14rem',*/}
				{/*    float: 'left',*/}
				{/*    margin: 5,*/}
				{/*    border: 'solid',*/}
				{/*    borderColor: '#CFE7E1',*/}
				{/*    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.1)',*/}
				{/*    transition: 'transform 0.3s',*/}
				{/*    transform: this.state.hoverStates['freePlan'] ? 'scale(1.05)' : 'scale(1)',*/}
				{/*    margin: "10px"*/}
				{/*  }}*/}
				{/*  onMouseEnter={() => this.handleHover('freePlan')}*/}
				{/*  onMouseLeave={() => this.handleHover('freePlan')}*/}
				{/*>*/}
				{/*  <Card.Body>*/}
				{/*    <Card.Title style={{fontSize: "24px"}}>Free Plan</Card.Title>*/}
				{/*    <Card.Subtitle className="mb-2 text-muted">$0 per month</Card.Subtitle>*/}
				{/*    <Card.Text>1,000 emails</Card.Text>*/}
				{/*    <Card.Text>200 contacts</Card.Text>*/}
				{/*    <Card.Text>Subject line AB testing</Card.Text>*/}
				{/*  </Card.Body>*/}
				{/*</Card>*/}
				<BSCard
					style={{
						width: '14rem',
						float: 'left',
						border: 'solid',
						borderColor: '#CFE7E1',
						boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.1)',
						transition: 'transform 0.3s',
						transform: this.state.hoverStates['basicPlan'] ? 'scale(1.05)' : 'scale(1)',
						margin: "10px"
					}}
					onMouseEnter={() => this.handleHover('basicPlan')}
					onMouseLeave={() => this.handleHover('basicPlan')}
				>
					<BSCard.Body>
						<BSCard.Title style={{fontSize: "24px"}}>Basic Plan</BSCard.Title>
						<BSCard.Subtitle className="mb-2 text-muted">$5 monthly (Rs 4k)</BSCard.Subtitle>
						<BSCard.Text>10,000 emails</BSCard.Text>
						<BSCard.Text>1,000 contacts</BSCard.Text>
						<BSCard.Text>Subject line AB testing</BSCard.Text>
						<BSCard.Text>Email Support</BSCard.Text>
						<BSCard.Text>Market Segmentation</BSCard.Text>
						<BSCard.Text>Daily Sending Limit</BSCard.Text>
					</BSCard.Body>
				</BSCard>
				
				<BSCard
					style={{
						width: '14rem',
						float: 'left',
						border: 'solid',
						borderColor: 'mediumpurple',
						boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.1)',
						transition: 'transform 0.3s',
						transform: this.state.hoverStates['proPlan'] ? 'scale(1.05)' : 'scale(1)',
						margin: "10px"
					}}
					onMouseEnter={() => this.handleHover('proPlan')}
					onMouseLeave={() => this.handleHover('proPlan')}
				>
					<BSCard.Body>
						<BSCard.Title style={{fontSize: "24px"}}>Pro Plan</BSCard.Title>
						<BSCard.Subtitle className="mb-2 text-muted">$10 monthly (Rs 8k)</BSCard.Subtitle>
						<BSCard.Text>30,000 emails</BSCard.Text>
						<BSCard.Text>3,000 contacts</BSCard.Text>
						<BSCard.Text>Subject line AB testing</BSCard.Text>
						<BSCard.Text>Email Support</BSCard.Text>
						<BSCard.Text>Market Segmentation</BSCard.Text>
						<BSCard.Text>No Daily Sending Limit</BSCard.Text>
						<BSCard.Text>Email Scheduling</BSCard.Text>
						<BSCard.Text>10 Custom Template Creation</BSCard.Text>
						<BSCard.Text>Tracking & Reporting</BSCard.Text>
					</BSCard.Body>
				</BSCard>
				
				<BSCard
					style={{
						width: '14rem',
						float: 'left',
						border: 'solid',
						borderColor: 'cornflowerblue',
						paddingRight: 0,
						boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.1)',
						transition: 'transform 0.3s',
						transform: this.state.hoverStates['quantusProPlan'] ? 'scale(1.05)' : 'scale(1)',
						margin: "10px"
					}}
					onMouseEnter={() => this.handleHover('quantusProPlan')}
					onMouseLeave={() => this.handleHover('quantusProPlan')}
				>
					<BSCard.Body>
						<BSCard.Title style={{fontSize: "24px"}}>Quantus Pro Plan</BSCard.Title>
						<BSCard.Subtitle className="mb-2 text-muted">$20 monthly (Rs 16k)</BSCard.Subtitle>
						<BSCard.Text>50,000 emails</BSCard.Text>
						<BSCard.Text>5,000 contacts</BSCard.Text>
						<BSCard.Text>Subject line AB testing</BSCard.Text>
						<BSCard.Text>Email Support</BSCard.Text>
						<BSCard.Text>Market Segmentation</BSCard.Text>
						<BSCard.Text>No Daily Sending Limit</BSCard.Text>
						<BSCard.Text>Email Scheduling</BSCard.Text>
						<BSCard.Text>30 Custom Template Creation</BSCard.Text>
						<BSCard.Text>Tracking & Reporting</BSCard.Text>
						<BSCard.Text>Smart Segments</BSCard.Text>
						<BSCard.Text>MultiVariate Testing</BSCard.Text>
					</BSCard.Body>
				</BSCard>
				
				<div style={{clear: 'both'}}></div>
				<br/><br/><br/><br/>
			</div>);
	};
}