import React, {Component} from 'react'
import './Sidebar.css'
import axios from "axios";
// import {Card, Text, Metric, Flex, ProgressBar} from "@tremor/react";
// import { ItemTypes } from './Constants'
import './DragDropExample/DragDropExample'
import 'react-toastify/dist/ReactToastify.css';
import DataContext from '../Context/DataContext';
import {Card as MuiCard} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {AgChartsReact} from 'ag-charts-react';
import {getAxiosBaseUrl} from "../../config";
import {SigninForm} from "../molecules/SigninForm";
import {MapContainer, Marker, TileLayer,} from "react-leaflet";
import './dashboard.css'
import 'leaflet/dist/leaflet.css';
import icon from '../../assets/icon1.png'
import L from 'leaflet';

axios.defaults.baseURL = getAxiosBaseUrl()

// Define your custom icon
const customIcon = new L.Icon({
	iconUrl: icon, // URL to your custom icon
	iconSize: [25, 41], // Size of the icon
	iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
	popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
	// shadowUrl: 'path_to_your_icon_shadow.png', // Optional shadow image
	// shadowSize: [41, 41]
});

export default class Dashboard extends Component {
	static contextType = DataContext;
	state = {
		confirming: true,
		newCampaignData: [],
		openCount: 0
	}
	
	constructor(props) {
		super(props);
		this.state = {
			emailsData: [], newCampaignData: [], trafficData: [],
			ipLatLongLocations: [], eventDataInfo: [], eventOpens: [], eventBounces: [], eventClicks: [],
			trafficDataLength: 0
		};
	}
	
	componentDidMount = () => {
		this.setState({confirming: false})
		axios.defaults.baseURL = getAxiosBaseUrl()
		var verifiedemail = sessionStorage.getItem("verifiedemail");
		
		axios.post('/getTrafficDataLength', {verifiedemail: verifiedemail})
		.then(response => {
			this.setState({trafficDataLength: response.data})
		}).catch(err => console.log(err));
		
		axios.post('/getTrafficData', {verifiedemail: verifiedemail})
		.then(response => {
			this.setState({trafficData: response.data})
		}).catch(err => console.log(err));
		
		axios.post('/getSnsEventData', {verifiedemail: verifiedemail})
		.then(response => {
			// console.log(response.data);
			const eventDataInfo = response.data.map(record => {
				return [record.eventType, record.eventMailData_source, record.eventMailData_destination, record.eventDataUserAgent]
			})
			this.setState({eventDataInfo: eventDataInfo});
			
			const eventOpens = response.data.filter(record => {
				return record.eventType == 'open'
			})
			this.setState({eventOpens: eventOpens})
			const eventBounces = response.data.filter(record => {
				return record.eventType == 'bounce'
			})
			this.setState({eventBounces: eventBounces})
			
			const eventClicks = response.data.filter(record => {
				return record.eventType == 'click'
			})
			this.setState({eventClicks: eventClicks})
			
			const ipLatLongLocationsArray = response.data.map(record => {
				return [record.eventDataIpInfo.data.latitude, record.eventDataIpInfo.data.longitude];
			});
			
			this.setState({ipLatLongLocations: ipLatLongLocationsArray});
			// console.log(response.data)
		}).catch(err => console.log(err));
	}
	
	// addRowToNewCampaign(dataWithoutHtmlMessage) {
	// 	toast('Added to your campaign', {autoClose: 2000})
	// 	this.setState(prevState => ({
	// 		newCampaignData: [...prevState.newCampaignData, dataWithoutHtmlMessage]
	// 	}));
	// 	//Requires more time to handle this
	// 	// sessionStorage.setItem('newCampaignData', JSON.stringify(this.state.newCampaignData))
	// }
	
	// renderNewCampaignTableData(inputData) {
	// 	// const inputData1 = JSON.parse(sessionStorage.getItem("newCampaignData")) || [];
	// 	return inputData.map((item, index) => (
	// 		<tr key={index}>
	// 			<td>{item.htmlSubject}</td>
	// 			<td>{item.source_}</td>
	// 			<td>{item.user_emails.join(',')}</td>
	// 		</tr>
	// 	));
	// }
	// renderTableData() {
	// 	let keepVisibleCharCount = 4
	// 	if (sessionStorage.getItem("signedin")) {
	// 		keepVisibleCharCount = -1
	// 	}
	// 	return this.state.emailsData.map((emailsData, index) => {
	// 		const dataWithoutHtmlMessage = Object.entries(emailsData)
	// 		.filter(([key]) => key !== 'htmlMessage' && key !== 'sesResponse')
	// 		.map(([key, value]) => {
	// 			// Replace content of 'source_' field
	// 			if (key === 'source_') {
	// 				if (keepVisibleCharCount == -1) {
	// 					return [key, value];
	// 				} else {
	// 					const maskedSource = value.substring(0, keepVisibleCharCount) + value.substring(keepVisibleCharCount, value.indexOf('@')).replace(/./g, '*') + value.substring(value.indexOf('@'));
	// 					return [key, maskedSource];
	// 				}
	// 			}
	// 			if (key === 'user_emails') {
	// 				if (typeof (value) == 'string') {
	// 					value = [value]
	// 				}
	// 				if (keepVisibleCharCount == -1) {
	// 					return [key, value]
	// 				} else {
	// 					const maskedArray = value.map((target_email) => {
	// 						const maskedSource = target_email.substring(0, keepVisibleCharCount) + target_email.substring(keepVisibleCharCount, target_email.indexOf('@')).replace(/./g, '*') + target_email.substring(target_email.indexOf('@'));
	// 						return maskedSource
	// 					})
	// 					return [key, maskedArray]
	// 				}
	//
	// 			}
	// 			return [key, value];
	// 		})
	// 		.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
	// 		var backgroundColor = 'white'
	// 		if (dataWithoutHtmlMessage['eventType'] == 'Open') {
	// 			backgroundColor = 'lightgreen'
	// 		} else if (dataWithoutHtmlMessage['eventType'] == 'Bounce') {
	// 			backgroundColor = 'lightyellow'
	// 		}
	// 		//binding is required to prevent onclick trigger on every render
	// 		// onClick={this.addRowToNewCampaign.bind(this, dataWithoutHtmlMessage)}
	// 		return (<tr
	// 			style={{
	// 				cursor: 'pointer', backgroundColor: backgroundColor, fontSize: '14px', textAlign: 'center', fontFamily: 'Alegreya SC'
	// 			}}>
	// 			{Object.values(dataWithoutHtmlMessage).map(value => (<td style={{minWidth: '100px', width: "100px"}} key={value}>
	// 				{typeof value === 'object' ? JSON.stringify(value) : value}</td>)
	// 			)
	// 			}
	// 		</tr>);
	// 	});
	// }
	
	// routeChange = (uniqueEmailsData) => {
	// 	const {history} = this.props;
	// 	this.context.setContextData(uniqueEmailsData);  // Use setContextData
	// 	history.push('/demo');
	// }
	
	getData() {
		// Extract the date and hour from 'createdAt' to PST and then aggregate per hour
		const transformed = {};
		this.state.trafficData.forEach(item => {
			// Convert createdAt to Date object and adjust for PST timezone (UTC-8)
			const createdAtUTC = new Date(item.createdAt);
			const pstOffset = 8 * 60; // Offset in minutes
			createdAtUTC.setMinutes(createdAtUTC.getMinutes() - pstOffset);
			try {
				// Extract date and hour in PST
				const date = createdAtUTC.toISOString().split('T')[0];
				const hour = createdAtUTC.toISOString().split('T')[1].split(':')[0];
				const dateHourKey = `${date} ${hour}:00`;
				
				// Initialize aggregation object
				if (!transformed[dateHourKey]) {
					transformed[dateHourKey] = {date: dateHourKey, na: 0, Open: 0, Bounce: 0};
				}
				
				// Aggregate based on 'state'
				if (item.state === 'sent') {
					transformed[dateHourKey].na++;
				}
				// Add other conditions for 'Open' and 'Bounce'
			} catch (e) {
				//Such data would be dropped
			}
			
		});
		return Object.values(transformed);
		//Daily
		// const transformed = {};
		// this.state.trafficData.forEach(item => {
		// 	const date = item.createdAt.split(' ')[1] + ' ' + item.createdAt.split(' ')[2] + ' ' + item.createdAt.split(' ')[3];
		// 	if (!transformed[date]) {
		// 		transformed[date] = {date: date, na: 0, Open: 0, Bounce: 0};
		// 	}
		// 	if (item.state === 'sent') {
		// 		transformed[date].na++;
		// 	}
		// 	// Add other conditions for 'na' and 'Bounce' as per your data mapping
		// });
		// return Object.values(transformed);
	}
	
	render = () => {
		// const targetEmails = Object.values(this.state.newCampaignData).map((data) => {
		// 	return Object.entries(data)
		// 	.filter(([key]) => key === 'user_emails')
		// 	.map(([key, value]) => value)
		// }).flat(Infinity);
		const sentCount = this.state.trafficDataLength
		const openCount = this.state.eventOpens.length
		const bounceCount = this.state.eventBounces.length
		const openPerc = ((openCount * 100.0 / sentCount).toFixed(1)) + '%'
		const bouncePerc = ((bounceCount * 100.0 / sentCount).toFixed(1)) + '%'
		const clickCount = this.state.eventClicks.length
		const clickPerc = ((clickCount * 100.0) / sentCount).toFixed(1) + '%'
		const data = [
			{title: 'Sent Count', value: sentCount},
			{title: 'Open %', value: openPerc},
			{title: 'Bounce %', value: bouncePerc},
			{title: 'Click %', value: clickPerc},
			{title: 'Open Count', value: openCount},
			{title: 'Bounce Count', value: bounceCount},
			{title: 'Click Count', value: clickCount},
		];
		if (!sessionStorage.getItem("signedin")) {
			return (<div style={{marginTop: "300px"}}>
				<SigninForm/>
			</div>)
		} else {
			return (<div style={{
				fontFamily: "monospace", fontSize: "13px", marginTop: '10px', textAlign: 'center', marginLeft: "50px", minHeight: "1000px"
			}}>
				<Grid container spacing={20}>
					{data.map((item, index) => (
						<Grid item xs={8} sm={4} md={1} key={index}>
							<MuiCard sx={{
								width: 150,
								margin: 0,
								marginLeft: 0,
								backgroundColor: "white",
								boxShadow: "0px 1px 1px rgba(0,0,0,0.2)"
							}}>
								<CardContent style={{color: "purple"}}>
									<Typography variant="h5" component="h2">
										{item.value}
									</Typography>
									<Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
										{item.title}
									</Typography>
								</CardContent>
							</MuiCard>
						</Grid>
					))}
				</Grid>
				
				<div style={{display: "flex", marginTop: "20px"}}>
					<MapContainer
						style={{
							height: "500px",
							width: "500px",
						}}
						center={[37.42870185406485, -122.17046990353927]}
						zoom={8}>
						<TileLayer
							className="custom-opacity-tiles"
							attribution="Google Maps"
							url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
						/>
						{this.state.ipLatLongLocations.map((location, index) => (
							<Marker key={index} position={location} icon={customIcon}/>
						))}
					</MapContainer>
					<div style={{
						maxHeight: "500px", overflow: "scroll", marginLeft: "50px", minWidth: "500px",
						border: "1px solid grey", borderRadius: '20px', padding: "10px"
					}}>
						{this.state.eventDataInfo.map((record, index) => (
							<>
								<div key={index} style={{float: "left"}}>
									Event {index + 1}
								</div>
								<div key={index} style={{marginBottom: '10px', border: '1px solid #ddd', padding: '10px'}}>
									<p>Event Type: {record[0]}</p>
									{/*<p>Source: {record[1]}</p>*/}
									<p>Destination: {record[2]}</p>
									{/*<p>User Agent: {record[3]}</p>*/}
								</div>
							</>
						))}
					</div>
				</div>
				<div style={{marginTop: "10px", maxWidth: "800px"}}>
					<AgChartsReact options={
						{
							title: {
								text: 'All Campaign Stats',
							},
							data: this.getData(),
							series: [
								{
									type: 'area',
									xKey: 'date',
									yKey: 'na',
									yName: 'Sent',
									// stacked: true,
								},
								{
									type: 'area',
									xKey: 'date',
									yKey: 'Open',
									yName: 'Open',
									stacked: true,
								},
								{
									type: 'area',
									xKey: 'date',
									yKey: 'Bounce',
									yName: 'Bounce',
									stacked: true,
								},
							],
						}
					}/>
				</div>
				{/*<div style={{marginTop: "30px"}}>*/}
				{/*	<hr/>*/}
				{/*	<label>New Campaign</label>*/}
				{/*	<label style={{marginBottom: '10px', fontWeight: 'bold', marginLeft: '20px'}}>*/}
				{/*		Items Added : {this.state.newCampaignData.length}</label>*/}
				{/*	<label style={{marginTop: "30px", marginBottom: '10px', fontWeight: 'bold', marginLeft: '20px'}}>*/}
				{/*		Unique Contacts : {targetEmailsUnique.length}</label>*/}
				{/*</div>*/}
				{/*<Grid container spacing={20}>*/}
				{/*	{dataNewCampaign.map((item, index) => (*/}
				{/*		<Grid item xs={8} sm={4} md={2} key={index}>*/}
				{/*			<MuiCard sx={{*/}
				{/*				width: 200,*/}
				{/*				margin: 0,*/}
				{/*				marginLeft: 0,*/}
				{/*				backgroundColor: "#D3E6FAFF",*/}
				{/*				marginBottom: '10px'*/}
				{/*			}}>*/}
				{/*				<CardContent>*/}
				{/*					<Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>*/}
				{/*						{item.title}*/}
				{/*					</Typography>*/}
				{/*					<Typography variant="h5" component="h2">*/}
				{/*						{item.value}*/}
				{/*					</Typography>*/}
				{/*				</CardContent>*/}
				{/*			</MuiCard>*/}
				{/*		</Grid>*/}
				{/*	))}*/}
				{/*</Grid>*/}
				
				<hr/>
				<br/>
				<p style={{textAlign: "left"}}>All Emails Data</p><br/>
				<table>
					<tr>
						<td style={{marginRight: "10px"}}> Row</td>
						<td style={{width: "200px"}}> Campaign Name</td>
						<td style={{width: "200px"}}> Sent To</td>
						<td style={{width: "300px"}}> Subject</td>
						<td style={{width: "100px"}}> Sender Status</td>
					</tr>
					{
						this.state.trafficData.map((rec, index) => {
							return (<tr>
								<td>{index + 1}</td>
								<td style={{width: "200px"}}> {rec.campaignname}</td>
								<td style={{width: "200px"}}> {rec.contactemail}</td>
								<td style={{width: "300px"}}> {rec.htmlSubject}</td>
								<td style={{width: "100px"}}> {rec.state}</td>
							</tr>)
						})
					}
				</table>
				
				{/*<div style={{*/}
				{/*	maxHeight: '500px',*/}
				{/*	overflowY: 'auto',*/}
				{/*	border: '3px solid #ddd',*/}
				{/*	borderRadius: '15px',*/}
				{/*	paddingLeft: '20px',*/}
				{/*	paddingRight: '20px',*/}
				{/*	backgroundColor: '#efeeea',*/}
				{/*	color: '#4F4C44FF'*/}
				{/*}}>*/}
				{/*	<table border="1" id='newcampaign' style={{borderRadius: '20px', textAlign: 'center'}}>*/}
				{/*		<tbody>*/}
				{/*		{this.renderNewCampaignTableData(this.state.newCampaignData)}*/}
				{/*		</tbody>*/}
				{/*	</table>*/}
				{/*</div>*/}
				
				{/*<div style={{justifyContent: 'center', display: 'flex'}}>*/}
				{/*	<button id='savemydesign' className={"btn"}*/}
				{/*	        onClick={this.routeChange.bind(this, targetEmailsUnique)}*/}
				{/*	        style={{*/}
				{/*		        backgroundColor: "lightgray", fontSize: '12px', marginLeft: '15px', marginTop: '20px',*/}
				{/*		        textAlign: 'center'*/}
				{/*	        }}>*/}
				{/*		Add Contacts and Go to Editor*/}
				{/*	</button>*/}
				{/*</div>*/}
				{/*<h5 className="mb-3"*/}
				{/*    style={{textAlign: 'center', marginTop: '0px', color: '#968dff', fontWeight: 'bold'}}>*/}
				{/*	Click on row to add contact to New Campaign</h5>*/}
				
				{/*<div style={{*/}
				{/*	height: '500px',*/}
				{/*	overflowY: 'auto',*/}
				{/*	border: '3px solid #ddd',*/}
				{/*	borderRadius: '30px',*/}
				{/*	paddingLeft: '20px',*/}
				{/*	paddingRight: '20px',*/}
				{/*	backgroundColor: '#efeeea',*/}
				{/*	color: '#4F4C44FF',*/}
				{/*	marginTop: '20px'*/}
				{/*}}>*/}
				{/*	<ToastContainer/>*/}
				{/*	<label style={{marginTop: "30px", marginBottom: '10px', fontWeight: 'bold'}}>*/}
				{/*		Emails Sent : {this.state.emailsData.length}</label>*/}
				{/*	<table border="1" style={{*/}
				{/*		width: "100%", borderCollapse: 'collapse', borderRadius: '20px', overflow: 'hidden'*/}
				{/*	}}>*/}
				{/*		/!*<thead>*!/*/}
				{/*		/!*{this.renderTableHeader()}*!/*/}
				{/*		/!*</thead>*!/*/}
				{/*		<tbody>*/}
				{/*		{this.renderTableData()}*/}
				{/*		</tbody>*/}
				{/*	</table>*/}
				{/*</div>*/}
				
				{/*<table style={{marginTop: "30px", width: "100%"}}>*/}
				{/*	<tr style={{verticalAlign: "baseline"}}>*/}
				{/*		<td style={{width: "100px"}}> Email Credits : 980 emails (Used : 20 emails, 2 Campaigns)</td>*/}
				{/*	</tr>*/}
				{/*</table>*/}
				{/*<div style={{clear: "both"}}>*/}
				{/*	<div style={{float: "left", height: "200px", opacity: "0.7"}}><PieChart1></PieChart1></div>*/}
				{/*	<div style={{float: "right", height: "200px", opacity: "0.7"}}><PieChart2></PieChart2></div>*/}
				{/*</div>*/}
				{/*/!*<br/><br/>*!/*/}
				{/*<table>*/}
				{/*	<tr>*/}
				{/*		<td>Campaign Id: 7368237</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold", paddingLeft: "100px"}}>*/}
				{/*			Open Rate: 3%*/}
				{/*		</td>*/}
				{/*		<td style={{color: "grey", fontWeight: "bold", paddingLeft: "100px"}}>*/}
				{/*			Sent Email: 10*/}
				{/*		</td>*/}
				{/*	</tr>*/}
				{/*	<p>Sent from: <span style={{color: "grey", fontWeight: "bold"}}>director@yourwebsite.com</span></p>*/}
				{/*</table>*/}
				
				
				{/*<table border="1"*/}
				{/*       style={{borderCollapse: 'collapse', width: '100%', textAlign: 'center', marginTop: "-10px"}}>*/}
				{/*	<tr style={{border: "none", backgroundColor: '#d3d3d354'}}>*/}
				{/*		<td>Email</td>*/}
				{/*		<td>Sent Date</td>*/}
				{/*		<td>Status</td>*/}
				{/*		<td>Launched By</td>*/}
				{/*		<td>Add In New Campaign</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid1@gmail.com</td>*/}
				{/*		<td>12/25/2023</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold"}}> Open</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid2@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "black", fontWeight: "bold"}}> Bounce</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>No</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid3@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "red", fontWeight: "normal"}}> Spam</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>No</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid4@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "red", fontWeight: "normal"}}> Spam</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid5@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold"}}>Open</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid6@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "steelblue", fontWeight: "bold"}}>Pending</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid7@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "steelblue", fontWeight: "bold"}}>Pending</td>*/}
				{/*		<td>self</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid18@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "grey", fontWeight: "bold"}}>Unsubscribe</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid19@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "grey", fontWeight: "bold"}}>Unsubscribe</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*</table>*/}
				
				{/*<table style={{marginTop: "30px"}}>*/}
				{/*	<tr>*/}
				{/*		<td>Campaign Id: 1000238</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold", paddingLeft: "100px"}}>*/}
				{/*			Open Rate: 20%*/}
				{/*		</td>*/}
				{/*		<td style={{color: "grey", fontWeight: "bold", paddingLeft: "100px"}}>*/}
				{/*			Sent Email: 10*/}
				{/*		</td>*/}
				{/*	</tr>*/}
				{/*	<p>Sent from: <span style={{color: "grey", fontWeight: "bold"}}>noreply@yourwebsite.com</span></p>*/}
				{/*</table>*/}
				{/*<table border="1"*/}
				{/*       style={{borderCollapse: 'collapse', width: '100%', textAlign: 'center', marginTop: "-10px"}}>*/}
				{/*	<tr style={{border: "none", backgroundColor: '#d3d3d354'}}>*/}
				{/*		<td>Email</td>*/}
				{/*		<td>Sent Date</td>*/}
				{/*		<td>Status</td>*/}
				{/*		<td>Launched By</td>*/}
				{/*		<td>Add In New Campaign</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid11@gmail.com</td>*/}
				{/*		<td>12/25/2023</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold"}}> Open</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid12@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "black", fontWeight: "bold"}}> Bounce</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>No</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid13@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "red", fontWeight: "normal"}}> Spam</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>No</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid14@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "red", fontWeight: "normal"}}> Spam</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid15@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold"}}>Open</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid16@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "steelblue", fontWeight: "bold"}}>Pending</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid17@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "green", fontWeight: "bold"}}>Open</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid18@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "grey", fontWeight: "bold"}}>Unsubscribe</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*	<tr>*/}
				{/*		<td>emailid19@gmail.com</td>*/}
				{/*		<td>12/24/2023</td>*/}
				{/*		<td style={{color: "grey", fontWeight: "bold"}}>Unsubscribe</td>*/}
				{/*		<td style={{color: "green"}}>mailchamp.ai</td>*/}
				{/*		<td>Yes</td>*/}
				{/*	</tr>*/}
				{/*</table>*/}
			</div>)
		}
	}
}
