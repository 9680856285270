import React, {useEffect, useState} from "react";
// import getServerBaseUrlFromClientUrlResolutionUtil from '../../utils/clientUrlsResolutionUtils'
import html2canvas from 'html2canvas';
import {getBaseUrlNodeServer} from "../../config";

function captureScreenshot(templateHtml, setImage) {
	const tempContainer = document.createElement('div');
	tempContainer.innerHTML = templateHtml;
	document.body.appendChild(tempContainer);
	const images = tempContainer.querySelectorAll('img');
	const totalImages = images.length;
	let imagesLoaded = 0;
	function checkAllImagesLoaded() {
		imagesLoaded++;
		if (imagesLoaded === totalImages) {
			html2canvas(tempContainer).then((canvas) => {
				const dataUrl = canvas.toDataURL('image/png');
				setImage(dataUrl); // Pass the data URL to setImage
				document.body.removeChild(tempContainer);
			});
		}
	}
	images.forEach((image) => {
		image.onload = checkAllImagesLoaded;
		image.onerror = checkAllImagesLoaded; // Handle image loading errors
	});
}

export default function HtmlFromMjML({idx, setTrigger}) {
	const [templateHtml, setTemplateHtml] = useState('');
	const [currDocId, setcurrDocId] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [screenshotUrl, setScreenshotUrl] = useState(null);
	const [imagedata, setImageData] = useState(null);
	
	function convertBase64ToDataUrl(base64Data) {
		const binaryData = atob(base64Data);
		const arrayBuffer = new ArrayBuffer(binaryData.length);
		const uint8Array = new Uint8Array(arrayBuffer);
		
		for (let i = 0; i < binaryData.length; i++) {
			uint8Array[i] = binaryData.charCodeAt(i);
		}
		const blob = new Blob([uint8Array]);
		return URL.createObjectURL(blob);
	}
	useEffect(() => {
		async function getRecords() {
			const response = await fetch(getBaseUrlNodeServer() + 'getusertemplate', {
				method: 'post', headers: {'Content-Type': 'application/json'}
			})
			const mgmlTxt1 = await response.json();
			const mgmlTxt = mgmlTxt1[idx];
			try {
				// setImageData(convertBase64ToDataUrl(mgmlTxt['image_data']))
				const doc_id = mgmlTxt._id
				setcurrDocId(doc_id);
				const mjmlToHtmlResp = await fetch(getBaseUrlNodeServer() + 'mjml2html', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({'mgml': mgmlTxt.content})
				})
				const htmlJson = await mjmlToHtmlResp.json()
				setTemplateHtml(htmlJson.html);
				captureScreenshot(htmlJson.html, setScreenshotUrl);
			} catch (e) {
				setTemplateHtml("");
			}
			setIsLoading(false);
		}
		getRecords();
	}, []);
	const containerStyle = {
		width: '100%',             // Set the container width to fit the image
		overflow: 'hidden',       // Hide any overflow
	};
	const imgStyle = {
		// width: 'calc(100% - 200px)', // Adjust the width to hide the right 50 pixels
		// height: 'auto',             // Maintain aspect ratio
		// objectFit: 'cover',
		// maxHeight: "800px",
		overflow: "hidden",
		minWidth: "300px"
	};
	if (isLoading) {
		return <div></div>
	} else {
		return (
			<>
				{/*<img src={imagedata} style={imgStyle}></img>*/}
				{/*// <div onClick={() => setTrigger(currDocId)} style={{cursor: 'pointer'}}>*/}
				{/*// 	<div style={containerStyle}>*/}
				{/*<img src={screenshotUrl}/>*/}
				<div dangerouslySetInnerHTML={{__html: templateHtml}}
				     style={{
					     maxHeight: '500px',
					     // overflowY: 'hidden',
					     maxWidth: '20vw',
					     // borderRadius: '10px'
				     }}/>
				{/*// </div>*/}
				{/*// </div>);*/}
			</>
		)
	}
}