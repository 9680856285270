import * as React from "react";
import {useState} from "react";

type ModalPopupProps = {
    heading?: string; description?: string; children?: React.ReactNode; onClose: () => void
};

export const ModalPopup = ({children, heading, description, onClose}: ModalPopupProps) => {
    const [showModal, setShowModal] = useState<boolean>(true)

    function modalClickHandler() {
        setShowModal(false);
        onClose();
    }

    return (<>
        {showModal && <>
			<div
				className="py-6 no-scrollbar animate-fade-in fixed inset-0 z-50 flex w-full items-center justify-center overflow-y-auto overflow-x-hidden outline-none backdrop-blur-sm focus:outline-none">
				<div
					className="py-6 relative  mt-48 sm:mt-0 flex w-full mx-6 sm:w-[70%] md:w-[50%] lg:w-[40%] flex-col items-center justify-center isolate  gap-2 rounded-[20px] bg-white shadow-lg">
					<div className="right-4  flex w-full justify-end relative">
                        {/*<div className="fa fa-times cursor-pointer absolute" onClick={() => modalClickHandler()}*/}
						<div className="cursor-pointer absolute" onClick={() => modalClickHandler()}
						     style={{
                                 width: "50px",
                                 height: "30px",
                                 borderRadius: "30px",
                                 backgroundColor: "lightgrey",
                                 textAlign: "center"
                             }}>
							Close
						</div>
					</div>
                    {/*style={{backgroundColor:"green"}}*/}
					<div className="mb-4 font-bold text-xl uppercase flex w-full justify-center">
                        {heading}
					</div>
					<div className="mb-4 px-8 max-w-[470px] text-center">
                        {description}
					</div>
					<div className="mb-4 px-8 max-w-full text-center">
                        {children}
					</div>
				</div>
			</div>
			<div className="fixed inset-0 z-40 bg-black opacity-25"></div>
		</>}
    </>);
};
