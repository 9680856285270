import React, {useEffect, useState} from 'react';
import axios from "axios";
import {toast} from 'react-toastify';
import './Campaign.css'
import Modal from '../MediaUpload/Modal'
import AddLeads from './AddLeads'
import ListboxFromList from "./ListboxFromList";
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import {getBaseUrlNodeServer} from "../../config";

const EditCampaign = ({currentCampaignFromParent}) => {
	const [currentCampaign, setcurrentCampaign] = useState(currentCampaignFromParent);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedContacts, setParentSelectedContacts] = useState([]);
	const [usertemplates, setusertemplates] = useState([]);
	const verifiedemail = sessionStorage.getItem("verifiedemail");
	const [isLoading, setIsLoading] = useState(true);
	const [selectedItem, setselectedItem] = useState('');
	const [templateIdToHtmlDict, settemplateIdToHtmlDict] = useState({})
	const [showCursorText, setShowCursorText] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({x: 0, y: 0});
	
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};
	
	useEffect(() => {
		window.scrollTo(0, 0);
		
		async function getCampaigns() {
			if (currentCampaignFromParent == null || currentCampaignFromParent == undefined || currentCampaignFromParent == '') {
				return
			}
			axios.defaults.baseURL = getBaseUrlNodeServer()
			axios.post('getonecampaign', {'verifiedemail': verifiedemail, 'campaignname': currentCampaign.campaignname}).then(response => {
					const usercampaignArr = response.data;
					setcurrentCampaign(usercampaignArr[0]);
					setselectedItem(usercampaignArr[0].seletectTemplateId)
					setParentSelectedContacts(usercampaignArr[0].targetLeads)
					//TODO set the item in the list as well the selected template
					// setusercampaigns(usercampaigns);
				}
			).catch(err => console.log(err));
			
			const response = await axios.post('getusersavedtemplates', {'verifiedemail': verifiedemail})
			const usertemplates = response.data
			
			const promises = usertemplates.map(async (template) => {
				const responseJson = await fetch(getBaseUrlNodeServer() + 'mjml2html', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({'mgml': template.content})
				})
				// console.log(template.subject);
				const htmlJson = await responseJson.json();
				return {
					[template._id]: {
						'subject': template.subject,
						'html': htmlJson.html
					}
				};
			})
			const results = await Promise.all(promises);
			const finalResult = Object.assign({}, ...results);
			
			settemplateIdToHtmlDict(finalResult);
			setusertemplates(usertemplates)
			setIsLoading(false);
		}
		
		getCampaigns();
	}, []);
	
	const handleAddLeadsClick = () => {
		setIsModalOpen(true);
	};
	
	function getStatus(targetLeads, seletectTemplateId) {
		if (targetLeads.length > 0 && seletectTemplateId.length > 4) {
			return 'Ready'
		} else {
			return 'Draft'
		}
	}
	
	function getAndSetCampaign() {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		axios.post('getonecampaign', {'verifiedemail': verifiedemail, 'campaignname': currentCampaign.campaignname}).then(response => {
				const usercampaignArr = response.data
				setcurrentCampaign(usercampaignArr[0])
			}
		).catch(err => console.log(err));
	}
	
	function saveCampaign() {
		const targetLeads = selectedContacts;
		const seletectTemplateId = selectedItem;
		const selectedItemHtml = templateIdToHtmlDict[selectedItem]['html'];
		
		axios.defaults.baseURL = getBaseUrlNodeServer()
		// getStatus(targetLeads, seletectTemplateId)
		axios.post('savecampaign', {
			'verifiedemail': verifiedemail,
			'campaignname': currentCampaign.campaignname, 'targetLeads': targetLeads, 'seletectTemplateId': seletectTemplateId,
			'selectedItemHtml': selectedItemHtml
		}).then(response => {
				// console.log(response.data);
			}
		).catch(err => console.log(err));
		
		getAndSetCampaign();
	}
	
	function setCampaignStatus(status) {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		axios.post('setcampaignstatus', {
			'verifiedemail': verifiedemail,
			'campaignname': currentCampaign.campaignname,
			'targetLeads': selectedContacts,
			'seletectTemplateId': selectedItem,
			'status': status
		}).then(response => {
				// console.log(response.data);
			}
		).catch(err => console.log(err));
		getAndSetCampaign();
	}
	
	function pauseCampaign() {
		setCampaignStatus('Paused');
	}
	
	function scheduleCampaign() {
		setCampaignStatus('Running');
	}
	
	function clickRun() {
		scheduleCampaign()
		toast.success("Running now !! Sit Tight !!")
	}
	
	function clickPause() {
		pauseCampaign();
		toast.success("Paused Campaign !! No more emails will be sent !!")
	}
	
	const handleMouseEnter = () => {
		setShowCursorText(true);
	};
	
	const handleMouseLeave = () => {
		setShowCursorText(false);
	};
	
	const handleMouseMove = (e) => {
		setCursorPosition({x: e.clientX, y: e.clientY});
	};
	const divStyle = {
		display: "flex",
	};
	
	const leftDivStyle = {
		flex: 1, // backgroundColor: "#f2f2f2",
		padding: "20px",
	};
	
	const rightDivStyle = {
		// borderLeft: "1px solid #ccc",
		paddingLeft: "20px", marginLeft: "-100px", flex: 1, // backgroundColor: "#e0e0e0",
	};
	
	function goToTemplateEdit() {
		console.log(currentCampaign);
		// props.history.push(`/editor/${currentCampaign.seletectTemplateId}`);
	}
	
	if (currentCampaign != undefined && currentCampaign.campaignname != undefined &&
		currentCampaign.campaignname.length > 0) {
		return (<div style={{minHeight: '1000px', textAlign: 'left', marginTop: '0px'}}>
				<div style={{marginTop: "0px", marginLeft: "-20px", minHeight: "900px"}}>
					<div style={divStyle}>
						<div style={leftDivStyle}>
							<div style={{
								border: "1px solid lightgrey", borderRadius: "30px", maxWidth: "350px", paddingLeft: "20px", paddingRight: "20px"
							}}>
								<p>Campaign Name:<span
									style={{
										color: 'black', fontSize: '20px', marginLeft: "10px", marginRight: "10px"
									}}>{currentCampaign.campaignname}</span>
								</p>
								Campaign Status: <span style={{color: 'black', fontSize: '20px', marginLeft: "10px"}}>{currentCampaign.Status}</span>
								{/*<hr/>*/}
								{/*<Stack spacing={2} direction="column" style={{justifyContent: "left", marginRight: "100px", marginTop: "20px"}}>*/}
								<div style={{marginBottom: "10px", marginTop: "10px"}}>
									<label>Manage Leads</label>
									<Button variant="outlined" type='submit' className='btn' style={{marginLeft: '10px'}}
									        onClick={handleAddLeadsClick}>
										Manage Leads</Button>
									<p><span style={{
										color: 'black', fontSize: '20px', marginLeft: "100px"
									}}>{selectedContacts != undefined ? selectedContacts.length : 0}</span> Leads
										Selected</p>
								
								</div>
							</div>
							{/*<hr style={{marginBottom: "20px", marginTop: "20px"}}/>*/}
							<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
								<AddLeads currentCampaign={currentCampaign}
								          setParentSelectedContacts={setParentSelectedContacts}
								          parentSelectedContacts={selectedContacts}
								/>
							</Modal>
						</div>
						
						<div>
							<div style={{
								float: "left", marginLeft: "-390px", marginTop: '-60px', border: "1px solid lightgrey", borderRadius: "20px",
								paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px"
							}}>
								<ListboxFromList list={usertemplates.map(template => template._id)} setselectedItemParent={setselectedItem}
								                 parentSelectedItem={selectedItem}/>
								
								<br/>
								<hr/>
								<br/>
								<div style={{marginBottom: "10px", display: "block", marginTop: "20px"}}>
									<label>Save Campaign</label>
									<Button variant="outlined" type='submit' className='btn' style={{marginLeft: '10px'}}
									        onClick={saveCampaign}>
										Save Campaign</Button>
									{/*<Stack spacing={2} direction="row" style={{marginRight: "100px"}}>*/}
								</div>
								
								<div>
									<br/>
									<hr/>
									<br/>
									<div style={{marginBottom: "10px"}}>
										<label>Run Campaign</label>
										<Button variant="outlined" type='submit' className='btn' style={{marginLeft: '10px'}}
										        onClick={clickRun}> Run</Button>
									</div>
									<div style={{marginBottom: "10px"}}>
										<label>Pause Campaign</label>
										<Button variant="outlined" type='submit' className='btn' style={{marginLeft: '10px'}}
										        onClick={clickPause}>Pause</Button>
									</div>
								</div>
							</div>
						
						</div>
						
						<div style={rightDivStyle}>
							<div style={{display: 'block', marginTop: '-60px'}}>
								<div>
									<h5>Subject: {templateIdToHtmlDict[selectedItem] != undefined ? templateIdToHtmlDict[selectedItem]['subject'] : ''}</h5>
									<Link to={`/editor/${currentCampaign.seletectTemplateId}`}>
										<div className="hover-div"
										     onMouseEnter={handleMouseEnter}
										     onMouseLeave={handleMouseLeave}
										     onMouseMove={handleMouseMove}
											// onClick={goToTemplateEdit}
										>
											<div
												// className="content-div" onClick={handleClick}
												dangerouslySetInnerHTML={{__html: templateIdToHtmlDict[selectedItem] != undefined ? templateIdToHtmlDict[selectedItem]['html'] : ''}}
												style={{
													maxWidth: '800px',
													marginBottom: '50px',
													marginTop: '20px',
													border: '1px dotted grey',
													overflowY: 'scroll',
													borderRadius: '10px',
													paddingTop: "10px",
													paddingBottom: "10px",
													marginRight: "30px"
												}}>
											</div>
											{showCursorText && (<div
												className="cursor-text"
												style={{left: cursorPosition.x + 10, top: cursorPosition.y + 10}}>
												Click to edit
											</div>)}
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		return (<div style={{minHeight: '1000px', textAlign: 'left', marginTop: '20px'}}>
				Go to Campaigns
				<br/>
				Select a Campaign to manage
			</div>
		)
	}
	
};

export default EditCampaign;
