import React, {useState} from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CodeBlock = ({code, heading, index, lastIndex}) => {
	const [copySuccess, setCopySuccess] = useState('');
	
	const copyToClipboard = () => {
		navigator.clipboard.writeText(code).then(() => {
			setCopySuccess('Copied!');
			setTimeout(() => setCopySuccess(''), 1000); // Clear the message after 1 second
		}, () => {
			setCopySuccess('Failed to copy!');
			setTimeout(() => setCopySuccess(''), 1000); // Clear the message after 1 second
		});
	};
	
	return (<div style={{
		marginTop: "0px",
		display: "flex",
		// maxWidth: "300px",
		minHeight: "30px",
		paddingBottom: lastIndex ? "0px" : "0px",
		overflow: "hidden"
	}}>
		<div>
			<div style={{display: "flex"}}>
				{index == -1 ? heading : <p style={{color: "white", marginBottom: "0px", maxWidth: "300px"}}>{index + 1}. {heading}</p>
				}
				<div style={{marginTop: "-2px", marginLeft: "2px", marginBottom: "2px"}}>
					<button onClick={copyToClipboard} style={{fontSize: "12px"}}>
						<ContentCopyIcon style={{height: "15px"}}/>
					</button>
				</div>
			</div>
			<pre style={{
				maxHeight: "20px",
				overflow: "hidden",
				marginLeft: "30px",
				backgroundColor: "#f5f5f542",
				color: "lightgrey",
				maxWidth: "300px",
				overflowWrap: "break-word",
				whiteSpace: "pre-wrap",  // Ensures that the text wraps
				paddingLeft: "10px",
				paddingTop: "2px",
				// paddingBottom: "10px",
				paddingRight: "0px",
				borderRadius: "10px",
				fontSize: "11px"
			}}>
		    <code>{code}</code>
		</pre>
		</div>
		{copySuccess && <div style={{fontSize: "12px", marginLeft: "10px"}}>{copySuccess}</div>}
	</div>);
};

export default CodeBlock;
