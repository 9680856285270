// HorizontalSubMenuComponent.jsx
import React, {useState} from 'react';
import './HorizontalSubMenuComponent.css';
import NewCampaign from './NewCampaign';
import EditCampaign from './EditCampaign';

const CampaignHorizontalSubMenuComponent = ({index}) => {
	const [activeIndex, setActiveIndex] = useState(index);
	const [currentCampaign, setcurrentCampaign] = useState(null);
	const ContentComponentOne = () => <NewCampaign onChildAction={setActiveIndex} setcurrentCampaign={setcurrentCampaign}/>;
	const ContentComponentTwo = () => <EditCampaign currentCampaignFromParent={currentCampaign}/>;
	
	const submenuItems = [
		{
			title: 'Campaigns',
			content: ContentComponentOne,
		},
		{
			title: 'Manage Campaign',
			content: ContentComponentTwo,
		}
	];
	
	const handleItemClick = (index) => {
		setActiveIndex(index);
	};
	
	const ActiveContentComponent = submenuItems[activeIndex].content;
	
	return (
		<div>
			<div className="submenu-container" style={{marginTop: '20px', maxWidth: '400px'}}>
				{submenuItems.map((item, index) => (
					<div
						style={{marginLeft: '10px', textAlign: 'left'}}
						key={index}
						className={`submenu-item ${index === activeIndex ? 'active' : ''}`}
						onClick={() => handleItemClick(index)}>
						{item.title}
					</div>
				))}
			</div>
			<div className="content-container">
				{/* Render the active component */}
				<ActiveContentComponent/>
			</div>
		</div>
	);
};

export default CampaignHorizontalSubMenuComponent;