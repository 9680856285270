import React, {Component} from 'react'
import topregistrars from "../Blogs/images/topregistrars.png";

export default class TopRegistrationProviders extends Component {
	state = {
		confirming: true
	}
	componentDidMount = () => {
		this.setState({confirming: false})
	}
	render = () => {
		return (
			
			<div className='row' style={{
				display: "flex",
				justifyContent: "center",
				marginTop: "50px",
				// width: "70%",
			}}>
				<div
					className='col-sm'
					style={{
						display: "flex",
						justifyContent: "center",
						fontFamily: 'Roboto, sans-serif',
						fontWeight: 500,
						border: '0.2px solid lightgray',
						padding: '10px 20px',
						boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',
						borderRadius: '20px',
						margin: '0 20px',
						// width: '40%',
					}}
				>
					<div style={{float: "left", display: "flex"}}>
						<div style={{justifyContent: "center"}}>
							<p style={{fontFamily: 'Roboto,sans-serif', color: 'gray', fontSize: 20}}>
								Top Company Registration Providers (2019 - 2020)</p>
							<li style={{listStyleType: 'none', fontSize: 16}}>An estimated 2 Lakh indian companies got registered from 2019 to 2020.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>
								Less than 1 % of the companies secured custom domain on day 1 of registration.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}> Around 83% of these entities used Gmail, Yahoo or Outlook for email
								contact.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>It shows that new companies are not aware about brand value or they
								don't have technical resources.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>The remaining 17% companies paid relatively premium price on company
								registration.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>
								As they took services of well established franchised platform of lawyers. Also got custom email
								addresses.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>Our data shows that the top 8 registration websites favoured by these
								30,000 companies are as follows,
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>Setindiabiz.com ,Legalraasta.com ,kanakkupillai.com
								,Sujataassociates.com
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}> Companyji.com ,Taxtoday.in ,Neerajbhagat.com ,Legacypartners.in
							</li>
						</div>
						<img
							width={600}
							height={400}
							className="mr-3"
							src={topregistrars}
							alt="Rise of Registrations in 2020 by Industrial Sector, Covid\'19'"
						/>
					</div>
					<div>
					
					</div>
				</div>
				{/*<div*/}
				{/*	className='col-sm'*/}
				{/*	style={{*/}
				{/*		fontFamily: 'Roboto, sans-serif',*/}
				{/*		fontWeight: 500,*/}
				{/*		border: '0.2px solid lightgray',*/}
				{/*		padding: '10px 20px',*/}
				{/*		boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',*/}
				{/*		borderRadius: '20px',*/}
				{/*		margin: '0 20px',*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<img*/}
				{/*		width={600}*/}
				{/*		height={400}*/}
				{/*		className="mr-3"*/}
				{/*		src={rise2020}*/}
				{/*		alt="Rise of Registrations in 2020 by Industrial Sector, Covid\'19'"*/}
				{/*	/>*/}
				{/*</div>*/}
			</div>
		)
	}
}