import React, {useEffect, useRef, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import {Button} from "@mui/material";
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";
import './whatsapp.css'
import {upsertToDb} from "./dbUtils/simpleDbUtils";
import DropdownList from "./TemplateBuilder/DropdownList";
import CodeBlock from "../Utils/UI/CodeBlock";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

export default function Whatsapp() {
	const tableRef = useRef(null);
	var signedin = sessionStorage.getItem("signedin");
	const [msgLog, setmsgLog] = useState([]);
	const [accessToken, setaccessToken] = useState('');
	const [phoneNoId, setphoneNoId] = useState('');
	const [phoneNo, setphoneNo] = useState('');
	const [templateName, settemplateName] = useState('');
	const [templateData, settemplateData] = useState([]);
	const [chatHistory, setchatHistory] = useState([]);
	const [fltrdChatHistory, setfltrdChatHistory] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');
	const [selectedEventTypeValue, setselectedEventTypeValue] = useState('');
	const [headerOptions, setheaderOptions] = useState([]);
	const [eventHeaderOptions, seteventHeaderOptions] = useState([]);
	const [userAPIToken, setuserAPIToken] = useState('');
	const [isVisible, setIsVisible] = useState(false);
	const scrollableContainerRef = useRef(null);
	
	function toggleVisibility() {
		if (isVisible) {
			if (scrollableContainerRef.current) {
				scrollableContainerRef.current.scrollLeft = 0;
			}
		}
		setIsVisible(!isVisible)
	}
	
	const currentURL = window.location.href;
	const hostInfo = currentURL.includes('localhost') ? 'http://localhost' : 'https://mailchamp.ai'
	const codeblocks = [{
		'heading': 'broadcast txt msg',
		'codeText': 'curl -X POST "' + hostInfo + ':8080/externalSendTextMsgToManyPhonenos" \\\n' +
			'     -H "Content-Type: application/json" \\\n' +
			'     -d \'{\n' +
			'           "uniqueClientToken": "' + userAPIToken + '",\n' +
			'           "sendToNumbers":"14159608418,16505056526",\n' +
			'           "encryptedMsg":"this is sample text the user is trying to send to his audience"\n' +
			'         }\''
	}, {
		'heading': 'fetch all chat history (encrypted)',
		'codeText': 'curl -X POST "' + hostInfo + ':8080/externalGetChatHistory" \\\n' +
			'     -H "Content-Type: application/json" \\\n' +
			'     -d \'{\n' +
			'           "uniqueClientToken": "' + userAPIToken + '"\n' +
			'         }\''
	}, {
		'heading': 'broadcast non-variable template',
		'codeText': 'curl -X POST "' + hostInfo + ':8080/externalSendNonVarTemplate" \\\n' +
			'     -H "Content-Type: application/json" \\\n' +
			'     -d \'{\n' +
			'           "uniqueClientToken": "' + userAPIToken + '",\n' +
			'           "sendToNumbers":"14159608418,16505056526",\n' +
			'           "templateName":"sdnewspublished"\n' +
			'         }\''
	}, {
		'heading': 'broadcast templates with variables in header, body and footer only',
		'codeText': 'curl -X POST "' + hostInfo + ':8080/externalSendVarTemplate" \\\n' +
			'     -H "Content-Type: application/json" \\\n' +
			'     -d \'{\n' +
			'           "uniqueClientToken": "' + userAPIToken + '",\n' +
			'           "sendToNumbers":"14159608418,16505056526",\n' +
			'           "templateName":"student_opt_in_form",\n' +
			'           "variablesDict":"{\\"HEADER\\": [\\"Interested in joining our whatsapp teacher\\"],\\"BODY\\":[\\"Rohit\\",\\"The body text total size can be 1024, including what else is there besides the variable value part here.\\"]}"\n' +
			'         }\''
	}, {
		'heading': 'broadcast templates with variables in header, body, footer and button (url endpoint changeable)',
		'codeText': 'curl -X POST "' + hostInfo + ':8080/externalSendVarTemplate" \\\n' +
			'     -H "Content-Type: application/json" \\\n' +
			'     -d \'{\n' +
			'           "uniqueClientToken": "' + userAPIToken + '",\n' +
			'           "sendToNumbers":"14159608418,16505056526",\n' +
			'           "templateName":"button_link_example",\n' +
			'           "variablesDict":"{\\"HEADER\\": [\\"This is a mathematics problem\\"],' +
			'           \\"BODY\\":' +
			'                   [\\"if x + y = 2 and y - 2 = 0 then the values of x and y can be\\",' +
			'                   \\"1. x = 2 and y = 3\\", \\"2. y = 2 and x = 0\\",' +
			'                   \\"3. x = -1 and y = 12\\", \\"4. Give me the answer\\"' +
			'                   ],' +
			'           \\"BUTTON\\":' +
			'                   [\\"variableAppendAtTheEndOfUrl\\"]' +
			'           }"\n' +
			'         }\''
	}, {
		'heading': 'broadcast pdf / img / video msg',
		'codeText': 'curl -X POST "' + hostInfo + ':8080/externalGetChatHistory" \\\n' + '     -H "Content-Type: application/json" \\\n' + '     -d \'{\n' + '           "uniqueClientToken": "' + userAPIToken + '"\n' + '         }\''
	}
	
	]
	
	function scrollToLocation(location) {
		if (tableRef.current) {
			tableRef.current.scrollIntoView({block: location, behavior: 'smooth'});
		}
	}
	
	function formatTimestamp(timestampInSeconds) {
		const date = new Date(timestampInSeconds * 1000); // Convert to milliseconds
		
		const month = date.getMonth() + 1; // getMonth() returns 0-11
		const day = date.getDate(); // getDate() returns 1-31
		const year = date.getFullYear(); // getFullYear() returns the year
		
		const hours = date.getHours(); // getHours() returns 0-23
		const minutes = date.getMinutes(); // getMinutes() returns 0-59
		
		// Pad single digit minutes and months with a leading zero
		const formattedMonth = month.toString().padStart(2, '0');
		const formattedDay = day.toString().padStart(2, '0');
		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		
		return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}`;
	}
	
	function setSelectedValueHandler(eventSelectedValue) {
		setSelectedValue(eventSelectedValue)
		if (eventSelectedValue.toLowerCase().trim().length !== 0) {
			if (selectedEventTypeValue.trim().length > 0) {
				setfltrdChatHistory(chatHistory.filter(rec => (rec.sendToNumber === eventSelectedValue) && (rec.eventType === selectedEventTypeValue)))
			} else {
				setfltrdChatHistory(chatHistory.filter(rec => (rec.sendToNumber === eventSelectedValue)))
			}
		} else {
			if (selectedEventTypeValue.trim().length > 0) {
				setfltrdChatHistory(chatHistory.filter(rec => (rec.eventType === selectedEventTypeValue)))
			} else {
				setfltrdChatHistory(chatHistory)
			}
		}
	}
	
	function setSelectedEventTypeValueHandler(eventSelectedValue) {
		setselectedEventTypeValue(eventSelectedValue)
		if (eventSelectedValue.toLowerCase().trim().length !== 0) {
			if (selectedValue.trim().length > 0) {
				setfltrdChatHistory(chatHistory.filter(rec => (rec.eventType === eventSelectedValue) && (rec.sendToNumber === selectedValue)))
			} else {
				setfltrdChatHistory(chatHistory.filter(rec => (rec.eventType === eventSelectedValue)))
			}
		} else {
			if (selectedValue.trim().length > 0) {
				setfltrdChatHistory(chatHistory.filter(rec => (rec.sendToNumber === selectedValue)))
			} else {
				setfltrdChatHistory(chatHistory)
			}
		}
	}
	
	function changeTemplateNameFieldId(e) {
		settemplateName(e.target.value)
	}
	
	function sendWhatsappMsg() {
		const sendToNumber = document.getElementById('sendToPhoneNo').value
		try {
			axios.defaults.baseURL = getBaseUrlNodeServer()
			axios.post('sendWhatsappMsgTemplate', {
				'accessToken': accessToken,
				'sendToNumber': sendToNumber, 'templateName': templateName, phoneNoId: phoneNoId, phoneNo: phoneNo
			}).then(response => {
				const responseData = response.data
				try {
					document.getElementById('sendmessageresponse').innerText = responseData['messages'][0]['message_status']
					document.getElementById('templateNameFieldInput').value = '';
					document.getElementById('sendToPhoneNo').value = '';
				} catch (e) {
					document.getElementById('sendmessageresponse').innerText = 'Invalid / Failure / Expired Access Token'
				}
			}).catch(err => console.log(err));
		} catch (error) {
			console.error('Error during fetch:', error.message);
		}
	}
	
	function sendWhatsappMsgTxt() {
		const sendToNumber = document.getElementById('sendToPhoneNo').value
		try {
			axios.defaults.baseURL = getBaseUrlNodeServer()
			const textMessage = document.getElementById('userTextInputToMsg').value
			axios.post('sendWhatsappMsgText', {
				'accessToken': accessToken, 'sendToNumber': sendToNumber, 'textMessage': textMessage, 'phoneNoId': phoneNoId
			}).then(response => {
				const responseData = response.data
				// console.log('responseData', responseData);
				document.getElementById('userTextInputToMsg').value = ''
			}).catch(err => console.log(err));
		} catch (error) {
			console.error('Error during fetch:', error.message);
		}
	}
	
	function getChatHistory(phoneNo, phoneNoId, keepFilterStatesIntact = false) {
		const currentSelectedValue = selectedValue;
		const currentEventTypeSelectedValue = selectedEventTypeValue;
		
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('getchathistoryforsender', {
			collectionName: 'whatsappChatHistory', phoneNo: phoneNo, phoneNoId: phoneNoId
		}).then(response2 => {
			const keysToCheck = ['sendToNumber', 'templateName', 'timestamp', 'eventType', 'responseText'];
			const updatedData = response2.data.map(item => {
				const newItem = {...item}; // Create a copy of the item
				keysToCheck.forEach(key => {
					if (!newItem[key]) { // Check if the key is missing or falsy
						newItem[key] = '-'; // Assign default value
					}
				});
				return newItem;
			});
			
			const seenSendToNumbers = new Set();
			const seenEventTypes = new Set();
			
			updatedData.sort((item) => {
				if (!seenSendToNumbers.has(item.sendToNumber)) {
					seenSendToNumbers.add(item.sendToNumber);
				}
				if (!seenEventTypes.has(item.eventType)) {
					seenEventTypes.add(item.eventType);
				}
			})
			
			const freshData = updatedData.sort((a, b) => {
				const timestampA = new Date(a.timestamp).getTime();
				const timestampB = new Date(b.timestamp).getTime();
				return (timestampA - timestampB);
			}).map(item => ({
				...item,
				dateInfo: formatTimestamp(item.timestamp)
			}));
			
			setchatHistory(freshData);
			setfltrdChatHistory(freshData)
			const headerOptions = [...seenSendToNumbers].map(rec => ({value: rec, label: rec}));
			setheaderOptions(headerOptions);
			
			const eventHeaderOptions = [...seenEventTypes].map(rec => ({value: rec, label: rec}));
			seteventHeaderOptions(eventHeaderOptions)
			
			if (keepFilterStatesIntact) {
				setSelectedValueHandler(currentSelectedValue);
				setSelectedEventTypeValueHandler(currentEventTypeSelectedValue);
			} else {
			
			}
		})
	}
	
	useEffect(() => {
		function getAccessToken() {
			axios.defaults.baseURL = getBaseUrlNodeServer();
			axios.post('getCollectionRecData', {
				collectionName: 'userdata',
				idQuery: {'email': sessionStorage.getItem("verifiedemail")}
			}).then(response => {
				setaccessToken(response.data['accessToken']);
				setphoneNoId(response.data['phoneNoId']);
				setphoneNo(response.data['phoneNo']);
				if ('mailChampApiAccessToken' in response.data) {
					setuserAPIToken(response.data['mailChampApiAccessToken'])
				} else {
					generateAPITokenAndStore();
				}
				getChatHistory(response.data['phoneNo'], response.data['phoneNoId'])
			})
		}
		
		getAccessToken();
		// const intervalId = setInterval(fetchDataFromMongoDB, 20000); // Polling every 5 seconds
		// Cleanup the interval when the component unmounts
		// return () => clearInterval(intervalId);
	}, []);
	
	function refreshDataFromDb() {
		// console.log('I am clicked')
		getChatHistory(phoneNo, phoneNoId, true)
	}
	
	function generateAPITokenAndStore() {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		axios.get('generateApiToken').then(newToken => {
			setuserAPIToken(newToken.data)
			//store this in the database
			upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'mailChampApiAccessToken': newToken.data})
		})
	}
	
	function clearFilters() {
		getChatHistory(phoneNo, phoneNoId, false)
		setSelectedValue('');
		setselectedEventTypeValue('');
	}
	
	function handleInputChange(e) {
		const localToken = e.target.value
		setaccessToken(localToken);
		//Sync to the database as well
		upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'accessToken': localToken})
	}
	
	function handlePhoneNoInputChange(e) {
		const localToken = e.target.value
		setphoneNo(localToken);
		//Sync to the database as well
		upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'phoneNo': localToken})
	}
	
	function handlePhoneNoIdInputChange(e) {
		const localToken = e.target.value
		setphoneNoId(localToken);
		//Sync to the database as well
		upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'phoneNoId': localToken})
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div ref={scrollableContainerRef} style={{
			marginTop: "10px", marginLeft: "0px", minHeight: "0px", overflow: isVisible ? "scroll" : "hidden",
		}}>
			<Button variant='outlined' onClick={toggleVisibility} style={{
				height: "30px", textTransform: "none", marginRight: "10px", backgroundColor: "grey", color: "white", float: "right"
			}}>
				Toggle API Info
			</Button>
			<div style={{display: "flex"}}>
				
				<div style={{
					flex: 1, border: "0px 1px 0px 0px solid lightgrey", borderRight: "1px dotted lightgrey", // width:"50%"
					// maxWidth: "1000px"
				}}>
					{/*<div style={{display: "flex", float: "right"}}>*/}
					{/*</div>*/}
					<div style={{display: "flex"}}>
						<p>Broadcast Template:</p>
						<div style={{fontSize: "9px", marginTop: "6px", marginLeft: "100px"}}>Your information is private and secure</div>
					</div>
					
					{/*<hr style={{width: "600px", marginTop: "5px"}}/>*/}
					
					<div style={{marginTop: "10px"}}>
						<div style={{width: "100px", float: "left", color: 'grey', marginTop: "8px"}}><label>AccessToken:</label></div>
						<input id='accessTokenHolderInputId' placeholder='enter your one time access token' onChange={handleInputChange}
						       value={accessToken}
						       style={{
							       height: "40px",
							       minWidth: "350px",
							       border: "1px solid lightgrey",
							       textAlign: "center",
							       color: "grey",
							       borderRadius: "10px",
							       padding: "10px"
						       }} required={true}
						></input>
					</div>
					
					<div style={{marginTop: "10px"}}>
						<div style={{width: "100px", float: "left", color: 'grey', marginTop: "8px"}}><label>PhoneNo:</label></div>
						<input id='PhoneNo' placeholder='phone number from whatsapp manager account'
						       onChange={handlePhoneNoInputChange}
						       value={phoneNo}
						       style={{
							       height: "40px",
							       minWidth: "350px",
							       border: "1px solid lightgrey",
							       textAlign: "center",
							       color: "grey",
							       borderRadius: "10px"
						       }} required={true}
						></input>
					</div>
					
					<div style={{marginTop: "10px"}}>
						<div style={{width: "100px", float: "left", color: 'grey', marginTop: "8px"}}><label>PhoneNoId:</label></div>
						<input id='phoneNoId' placeholder='phone number id from whatsapp manager account'
						       onChange={handlePhoneNoIdInputChange}
						       value={phoneNoId}
						       style={{
							       height: "40px",
							       minWidth: "350px",
							       border: "1px solid lightgrey",
							       textAlign: "center",
							       color: "grey",
							       borderRadius: "10px"
						       }} required={true}
						></input>
					</div>
					
					<div style={{display: "flex"}}>
						<div style={{width: "100px", float: "left", color: 'grey', marginTop: "20px", marginRight: "20px"}}>
							<label>TemplateName:</label>
						</div>
						<input id='templateNameFieldInput' type='text' onChange={changeTemplateNameFieldId} style={{
							height: "40px",
							minWidth: "200px",
							border: "1px solid lightgrey",
							textAlign: "center",
							color: "grey",
							borderRadius: "10px",
							marginTop: "10px"
						}} required={true}
						></input>
					</div>
					<div style={{display: "flex"}}>
						<div style={{width: "100px", float: "left", color: 'grey', marginTop: "20px", marginRight: "20px"}}><label>Send To:</label>
						</div>
						<input typeof='tel' id='sendToPhoneNo' type="phone" style={{
							height: "40px",
							minWidth: "200px",
							border: "1px solid lightgrey",
							textAlign: "center",
							color: "grey",
							borderRadius: "10px",
							marginTop: "10px"
						}} required={true}
						></input>
					</div>
					
					<Button variant='outlined' style={{
						border: "1px solid", marginTop: "10px", height: "30px", marginLeft: "120px",
						textTransform: "none"
					}}
					        type="submit" disabled={false}
					        onClick={sendWhatsappMsg}
					>
						Send Message
					</Button>
					<div style={{marginLeft: "0px", marginTop: "0px", color: "grey"}}>Send Status:<span style={{marginLeft: "20px"}}
					                                                                                    id='sendmessageresponse'></span></div>
					{/*<hr style={{marginTop: "20px", marginBottom: "10px"}}/>*/}
					<div style={{marginTop: "20px"}}>
						<div style={{display: "flex"}}><p>Chat History</p> <p style={{marginTop: "0px", marginLeft: "10px", color: "grey"}}>
							Record Count: <span
							style={{color: "blue"}}>{fltrdChatHistory.length}</span></p></div>
						
						<div style={{display: "flex", color: "grey"}}><p style={{marginTop: "14px"}}></p>
							<div style={{marginLeft: "10px", marginBottom: "10px", fontSize: "14px"}}>
								<DropdownList labelText='Filters:' selectedValue={selectedValue}
								              setSelectedValue={setSelectedValueHandler}
								              options={headerOptions}
								              defaultValue='All Numbers'/>
							</div>
							<div style={{marginLeft: "-10px", marginBottom: "10px", fontSize: "14px"}}>
								<DropdownList labelText='' selectedValue={selectedEventTypeValue}
								              setSelectedValue={setSelectedEventTypeValueHandler}
								              options={eventHeaderOptions}
								              defaultValue='All EventTypes'/>
							</div>
							<div className='refreshDataButton' onClick={() => refreshDataFromDb()} style={{
								marginTop: "8px",
								marginLeft: "20px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								marginBottom: "10px",
								cursor: "pointer"
							}}>
								<div className="noselect" style={{marginTop: "4px", paddingRight: "5px", paddingBottom: "5px"}}>
									<AutorenewIcon style={{height: "18px", color: "blue"}}/>
									Refresh Data
								</div>
							</div>
							
							<div className='refreshDataButton' onClick={() => clearFilters()} style={{
								marginTop: "8px",
								marginLeft: "20px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								marginBottom: "10px",
								cursor: "pointer"
							}}>
								<div className="noselect" style={{marginTop: "4px", paddingRight: "5px", paddingBottom: "5px"}}>
									<RestartAltIcon style={{height: "18px", color: "darkred"}}/>
									Reset Filters
								</div>
							</div>
							{/*<div style={{display: "block", marginTop: "10px", maxWidth: "30px", padding: "0px", margin: "0px"}}>*/}
							{/*<div style={{width: "30px", padding: "0px", margin: "0px", marginTop: "10px"}}>*/}
							<p style={{marginLeft: "20px", marginTop: "13px"}}>Table</p>
							<NorthIcon style={{
								marginLeft: "10px",
								height: "25px",
								maxWidth: "30px",
								margin: "5px",
								border: "1px solid lightgrey",
								cursor: "pointer",
								opacity: "60%",
								marginTop: "11px"
							}}
							           onClick={() => scrollToLocation("start")}
							/>
							{/*</div>*/}
							<SouthIcon style={{
								height: "25px",
								maxWidth: "30px",
								margin: "5px",
								border: "1px solid lightgrey",
								cursor: "pointer",
								opacity: "60%",
								marginTop: "11px"
							}}
							           onClick={() => scrollToLocation("end")}
							/>
							{/*</div>*/}
						
						</div>
					</div>
					
					<div style={{display: "flex", marginRight: "10px"}}>
						<div style={{
							minHeight: "400px", maxHeight: "400px", border: "1px dotted lightgrey",
							borderRadius: "10px", padding: "10px", paddingTop: "5px", justifyContent: "center", display: "block", overflow: "scroll"
						}}>
							<table id='chathistorytable' style={{textAlign: 'center', tableLayout: "fixed", width: "100%"}} ref={tableRef}>
								<tr style={{backgroundColor: "lightcyan", position: "sticky", top: "0"}}>
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px", width: "30px"
									}}>Row
									</td>
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px", width: "90px"
									}}>MyPhone
									</td>
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px", width: "100px"
									}}>SendToNumber
									</td>
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px", width: "120px"
									}}>TemplateName
									</td>
									{/*<td style={{border: "1px solid lightgrey", padding: "2px"}}>timestamp</td>*/}
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px", width: "120px"
									}}>Date
									</td>
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px", width: "120px"
									}}>EventType
									</td>
									<td style={{
										border: "1px solid lightgrey", padding: "2px", fontSize: "12px",
										width: "150px"
									}}>VariablesDict
									</td>
									<td style={{
										border: "1px solid lightgrey",
										padding: "2px",
										fontSize: "12px", // minWidth: "400px"
									}}>Response/SendText
									</td>
								</tr>
								{fltrdChatHistory.map((rec, index) => {
									return (<tr style={{border: "1px solid lightgrey"}}>
										<td style={{
											border: "1px solid lightgrey", padding: "5px", fontSize: "11px"
										}}>{index + 1}</td>
										<td style={{border: "1px solid lightgrey", padding: "5px", fontSize: "11px"}}>{rec.phoneNo}</td>
										<td style={{border: "1px solid lightgrey", padding: "5px", fontSize: "11px"}}>{rec.sendToNumber}</td>
										<td style={{border: "1px solid lightgrey", padding: "5px", fontSize: "11px"}}>{rec.templateName}</td>
										{/*<td style={{border: "1px solid lightgrey", padding: "5px", fontSize: "11px"}}>{rec.timestamp}</td>*/}
										<td style={{
											border: "1px solid lightgrey",
											padding: "5px",
											fontSize: "11px",
											minWidth: "100px"
										}}>{rec.dateInfo}</td>
										<td style={{border: "1px solid lightgrey", padding: "5px", fontSize: "11px"}}>{rec.eventType}</td>
										<td style={{
											border: "1px solid lightgrey",
											padding: "5px",
											fontSize: "11px",
											
											wordWrap: "break-word",
											overflowWrap: "break-word",
											minWidth: "1px"
										}}>{rec.variablesDict}</td>
										
										<td style={{border: "1px solid lightgrey", padding: "5px", fontSize: "12px"}}>{rec.responseText}</td>
									</tr>)
								})}
							</table>
						</div>
					</div>
				</div>
				{/*	Right Side Div*/}
				<div style={{
					flex: 0, marginTop: "0px", position: 'relative'
				}}>
					<div onMouseEnter={() => toggleVisibility()} onMouseLeave={() => toggleVisibility()} style={{
						display: 'block', backgroundColor: "grey", position: 'absolute', right: isVisible ? '-140px' : '-450px', // Move in and out based on isVisible
						transition: 'right 0.5s ease-in-out',
						borderRadius: "20px", padding: "20px", textAlign: "left", marginTop: "40px", zIndex: 100,
						minWidth: "450px", color: "white"
					}}>
						<div style={{marginRight: "0px"}}>Access Tokens & Decryption Info</div>
						<div style={{border: "1px solid lightgrey", padding: "5px", borderRadius: "10px", display: "flex"}}>
							<div style={{display: "block"}}>
								<CodeBlock
									code={userAPIToken}
									heading='Your Request Token' index={-1}/>
								<CodeBlock
									code={userAPIToken + phoneNoId}
									heading='Your Decryption Key' index={-1}/>
								<label style={{fontSize: "9px", marginLeft: "52px"}}>Request Token + PhoneNoId</label>
								<CodeBlock
									code='function decryptData(encryptedData, key) {
											    const decrypted = CryptoJS.AES.decrypt(encryptedData, key);
											    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
											    try {
											        return JSON.parse(decryptedString);
											    } catch (e) {
											        return decryptedString;
											    }
											}'
									heading='Decryption Code Example:' index={-1}/>
								<div className='refreshDataButton' onClick={() => generateAPITokenAndStore()} style={{
									marginTop: "8px",
									marginLeft: "20px",
									border: "1px solid lightgrey",
									borderRadius: "10px",
									marginBottom: "10px",
									cursor: "pointer", maxWidth: "175px", backgroundColor: "darkmagenta", opacity: "70%"
								}}>
									<div className="noselect" style={{
										marginTop: "0px",
										paddingRight: "5px",
										paddingBottom: "10px",
										paddingLeft: "5px",
										marginBottom: "4px",
										height: "20px"
									}}>
										<AutorenewIcon style={{height: "18px", color: "white", maxWidth: "200px"}}/>
										<label style={{color: "white"}}>Generate New Token</label>
									</div>
								</div>
							</div>
						
						</div>
						<div style={{marginTop: "20px"}}>API Endpoints</div>
						<div style={{
							border: "1px solid lightgrey", paddingLeft: "10px", borderRadius: "10px", paddingTop: "10px", paddingBottom: "40px"
						}}>
							<label style={{color: "lightgrey"}}>Only "en" lang supported and batch size of 20 per broadcast</label>
							{codeblocks.map((rec, index) => {
								return (<div>
									<CodeBlock code={rec.codeText} heading={rec.heading} index={index} lastIndex={index == codeblocks.length - 1}/>
								</div>)
							})}
						</div>
					</div>
				</div>
				{/*</div>*/}
					{/*<textarea placeholder='scratch pad' style={{*/}
					{/*	border: "1px solid lightgrey", marginTop: "20px",*/}
					{/*	borderRadius: "10px",*/}
					{/*	maxWidth: "1000px",*/}
					{/*	minWidth: "500px",*/}
					{/*	minHeight: "80px",*/}
					{/*	padding: "10px",*/}
					{/*	color: "grey",*/}
					{/*	wordWrap: "break-word"*/}
					{/*}}>*/}
					{/*</textarea>*/}
				</div>
			{/*</div>*/}
			{/*RealTime Chat*/}
			{/*<div style={{*/}
			{/*	marginLeft: "50px", marginBottom: "50px", marginTop: "10px",*/}
			{/*	maxWidth: "450px",*/}
			{/*	backgroundImage: `url(${whatsappBkgImage})`, borderRadius: "20px",*/}
			{/*	padding: "20px"*/}
			{/*}}>*/}
			{/*	<div className="chat-container">*/}
			{/*		{msgLog.map((item) => (// <li key={item._id}>{item.fromPhone}</li>*/}
			{/*			<div>*/}
			{/*				<li key={item._id}>{item.msgTimeStamp}</li>*/}
			{/*				<li key={item._id}>{item.userTextMsg}</li>*/}
			{/*			</div>))}*/}
			{/*	</div>*/}
			{/*	*/}
			{/*	<div className="input-container">*/}
			{/*		<input id='userTextInputToMsg' type="text" className="chat-input" placeholder="Type your message"*/}
			{/*		       style={{borderRadius: "10px", minWidth: "350px"}}*/}
			{/*		/>*/}
			{/*		<button className="send-button" onClick={sendWhatsappMsgTxt}>&#9658;</button>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<p style={{display: "none"}}>*/}
			{/*	All Template Types Here:*/}
			{/*	<br/>*/}
			{/*	https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/catalog-templates*/}
			{/*</p>*/}
			<p style={{display: "none"}}>
				{`curl 'https://graph.facebook.com/v19.0/102290129340398/message_templates' \
				-H 'Authorization: Bearer EAAJB...' \
				-H 'Content-Type: application/json' \
				-d '
				{
					"name": "seasonal_promotion",
					"language": "en_US",
					"category": "MARKETING",
					"components": [
				{
					"type": "HEADER",
					"format": "TEXT",
					"text": "Our {{1}} is on!",
					"example": {
					"header_text": [
					"Summer Sale"
					]
				}
				},
				{
					"type": "BODY",
					"text": "Shop now through {{1}} and use code {{2}} to get {{3}} off of all merchandise.",
					"example": {
					"body_text": [
					[
					"the end of August","25OFF","25%"
					]
					]
				}
				},
				{
					"type": "FOOTER",
					"text": "Use the buttons below to manage your marketing subscriptions"
				},
				{
					"type":"BUTTONS",
					"buttons": [
				{
					"type": "QUICK_REPLY",
					"text": "Unsubscribe from Promos"
				},
				{
					"type":"QUICK_REPLY",
					"text": "Unsubscribe from All"
				}
					]
				}
					]
				}'`}
			
			</p>
		
		</div>)
	}
}