import axios from "axios";
import {getBaseUrlNodeServer} from "../../../config";

const upsertToDb = async (collectionName, idQuery, updateObject) => {
	try {
		axios.defaults.baseURL = getBaseUrlNodeServer();
		axios.post('upsertTocollection', {collectionName: collectionName, idQuery: idQuery, updateObject: updateObject}).then(response => {
				console.log(response);
			}
		).catch(err => console.log(err));
	} catch (error) {
		console.error('Error during upserting:', error.message);
	}
};


export {upsertToDb};
