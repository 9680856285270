// SubMenusComponent.jsx
import React, {useState} from 'react';
import './SubMenusComponent.css';

const menus = [
	{
		title: 'Is Mailchimp cheaper?',
		subMenus: [
			'Not if you do the math.',
			'MailChamp has no premium add-ons or hidden fees (unlike Mailchimp, which has whole web pages about each).',
			'Most importantly, people say they make more money with MailChamp. That’s the bottom line.',
		],
	},
	{
		title: 'Is Mailchimp easier?',
		subMenus: [
			'Quite the opposite, say former Mailchimp users and independent review sites.',
			'We see how MailChamp could appear complicated—there’s just so much you can do. But we always meet you where you are.',
			'Our instant integrations, built-in email templates, and segmentation are easy enough for small, scrappy brands. Advanced features, like predictive analytics and complex multi-channel automations, are there when you’re ready. (Spoiler: You’re ready.)'
		],
	},
	{
		title: 'Is Mailchimp enough?',
		subMenus: [
			'Maybe, if you’re growing enough.',
			'Former Mailchimp users tell us a common story: as their business grew, the gap between what they needed and what Mailchimp could handle got bigger and bigger.',
			'Whether you have big dreams for tomorrow or are already outgrowing your email marketing platform today, MailChamp is built to grow with you and get you to where you want to be.'
		],
	},
	// Add more menus as needed...
];

const SubMenusComponent = () => {
	const [openMenuIndex, setOpenMenuIndex] = useState(null); // To track which menu is open
	
	const toggleMenu = (index) => {
		setOpenMenuIndex(openMenuIndex === index ? null : index); // Toggle the clicked menu
	};
	
	return (
		<div className="menu-container">
			{menus.map((menu, index) => (
				<div key={index}>
					<div className="menu-title" onClick={() => toggleMenu(index)}>
						{menu.title}
						{/*<span style={{marginLeft: '0px', display: 'block', textAlign:'right'}}*/}
						<span style={{marginLeft: '50px'}}
						      className={`caret ${openMenuIndex === index ? 'up' : ''}`}> ▼</span>
					</div>
					<div className={`sub-menu ${openMenuIndex === index ? 'open' : 'closed'}`}>
						{menu.subMenus.map((subMenu, subIndex) => (
							<div key={subIndex} className="sub-menu-item">
								{subMenu}
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};


export default SubMenusComponent;
