// @ts-ignore
import {Image} from "../atoms/Image.tsx"
// @ts-ignore
import bgImage from "../../assets/HeroVector.png"
// @ts-ignore
import dashboardImage from "../../assets/dashboard3.png"

// @ts-ignore
import {Text} from "../atoms/Text.tsx"
// @ts-ignore
import {HeroTexts} from "../particles/DataLists.tsx"
// @ts-ignore
import {Button} from "../atoms/Button.tsx"
// @ts-ignore
import {Fade, Slide} from "react-awesome-reveal"
import * as React from "react"
import {useState} from "react"
// @ts-ignore
import {RegisterForm} from "../molecules/RegisterForm.tsx";
import {ModalPopup} from "../molecules/ModalPopup.tsx";
import {SigninForm} from "../molecules/SigninForm.tsx";

// const backgroundImage = "url(../../assets/background1.png)";

const HeroSection = () => {
    const [activeModal, setActiveModal] = useState<string>('')

    return (
        <>
            {/*<div style={{height: '100vh', width: '100%'}}>*/}
            <div style={{
                height: '50%',
                // background: 'linear-gradient(black, rgba(0,0,0,0) 100%)'
                background: `
                            radial-gradient(circle at center top, black 75%, transparent 100%),
                            radial-gradient(circle at right bottom, purple 40%, transparent 40%),
                            radial-gradient(circle at left bottom, blue 40%, transparent 40%)
                            `
                // background: `
                //             radial-gradient(circle at center top, black 75%, transparent 100%),
                //             radial-gradient(circle at left bottom, Teal 50%, transparent 100%),
                //             radial-gradient(circle at right bottom, Teal 40%, transparent 100%)
                //             `
                // background: `
                //             radial-gradient(circle at center top, black 75%, transparent 100%),
                //             radial-gradient(circle at left bottom, Indigo 50%, transparent 100%),
                //             radial-gradient(circle at right bottom, Indigo 40%, transparent 100%)
                //             `
                // background: `
                //             radial-gradient(circle at center top, black 75%, transparent 100%),
                //             radial-gradient(circle at left bottom, Beige 50%, transparent 100%),
                //             radial-gradient(circle at right bottom, Beige 40%, transparent 100%)
                //             `
            }}>
                {/* Top half - Fading Black */}
                {/*</div>*/}
                {/*<div style={{height: '50%', display: 'flex'}}>*/}
                {/*    <div style={{*/}
                {/*        flex: 1,*/}
                {/*        background: 'linear-gradient(rgba(0,0,255,0) 20%, blue)'*/}
                {/*    }}>*/}
                {/*        /!* Bottom left - Fading Blue *!/*/}
                {/*    </div>*/}
                {/*    <div style={{*/}
                {/*        flex: 1,*/}
                {/*        background: 'linear-gradient(rgba(128,0,128,0) 20%, purple)'*/}
                {/*    }}>*/}
                {/*        /!* Bottom right - Fading Purple *!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<Image style={{zIndex: -1, float: "left", display: "flex", position: "relative"}} image={backgroundImage}/>*/}
                <div className="w-full lg:h-screen md:h-[550px] h-[830px] relative overflow-x-hidden flex justify-end"
                    // style={{backgroundImage: `url(${backgroundImage})`}}
                >
                    {/*<Image className="h-[60%] w-[80%] lg:h-[90vh] md:h-[50vh] lg:w-1/2 md:w-[55%]" image={bgImage}*/}
                    {/*       alt="Hero Background Vector"/>*/}
                    {/*<Image className="h-[60%] w-[80%] lg:h-[90vh] md:h-[50vh] lg:w-1/2 md:w-[55%]" image={backgroundImage}*/}
                    {/*       alt="Hero Background Vector"/>*/}
                    <main
                        className="w-full lg:h-full h-auto grid md:grid-cols-2 absolute top-0 left-0 lg:px-24 md:px-8 px-5 pt-24 md:pt-32 lg:pt-0">
                        <div className="flex flex-col justify-center md:gap-6 gap-3 md:order-1 order-2"
                             style={{order: '1'}}>
                            <Text as="p"
                                  className="text-white uppercase tracking-widest lg:text-base text-sm font-normal">
                                <Fade>{HeroTexts.firstText}</Fade>
                            </Text>
                            <Text as="h1"
                                  className="text-white lg:text-7xl md:text-5xl text-3xl font-medium bg-gradient-to-r from-orange-400 via-red-500 to-pink-400 text-transparent bg-clip-text font-sans m-0">
                                <Fade>{HeroTexts.secondText}</Fade>
                            </Text>
                            <Text as="p" className="text-white md:text-base text-sm text-justify font-light">
                                <Fade>{HeroTexts.thirdText}</Fade>
                            </Text>
                            <div
                                className="w-full flex md:justify-start justify-between items-center lg:gap-12 md:gap-6 gap-0"
                                style={{}}>
                                <Button type="button"
                                        className="outline-none border-none lg:px-9 px-5 py-3 bg-color2 text-white font-light rounded-lg"
                                        onClick={() => setActiveModal('Signup')}
                                        style={{
                                            paddingTop: '1rem',
                                            paddingBottom: '1rem',
                                            fontSize: '1rem',
                                            fontWeight: "bold",
                                            letterSpacing: '2px'
                                        }}>
                                    {HeroTexts.firstButton}
                                </Button>
                                {activeModal != '' && <ModalPopup onClose={() => setActiveModal('')}
							                                      children={activeModal == 'login' ?
                                                                      <SigninForm onClose={() => setActiveModal('')}/> :
                                                                      <RegisterForm
                                                                          onClose={() => setActiveModal('')}/>}
							                                      heading={activeModal == 'login' ? "SignIn" : "Signup"}
							                                      description={activeModal == 'login' ? "Enter verification ID sent from MAilChamp.Ai" : "Enter your email to register."}/>}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center md:order-2 order-1">
                            <Slide direction="right">
                                <Image image={dashboardImage} alt="Hero Image"
                                       className=" lg:h-[85%]  md:w-full w-[90%] h-[50vh]"/>
                            </Slide>

                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default HeroSection