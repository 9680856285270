// @ts-ignore
import {Envelope} from '@phosphor-icons/react'
import {Input} from '../atoms/Input'
import {Button} from '../atoms/Button'
import {SignInModalTexts} from '../particles/DataLists'
import * as React from 'react'
import {useEffect, useState} from 'react'
// @ts-ignore
import {toast} from "react-toastify";
// @ts-ignore
import axios from "axios";
// @ts-ignore
import {getAxiosBaseUrl} from "../../config.js"
// @ts-ignore
import {validEmailAddress} from './EmailVerificationUtils'
// @ts-ignore
export const SigninForm = ({onClose}) => {
    const [serverMessage, setserverMessage] = useState("");
    const [requestingVerificationId, setrequestingVerificationId] = useState(false);
    const [signedin, setsignedin] = useState(false);
    const [verificationid, setverificationid] = useState('');
    const [verifiedemail, setverifiedemail] = useState('');

    function onCloseInternal() {
        document.removeEventListener('keydown', handleEsc);
        onClose();
    }

    function validEmailAddress(email: String) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleEsc = (event: { keyCode: number; }) => {
        if (event.keyCode === 27) {
            onCloseInternal();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleEsc);
    }, []); // Only re-run effect if isOpen or onClose changes

    useEffect(() => {
        // var signedin = sessionStorage.getItem("signedin");
        // var verificationid = sessionStorage.getItem("verificationid");
        // var verifiedemail = sessionStorage.getItem("verifiedemail");
        //
        // if (signedin == null || signedin == false) {
        //     setsignedin(false);
        // } else {
        //     setsignedin(true);
        //     setverificationid(verificationid);
        //     setverifiedemail(verifiedemail)
        //     document.getElementById('sendFromField').value = verifiedemail
        //     document.getElementById('verificationId').value = verificationid
        //     document.getElementById('sendFromField').style.color = 'green'
        // }
    }, []);

    function signOut() {
        // //reset form
        // setsignedin(false);
        // setrequestingVerificationId(false);
        // document.getElementById('userform').reset();
        // document.getElementById('sendFromField').value = '...'
        //
        // sessionStorage.removeItem("signedin");
        // sessionStorage.removeItem("verificationid");
        // sessionStorage.removeItem("verifiedemail");
        //
        // toast.success('Good Bye', {
        //     position: "top-right",
        //     autoClose: 2000
        // });
        // window.location.reload()
    }

    function onSubmit(event: { preventDefault: () => void }) {
        event.preventDefault();
        if (signedin) { //User wants to sign out
            signOut()
            return
        }
        setrequestingVerificationId(true)
        // @ts-ignore
        const varId = document.getElementById('verificationId').value.trim()
        if (varId.length > 20) {
            setrequestingVerificationId(true)
            axios.defaults.baseURL = getAxiosBaseUrl()
            axios.post('/idtoemail', {'verification_id': varId})
                .then(response => {
                        setrequestingVerificationId(false);
                        if (validEmailAddress(response.data.msg)) {
                            toast.success('Logged In', {
                                position: "top-right",
                                autoClose: 2000
                            });
                            document.getElementById('sendFromField').value = response.data.msg
                            document.getElementById('sendFromField').style.color = 'green'
                            setsignedin(true);
                            sessionStorage.setItem("signedin", true);
                            sessionStorage.setItem("verificationid", varId);
                            sessionStorage.setItem("verifiedemail", response.data.msg);
                            window.location.reload()
                        } else {
                            toast.error('Failed Logged In', {
                                position: "top-right",
                                autoClose: 2000
                            });
                            document.getElementById('sendFromField').value = response.data.msg;
                            document.getElementById('sendFromField').style.color = 'red';
                            sessionStorage.removeItem("signedin");
                            sessionStorage.removeItem("verificationid");
                            sessionStorage.removeItem("verifiedemail");
                            // window.location.reload()
                        }
                    }
                ).catch(err => console.log(err));
        } else {
            setrequestingVerificationId(false);
            document.getElementById('sendFromField').value = 'Invalid Verification Id'
        }
    }

    function getUserLoginStateText() {
        if (requestingVerificationId) {
            return "Verifying ... "
        } else if (signedin) {
            return "Logout"
        } else {
            return "Login"
        }
    }

    return (
        <div>
            <form className='flex flex-col gap-4' onSubmit={onSubmit}>
                <div className='flex md:flex-row flex-col gap-2'
                     style={{justifyContent: "center"}}>
                    <Input id="verificationId" containerClass='relative'
                           inputClass='border rounded-lg outline-none  h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1'
                           style={{width: "300px"}}
                           type="text" placeholder={SignInModalTexts.emailPlaceholderText} required>
                        <div className='absolute top-4 left-3 text-color3/80'>
                            <Envelope size={18} color="currentColor" weight="fill"/>
                        </div>
                    </Input>
                </div>
                {/*<label style={{*/}
                {/*    width: 200,*/}
                {/*    // marginLeft: '40px',*/}
                {/*    // marginTop: '20px',*/}
                {/*    fontSize: 18,*/}
                {/*    justifyContent:'center',*/}
                {/*    fontWeight: "bold",*/}
                {/*    color: "black"*/}
                {/*}}>Registered*/}
                {/*    Email: </label>*/}
                <textarea type='email' name='sendFromField' id='sendFromField' disabled={true}
                       placeholder=' send from email ... '
                       style={{
                           // lineHeight: 5,
                           textAlign: "center",
                           marginLeft: '0px',
                           maxWidth: 550,
                           height: '100px',
                           wordWrap: "break-word",
                           whiteSpace: "pre-wrap",
                           fontSize: 14
                       }}/>
                <div className='flex md:flex-row flex-col justify-center gap-2'>
                    <Button type='submit'
                            className='border outline-none bg-color1 py-2 px-6 text-white font-light text-base rounded-lg'>
                        {SignInModalTexts.buttonText}
                    </Button>
                </div>
            </form>

        </div>
    )
}
