import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {getBaseUrlNodeServer} from "../../config";

const Article = ({articleId = null}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [templateHtml, settemplateHtml] = useState("");
	
	useEffect(() => {
		async function getBlogArticles() {
			try {
				// console.log(window.location.host);
				// let params = (new URL(document.location)).searchParams;
				const fullURL = document.location.href;
				const parsedURL = new URL(fullURL);
				const pathSegments = parsedURL.pathname.split('/');
				const docId = pathSegments[pathSegments.length - 1] || pathSegments[pathSegments.length - 2];
				const response = await fetch(getBaseUrlNodeServer() + 'blog/article/' + docId, {
					// const response = await fetch(baseurl + 'blog/article/', {
					method: 'post', headers: {'Content-Type': 'application/json'}
				})
				const result = await response.json();
				const html = result[0].html
				settemplateHtml(html);
				// console.log(html);
				setIsLoading(false);
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		
		getBlogArticles();
	}, []);
	
	if (isLoading) {
		return (
			<Box sx={{
				display: 'flex', minHeight: '1000px',
				justifyContent: 'center', marginTop: '50px'
			}}>
				<CircularProgress/>
				{/*<LinearProgress/>*/}
			</Box>
		)
	} else {
		return (<div dangerouslySetInnerHTML={{__html: templateHtml}}
		             style={{
			             maxWidth: '1200px',
			             display: 'flex',
			             justifyContent: 'center',
			             marginBottom: '50px',
			             marginTop: '20px'
			             // justifyContent: 'center',
			             // textAlign: 'center'
			             // overflowY: 'hidden',
			             // maxWidth: '20vw',
			             // borderRadius: '10px'
		             }}>
		
		</div>)
	}
}
export default Article;