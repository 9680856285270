import {Button} from "@mui/material";
import React, {useState} from "react";
import {SigninForm} from "../molecules/SigninForm";

export default function Account() {
	var signedin = sessionStorage.getItem("signedin");
	const [formData, setFormData] = useState({
		fname: "",
		lname: "",
		phone: "",
	});
	
	const handleChange = (e) => {
		const {name, value} = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const divStyle = {
		display: "flex",
	};
	
	const leftDivStyle = {
		flex: 1,
		// backgroundColor: "#f2f2f2",
		padding: "20px",
	};
	
	const rightDivStyle = {
		borderLeft: "1px solid #ccc",
		paddingLeft: "20px",
		marginLeft: "-150px",
		flex: 1,
		// backgroundColor: "#e0e0e0",
	};
	
	const subDivStyle = {
		margin: "10px 0",
		padding: "10px",
		border: "1px solid #ccc",
		backgroundColor: "#fff",
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		// Add your form submission logic here
		console.log("Form Data Submitted:", formData);
	};
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (
			<div style={{marginTop: "20px", marginLeft: "20px", minHeight: "900px"}}>
				<div style={divStyle}>
					<div style={leftDivStyle}>
						User Info:
						<hr style={{width: "600px", marginTop: "5px"}}/>
						<div style={{marginTop: "10px"}}>
							<div style={{width: "100px", float: "left", color: 'grey'}}><label>Email:</label></div>
							<input value={sessionStorage.getItem("verifiedemail")}
							       style={{
								       height: "40px", minWidth: "300px", border: "1px solid grey", textAlign: "center", color: "grey"
							       }}
							       disabled={true}
							></input>
						</div>
						<div style={{marginTop: "10px"}}>
							<div style={{width: "100px", float: "left", color: 'grey'}}><label>Verification Id:</label></div>
							<input value={sessionStorage.getItem("verificationid")}
							       style={{height: "40px", minWidth: "300px", border: "1px solid grey", textAlign: "center", color: "grey"}}
							       disabled={true}></input>
						</div>
						<div>
							<div style={{width: "100px", float: "", marginBottom: "10px", color: 'grey'}}><label>Status:</label></div>
						</div>
						
						<div style={{
							border: "1px solid grey", borderRadius: "20px", padding: "20px", maxWidth: "600px", textAlign: "center", marginTop: "20px"
						}}>
							<br/>
							<div style={{marginTop: "10px"}}>
								<div style={{width: "100px", float: "left"}}><label>Email Credits:</label></div>
								<input value="1,000"
								       style={{
									       height: "40px",
									       minWidth: "300px",
									       border: "1px solid grey",
									       textAlign: "left",
									       paddingLeft: "10px",
									       color: "grey"
								       }}
								       disabled={true}></input>
							</div>
							<Button style={{border: "1px solid", marginTop: "20px"}} disabled={true}>
								Add Credits
							</Button>
						</div>
						<div style={{
							border: "1px solid grey",
							borderRadius: "20px",
							padding: "20px",
							maxWidth: "600px",
							textAlign: "center",
							marginTop: "20px"
						}}>
							<form onSubmit={handleSubmit}>
								<div style={{width: "80px", float: "left", color: 'grey'}}><label>First Name:</label></div>
								<input id='fname' name='fname' type='text' defaultValue={formData.fname} style={{
									height: "40px", minWidth: "300px", border: "1px solid grey", paddingLeft: "5px"
								}} onChange={handleChange} required disabled={true}/>
								<div style={{marginTop: "10px"}}>
									<div style={{width: "80px", float: "left", color: 'grey'}}><label>Last Name:</label></div>
									<input id='lname' name='lname' type='text' defaultValue={formData.lname} style={{
										height: "40px", minWidth: "300px", border: "1px solid grey", paddingLeft: "5px"
									}} onChange={handleChange} required disabled={true}/>
								</div>
								<div style={{marginTop: "10px"}}>
									<div style={{width: "80px", float: "left", color: 'grey'}}><label>Phone No:</label></div>
									<input id='phone' name='phone' type='text' defaultValue={formData.phone} style={{
										height: "40px", minWidth: "300px", border: "1px solid grey", paddingLeft: "5px"
									}} onChange={handleChange} disabled={true}/>
								</div>
								<br/>
								<Button style={{border: "1px solid"}} type="submit" disabled={true}>
									Update Information
								</Button>
							</form>
						</div>
					</div>
					<div style={rightDivStyle}>
						<div style={{marginTop: "30px"}}>
							<p style={{color: 'grey'}}>Downloads</p>
							<hr style={{width: "600px"}}/>
							<br/>
							<Button style={{border: "1px solid", height: "25px"}} disabled={true}>
								Download Bill Pdf
							</Button><br/><br/>
							<Button style={{border: "1px solid", height: "25px"}} disabled={true}>
								Download Stats
							</Button>
							<br/><br/>
							<Button style={{border: "1px solid", height: "25px"}} disabled={true}>
								Download Contacts
							</Button>
							<br/><br/>
							<Button style={{border: "1px solid", height: "25px"}} disabled={true}>
								Download Templates
							</Button>
						</div>
					</div>
				</div>
				
				<div style={{marginTop: "20px"}}>
					<br/><br/><br/><br/><br/>
					<p style={{color: 'grey'}}>Danger Zone</p>
					<hr/>
					<Button style={{border: "1px solid", height: "25px"}} disabled={true}>
						Delete Account
					</Button>
				</div>
			</div>)
	}
}