import React, {useState} from 'react';
import axios from 'axios';
import Button from "@mui/material/Button";
import {getBaseUrlNodeServer} from "../../config";

function PdfUpload({getAllPdfData}) {
	const [file, setFile] = useState(null);
	
	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!file) {
			alert('Please select a file first!');
			return;
		}
		
		const formData = new FormData();
		formData.append('pdf', file);
		
		try {
			axios.defaults.baseURL = getBaseUrlNodeServer();
			await axios.post('/uploadResumePdf', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			getAllPdfData();
			alert('File uploaded successfully');
		} catch (error) {
			console.error('Error uploading file:', error);
			alert('Error uploading file');
		}
	};
	
	return (
		<div style={{
			display: "block", verticalAlign: "middle", alignItems: "center",
			border: "1px solid lightgrey",
			borderRadius: "10px",
			padding: "10px",
			maxWidth: "450px"
		}}>
			<form onSubmit={handleSubmit}>
				<input type="file" onChange={handleFileChange} accept="application/pdf"/>
				<Button variant='outlined' style={{marginTop: "0px", height: "30px"}} type='submit'>Upload PDF< /Button>
			</form>
		</div>
	);
}

export default PdfUpload;
