import React from 'react';

const ProgressBar = ({totalJobs, summaryAvailable}) => {
	const percentage = (summaryAvailable / (totalJobs + 1)) * 100;
	
	const progressBarStyle = {
		width: '100%', backgroundColor: '#e0e0e0', borderRadius: '8px', overflow: 'hidden',
	};
	
	const fillerStyle = {
		height: '100%',
		width: `${percentage}%`,
		backgroundColor: '#4caf50',
		transition: 'width 0.5s ease-in-out',
		borderRadius: 'inherit',
		textAlign: 'right',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: 5,
	};
	
	return (<div style={{width: '50%'}}>
		<div style={{textAlign: 'left'}}>
			Percentage Parsed: {percentage.toFixed(2)} %<br/>
			{/*Available Jobs: {totalJobs} <br/>*/}
			{/*Job Summary Available for: {summaryAvailable}*/}
		</div>
		<div style={progressBarStyle}>
			<div style={fillerStyle}>
				<span style={{color: 'white', fontSize: '1em'}}>{`${percentage.toFixed(2)}%`}</span>
			</div>
		</div>
	</div>);
};

export default ProgressBar;
