import React, {Component, useRef} from 'react'

export default class SubmitDemoButton extends Component {
    state = {
        confirming: true, sendingEmail: false, serverMessage: ""
    }
    componentDidMount = () => {
        this.setState({confirming: false})
        this.setState({serverMessage: ""})
    }
    onSubmit = event => {
        event.preventDefault()
        this.setState({serverMessage: ""})
        this.setState({sendingEmail: true})
        fetch("https://script.google.com/macros/s/AKfycbxgEZ1zMcVCQ9q_XGM8o1c1byO5yipYXdAnTZR9Ala8D209Tf5os-ODPP7wlG1vXINd/exec", {
            method: 'POST',
            body: new FormData(this.form)
        }).then(res => {
            this.setState({sendingEmail: false})
            this.setState({serverMessage: "We will get back to you Soon !! Thank You !!"})
            // setLoading(false)
        }).catch(err => console.log(err))
    }

    render = () => {
        const {sendingEmail} = this.state;
        return (<div style={{marginTop: 0, marginLeft: 0}}>
            <div>
                <form ref={form => this.form = form} onSubmit={this.onSubmit} style={{
                    display: "flex", flexDirection: "column", alignItems: "center", width: "500px",
                }}>
                    <div className='col'>
                        <p style={{fontSize: "large", fontWeight: "bold"}}> Tell us about yourself </p>
                        {/*<div className="mb-3 w-75">*/}
                        <div className="mb-3">
                            <label htmlFor="InputEmail1" className="form-label">Email address *</label>
                            <input type="email" className="form-control" id="exampleInputEmail1"
                                   placeholder="example@gmail.com" name="Email" required/>
                        </div>
                        {/*<div className="mb-3 w-75">*/}
                        <div className="mb-3">
                            <label htmlFor="InputName" className="form-label">Full Name *</label>
                            <input type="" className="form-control" id="InputName"
                                   name="Name"
                                   placeholder="Your Name"
                                   required/>
                        </div>
                        {/*<div className="mb-3 w-75">*/}
                        <div className="mb-3">
                            <label htmlFor="input" className="form-label">Mobile Phone Number *</label>
                            <div className="input-group">
                                <select className="form-select" id="inputGroupSelect01"
                                    // onChange="updateMaxLength(this)"//TODO fix this in the future
                                        name="Country Code"
                                        style={{width: "100px"}}>
                                    <option selected disabled>Country</option>
                                    <option value="880" data-maxlength="11">Bangladesh (+880)</option>
                                    <option value="55" data-maxlength="11">Brazil (+55)</option>
                                    <option value="86" data-maxlength="11">China (+86)</option>
                                    <option value="33" data-maxlength="11">France (+33)</option>
                                    <option value="49" data-maxlength="11">Germany (+49)</option>
                                    <option value="91" data-maxlength="10">India (+91)</option>
                                    <option value="62" data-maxlength="11">Indonesia (+62)</option>
                                    <option value="81" data-maxlength="11">Japan (+81)</option>
                                    <option value="7" data-maxlength="10">Russia (+7)</option>
                                    <option value="234" data-maxlength="13">Nigeria (+234)</option>
                                    <option value="92" data-maxlength="10">Pakistan (+92)</option>
                                    <option value="63" data-maxlength="11">Philippines (+63)</option>
                                    <option value="34" data-maxlength="11">Spain (+34)</option>
                                    <option value="82" data-maxlength="11">South Korea (+82)</option>
                                    <option value="84" data-maxlength="11">Vietnam (+84)</option>
                                    <option value="44" data-maxlength="11">United Kingdom (+44)</option>
                                    <option value="1" data-maxlength="10">United States (+1)</option>
                                </select>
                                <input type="text" className="form-control" id="InputName"
                                       placeholder="Enter your mobile number" Name="Phone Number" required/>
                            </div>
                        </div>
                        {/*{/*<div className="mb-3 w-75">*/}
                        <div className="mb-3">
                            <label htmlFor="InputText" className="form-label">Your Message</label>
                            <textarea className="form-control" id="InputText" name="Message"
                                      placeholder="Your Message"></textarea>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <button type='submit' className='btn' disabled={sendingEmail}
                                    style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                                        marginLeft: 10,
                                        lineHeight: 1.5,
                                        width: "150px",
                                        color:"white"
                                    }}
                                    >
                                {sendingEmail ? "Sending Information ... " : "Submit"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div style={{textAlign: 'center', color: 'green'}}><span>{this.state.serverMessage}</span></div>
        </div>)
    }
}