import React, {Component} from 'react'
import HowWeDoIt from "../HomeSubComponents/HowWeDoIt";
import CovidImpact from "../HomeSubComponents/CovidImpact";
import TopRegistrationProviders from "../HomeSubComponents/TopRegistrationProviders";

export default class BlogHome extends Component {
	state = {
		confirming: true
	}
	componentDidMount = () => {
		this.setState({confirming: false})
	}
	render = () => {
		return (<div>
			<div style={{marginBottom: 20}}>
				<style>{`
                table{
                 border:1px solid black;
                }
                th{
                 border:1px solid black;
                }
                td{
                 border:1px solid black;
                }
              `}</style>
				<div id='article0' style={{marginTop: "100px", marginBottom: "50px"}}>
					<h3 style={{textAlign: "center", marginBottom: "20px", fontSize: "20px"}}>What is our solution and what do you get ?</h3>
					<HowWeDoIt/>
				</div>
				<CovidImpact/>
				<TopRegistrationProviders/>
				<div>
					{/*<img src="images/2020rise_perc_change.png" alt="Quantus Software Private Limited"*/}
					{/*     style="width: 50%;clear: both;margin-top: 0px;">*/}
				</div>
				{/*<div id='article3'>*/}
				{/*	<br/><br/><br/>*/}
				{/*	<h3>RoC Office Level Registered Companies</h3>*/}
				{/*	<h3>Rank 1 to 4</h3>*/}
				{/*</div>*/}
				{/*<div style={{textAlign: 'center'}}>*/}
				{/*	<table>*/}
				{/*		<tr>*/}
				{/*			<td>Row #</td>*/}
				{/*			<td>Roc Name</td>*/}
				{/*			<td>Legalraasta.Com</td>*/}
				{/*			<td>Meraprofit.Com</td>*/}
				{/*			<td>Avikkedia2020</td>*/}
				{/*			<td>Eitcompliance2020</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>1</td>*/}
				{/*			<td>roc-ahmedabad</td>*/}
				{/*			<td>15</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>9</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>2</td>*/}
				{/*			<td>roc-bangalore</td>*/}
				{/*			<td>26</td>*/}
				{/*			<td>13</td>*/}
				{/*			<td>29</td>*/}
				{/*			<td>26</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>3</td>*/}
				{/*			<td>roc-chandigarh</td>*/}
				{/*			<td>9</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>3</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>4</td>*/}
				{/*			<td>roc-chennai</td>*/}
				{/*			<td>11</td>*/}
				{/*			<td>9</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>8</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>5</td>*/}
				{/*			<td>roc-chhattisgarh</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>2</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>6</td>*/}
				{/*			<td>roc-coimbatore</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>3</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>7</td>*/}
				{/*			<td>roc-cuttack</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>10</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>5</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>8</td>*/}
				{/*			<td>roc-delhi</td>*/}
				{/*			<td>84</td>*/}
				{/*			<td>50</td>*/}
				{/*			<td>55</td>*/}
				{/*			<td>39</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>9</td>*/}
				{/*			<td>roc-ernakulam</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>4</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>10</td>*/}
				{/*			<td>roc-goa</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>1</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>11</td>*/}
				{/*			<td>roc-gwalior</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>11</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>12</td>*/}
				{/*			<td>roc-himachalpradesh</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>2</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>13</td>*/}
				{/*			<td>roc-hyderabad</td>*/}
				{/*			<td>21</td>*/}
				{/*			<td>12</td>*/}
				{/*			<td>28</td>*/}
				{/*			<td>13</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>14</td>*/}
				{/*			<td>roc-jaipur</td>*/}
				{/*			<td>11</td>*/}
				{/*			<td>10</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>6</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>15</td>*/}
				{/*			<td>roc-jammu</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>3</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>16</td>*/}
				{/*			<td>roc-jharkhand</td>*/}
				{/*			<td>8</td>*/}
				{/*			<td>8</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>5</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>17</td>*/}
				{/*			<td>roc-kanpur</td>*/}
				{/*			<td>50</td>*/}
				{/*			<td>44</td>*/}
				{/*			<td>39</td>*/}
				{/*			<td>41</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>18</td>*/}
				{/*			<td>roc-kolkata</td>*/}
				{/*			<td>25</td>*/}
				{/*			<td>30</td>*/}
				{/*			<td>11</td>*/}
				{/*			<td>6</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>19</td>*/}
				{/*			<td>roc-mumbai</td>*/}
				{/*			<td>38</td>*/}
				{/*			<td>12</td>*/}
				{/*			<td>11</td>*/}
				{/*			<td>33</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>20</td>*/}
				{/*			<td>roc-patna</td>*/}
				{/*			<td>28</td>*/}
				{/*			<td>9</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>16</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>21</td>*/}
				{/*			<td>roc-pondicherry</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>22</td>*/}
				{/*			<td>roc-pune</td>*/}
				{/*			<td>20</td>*/}
				{/*			<td>33</td>*/}
				{/*			<td>34</td>*/}
				{/*			<td>13</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>23</td>*/}
				{/*			<td>roc-shillong</td>*/}
				{/*			<td>24</td>*/}
				{/*			<td>12</td>*/}
				{/*			<td>9</td>*/}
				{/*			<td>16</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>24</td>*/}
				{/*			<td>roc-uttarakhand</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>4</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>25</td>*/}
				{/*			<td>roc-vijayawada</td>*/}
				{/*			<td>10</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>5</td>*/}
				{/*		</tr>*/}
				{/*	</table>*/}
				{/*	<br/>*/}
				{/*	<h3 style={{textAlign: 'left'}}>Rank 5 to 8</h3>*/}
				{/*	<table>*/}
				{/*		<tr>*/}
				{/*			<td>Row #</td>*/}
				{/*			<td>roc Name</td>*/}
				{/*			<td>Aitf2413</td>*/}
				{/*			<td>Setindiabiz.Com</td>*/}
				{/*			<td>Kanakkupillai.Com</td>*/}
				{/*			<td>Sujataassociates.Com</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>1</td>*/}
				{/*			<td>roc-ahmedabad</td>*/}
				{/*			<td>8</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>2</td>*/}
				{/*			<td>roc-bangalore</td>*/}
				{/*			<td>18</td>*/}
				{/*			<td>44</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>1</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>3</td>*/}
				{/*			<td>roc-chandigarh</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>4</td>*/}
				{/*			<td>roc-chennai</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>200</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>5</td>*/}
				{/*			<td>roc-chhattisgarh</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>6</td>*/}
				{/*			<td>roc-coimbatore</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>14</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>7</td>*/}
				{/*			<td>roc-cuttack</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>8</td>*/}
				{/*			<td>roc-delhi</td>*/}
				{/*			<td>36</td>*/}
				{/*			<td>44</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>2</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>9</td>*/}
				{/*			<td>roc-ernakulam</td>*/}
				{/*			<td>9</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>10</td>*/}
				{/*			<td>roc-goa</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>11</td>*/}
				{/*			<td>roc-gwalior</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>12</td>*/}
				{/*			<td>roc-himachalpradesh</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>13</td>*/}
				{/*			<td>roc-hyderabad</td>*/}
				{/*			<td>17</td>*/}
				{/*			<td>17</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>14</td>*/}
				{/*			<td>roc-jaipur</td>*/}
				{/*			<td>8</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>15</td>*/}
				{/*			<td>roc-jammu</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>16</td>*/}
				{/*			<td>roc-jharkhand</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>3</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>17</td>*/}
				{/*			<td>roc-kanpur</td>*/}
				{/*			<td>29</td>*/}
				{/*			<td>30</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>18</td>*/}
				{/*			<td>roc-kolkata</td>*/}
				{/*			<td>14</td>*/}
				{/*			<td>8</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>193</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>19</td>*/}
				{/*			<td>roc-mumbai</td>*/}
				{/*			<td>30</td>*/}
				{/*			<td>20</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>20</td>*/}
				{/*			<td>roc-patna</td>*/}
				{/*			<td>17</td>*/}
				{/*			<td>15</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>6</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>21</td>*/}
				{/*			<td>roc-pondicherry</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>22</td>*/}
				{/*			<td>roc-pune</td>*/}
				{/*			<td>18</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>23</td>*/}
				{/*			<td>roc-shillong</td>*/}
				{/*			<td>9</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>2</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>24</td>*/}
				{/*			<td>roc-uttarakhand</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>25</td>*/}
				{/*			<td>roc-vijayawada</td>*/}
				{/*			<td>10</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*	</table>*/}
				{/*	<br/>*/}
				{/*	<h3 style={{textAlign: 'left'}}>Rank 9 to 13</h3>*/}
				{/*	<table>*/}
				{/*		<tr>*/}
				{/*			<td>Row #</td>*/}
				{/*			<td>roc Name</td>*/}
				{/*			<td>Dhantaxcare</td>*/}
				{/*			<td>Legalsalah</td>*/}
				{/*			<td>Legalpillers</td>*/}
				{/*			<td>Companyji.Com</td>*/}
				{/*			<td>Caakashgarg16</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>1</td>*/}
				{/*			<td>roc-ahmedabad</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>2</td>*/}
				{/*			<td>roc-bangalore</td>*/}
				{/*			<td>14</td>*/}
				{/*			<td>24</td>*/}
				{/*			<td>16</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>3</td>*/}
				{/*			<td>roc-chandigarh</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>4</td>*/}
				{/*			<td>roc-chennai</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>5</td>*/}
				{/*			<td>roc-chhattisgarh</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>6</td>*/}
				{/*			<td>roc-coimbatore</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>7</td>*/}
				{/*			<td>roc-cuttack</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>8</td>*/}
				{/*			<td>roc-delhi</td>*/}
				{/*			<td>35</td>*/}
				{/*			<td>13</td>*/}
				{/*			<td>16</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>9</td>*/}
				{/*			<td>roc-ernakulam</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>10</td>*/}
				{/*			<td>roc-goa</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>11</td>*/}
				{/*			<td>roc-gwalior</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>12</td>*/}
				{/*			<td>roc-himachalpradesh</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>13</td>*/}
				{/*			<td>roc-hyderabad</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>13</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>14</td>*/}
				{/*			<td>roc-jaipur</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>15</td>*/}
				{/*			<td>roc-jammu</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>16</td>*/}
				{/*			<td>roc-jharkhand</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>17</td>*/}
				{/*			<td>roc-kanpur</td>*/}
				{/*			<td>14</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>23</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>111</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>18</td>*/}
				{/*			<td>roc-kolkata</td>*/}
				{/*			<td>17</td>*/}
				{/*			<td>73</td>*/}
				{/*			<td>12</td>*/}
				{/*			<td>88</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>19</td>*/}
				{/*			<td>roc-mumbai</td>*/}
				{/*			<td>12</td>*/}
				{/*			<td>8</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>20</td>*/}
				{/*			<td>roc-patna</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>7</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>1</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>21</td>*/}
				{/*			<td>roc-pondicherry</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>22</td>*/}
				{/*			<td>roc-pune</td>*/}
				{/*			<td>22</td>*/}
				{/*			<td>6</td>*/}
				{/*			<td>12</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>23</td>*/}
				{/*			<td>roc-shillong</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>3</td>*/}
				{/*			<td>5</td>*/}
				{/*			<td>2</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>24</td>*/}
				{/*			<td>roc-uttarakhand</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*		<tr>*/}
				{/*			<td>25</td>*/}
				{/*			<td>roc-vijayawada</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>1</td>*/}
				{/*			<td>4</td>*/}
				{/*			<td>0</td>*/}
				{/*			<td>0</td>*/}
				{/*		</tr>*/}
				{/*	</table>*/}
				{/*	</div>*/}
				</div>
		</div>)
	}
}