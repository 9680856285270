import HeroSection from "../organs/HeroSection"
import Services from "../organs/Services"
import Templates from "../organs/template"
import ContactUs from "../organs/Contactus"
import * as React from "react"
import 'atropos/css'
import Atropos from 'atropos/react';
// @ts-ignore
// import Registration from "../../Components/Demo/EmailOnboarding/Registration.js"
// @ts-ignore
// import Login from "../../Components/Demo/EmailOnboarding/Login.js"
// import Atropos from 'atropos/react';
// import 'atropos/atropos.css';
import {Swiper, SwiperSlide} from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import image1 from '../../assets/gallery1.jpeg';
import image2 from '../../assets/gallery2.jpeg';
import image3 from '../../assets/gallery3.jpeg';
import './home.css'
import Parallax from './Parallax.js'

const Home = () => {
    const images = [
        image1, image2, image3
        // Add more image paths
    ];
    return (
        <>
            {/*<Parallax/>*/}
            {/*<Swiper*/}
            {/*    spaceBetween={50}*/}
            {/*    slidesPerView={1}*/}
            {/*    // Add other Swiper configurations as needed*/}
            {/*>*/}
            {/*    {images.map((image, index) => (*/}
            {/*        <SwiperSlide key={index}>*/}
            {/*            <Atropos*/}
            {/*                className="atropos-my-element"*/}
            {/*                rotateXMax={30}*/}
            {/*                rotateYMax={30}*/}
            {/*                // Add other Atropos configurations as needed*/}
            {/*            >*/}
            {/*                <img src={image} alt={`Slide ${index}`}/>*/}
            {/*            </Atropos>*/}
            {/*        </SwiperSlide>*/}
            {/*    ))}*/}
            {/*</Swiper>*/}
            {/*<Atropos>*/}
            {/*    /!**/}
            {/*      Element with negative offset will move in reverse direction,*/}
            {/*      making it appear behind the scene*/}
            {/*      *!/*/}
            {/*    <img src={image1} data-atropos-offset="-5"/>*/}
            {/*    /!**/}
            {/*        Element with no offset will not move*/}
            {/*        *!/*/}
            {/*    <img src={image2} data-atropos-offset="0"/>*/}
            {/*    /!**/}
            {/*        Element with positive offset will move in same direction,*/}
            {/*        making it appear in front of the scene*/}
            {/*       *!/*/}
            {/*    <img src={image3} data-atropos-offset="5"/>*/}
            {/*</Atropos>*/}
            <HeroSection/>
            <div style={{
                background: `
                          radial-gradient(circle at center top, black 25%, transparent 100%),
                          radial-gradient(circle at left top, blue 40%, transparent 50%),
                          radial-gradient(circle at right top, purple 40%, transparent 50%)
                        `
            }}>
                <Services/></div>
            <Templates/>
            {/*<Onboarding/>*/}
            {/*<BookDemo/>*/}
            {/*<TopBlogs/>*/}
            {/*<Testimonials/>*/}
            {/*<Partners />*/}
            <ContactUs/>
        </>
    )
}

export default Home