import React, {useState} from 'react';

const ListboxFromList = ({list, setselectedItemParent, parentSelectedItem}) => {
	const [selectedItem, setSelectedItem] = useState(parentSelectedItem);
	
	// if (parentSelectedItem != undefined && parentSelectedItem.length > 2) {
	// 	setSelectedItem(parentSelectedItem);
	// }
	
	const handleChange = (item) => {
		setSelectedItem(item);
		setselectedItemParent(item);
	};
	
	return (
		<div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', width: '30%'}}>
			<ul style={{listStyleType: 'none', padding: 0, margin: 0, width: '200px', borderRadius: '10px', textAlign: 'left'}}>
				<li style={{cursor: 'pointer', padding: '5px', backgroundColor: selectedItem === '' ? '#e0e0e0' : 'transparent'}}
				    onClick={() => handleChange('')}>
					Select a Template below
				</li>
				{list.map(item => (
					<li key={item} style={{cursor: 'pointer', padding: '5px', backgroundColor: selectedItem === item ? '#e0e0e0' : 'transparent'}}
					    onClick={() => handleChange(item)}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};

export default ListboxFromList;
