import React, {useEffect, useState} from 'react';
import dummyData from './dummyData'

import {toast} from 'react-toastify';
import axios from "axios";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {getBaseUrlNodeServer} from "../../config";
import './editableTable.css'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';


const EditableTable = ({initialData}) => {
	const verifiedemail = sessionStorage.getItem("verifiedemail");
	const [data, setData] = useState([]);
	const [loading, setIsLoading] = useState(true);
	
	useEffect(() => {
		async function getSetUserData() {
			// ['_id', 'Email', 'verifiedemail', 'First Name', 'Last Name', 'Position']
			if (data.length == 0) {
			var header = null;
			if (initialData != undefined && initialData != null && initialData.length > 0) {
				header = Object.keys(initialData[0]).filter(key => !key.includes('_id') && !key.includes('verifiedemail')).sort();
				const userContactData = initialData.map(rec => {
					const filteredData = [];
					header.forEach(key => {
						filteredData.push(rec[key]);
					});
					return filteredData;
				})
				const userContactDataUpdated = [header, ...userContactData];
				setData(userContactDataUpdated)
			} else {
				header = dummyData[0]
				const userContactData = dummyData[1]
				const userContactDataUpdated = [header, userContactData];
				setData(userContactDataUpdated)
			}
				setIsLoading(false);
			}
		}
		
		getSetUserData();
	}, data);
	const resetTable = () => {
		toast.success('Resetting Table')
		window.location.reload();
		// setData(dummyData);
	};
	const saveData = (row) => {
		const header = data[0]
		try {
			axios.defaults.baseURL = getBaseUrlNodeServer()
			axios.post('saveusercontact', {'data': row, 'header': header, 'verifiedemail': verifiedemail}).then(response => {
					console.log(response.data);
				}
			).catch(err => console.log(err));
			window.location.reload();
		} catch (error) {
			console.error('Error during fetch:', error.message);
		}
	}
	const addRow = () => {
		toast.success('Added Row')
		const newRow = data[0].map(() => ""); // Assuming all rows have the same number of columns
		setData([...data, newRow]);
		// console.log('data', data);
	};
	
	const addColumn = () => {
		toast.success('Added Column');
		const newData = data.map(row => [...row, '']); // Assume default empty string for new cells
		setData(newData);
	};
	const removeRow = (row, index) => {
		if (index >= 0 && index < data.length) {
			toast.info('Removed Row', {
				position: "top-right", autoClose: 2000
			});
			
			const header = data[0]
			try {
				axios.defaults.baseURL = getBaseUrlNodeServer()
				axios.post('deleteusercontact', {
					'data': row, 'header': header, 'verifiedemail': verifiedemail
				}).then(response => {
					console.log(response.data);
				}).catch(err => console.log(err));
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
			
			const newData = [...data];
			newData.splice(index, 1);
			setData(newData);
			
			window.location.reload();
		} else {
			toast.error('Invalid Row Index');
		}
	};
	
	function downloadTableData() {
		const filename = 'mailchamp_contacts.csv'
		// Assuming the first element of 'data' contains headers
		const headers = data[0];
		const csvRows = data.slice(1).map(row => row.join(','));
		
		// Add header row at the start and convert array to CSV string
		csvRows.unshift(headers.join(','));
		const csvString = csvRows.join('\r\n');
		
		// Create a blob with the CSV string
		const blob = new Blob([csvString], {type: 'text/csv'});
		
		// Create a link to download the blob
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		a.download = filename;
		a.style.display = 'none';
		
		// Append the link, trigger the download, then remove the link
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	
	const removeColumn = (columnIndex) => {
		toast.info(`Removed Column ${columnIndex + 1}`);
		const newData = data.map(row => {
			const newRow = [...row]; // Copy the row
			newRow.splice(columnIndex, 1); // Remove the item at the columnIndex
			return newRow;
		});
		setData(newData);
	};
	
	
	// Edit a cell
	const editCell = (rowIndex, columnIndex, value) => {
		const newData = [...data];
		newData[rowIndex][columnIndex] = value;
		setData(newData);
	};
	
	function getCellWidth(columnIndex) {
		// if (cell.includes('@')) {
		if (columnIndex == 0) {
			return '330px'
		} else {
			return '150px'
		}
	}
	
	function getSaveDeleteButtonTags(row, rowIndex) {
		if (rowIndex == 0) {
			return <>
				<td style={{background: "#ece8e894"}}></td>
				<td style={{background: "#ece8e894"}}></td>
			</>
		}
		return (<>
			<td style={{paddingLeft: "10px"}}>
				<div class="tooltip" onClick={() => saveData(row)} style={{
					justifyContent: 'center', cursor: 'pointer', borderRadius: '5px', display: "flex",
					border: "1px dotted grey", marginRight: "10px", backgroundColor: "#cbf5cbdb", padding: "2px"
				}}>
					<span className="tooltiptext">Save Contact</span>
					Save
					{/*<SaveOutlinedIcon/>*/}
				</div>
			</td>
			<td style={{paddingLeft: "10px"}}>
				<div class="tooltip" onClick={() => removeRow(row, rowIndex)} style={{
					justifyContent: 'center', cursor: 'pointer', borderRadius: '5px', display: "flex", border: "1px dotted grey",
					padding: "2px", backgroundColor: "rgb(238 207 175 / 86%)",
				}}>
					<div className="tooltiptext2">Delete Contact</div>
					<DeleteOutlined/>
				</div>
			</td>
		</>)
	}
	
	return (
		<div style={{justifyContent: 'left', display: 'grid'}}>
			<div style={{marginTop: '0px', marginLeft: '20px'}}>
				{/*<hr style={{*/}
				{/*	color: 'gray', borderStyle: 'inset',*/}
				{/*	borderWidth: '1px', margin: '0.5em auto',*/}
				{/*	marginBottom: '20px'*/}
				{/*}}/>*/}
				{/*<div onClick={addRow} style={{justifyContent: 'center', cursor: 'pointer'}}>*/}
				{/*	<img src={save} style={{height: '30px'}}/>*/}
				{/*	<span>Add Row</span>*/}
				{/*</div>*/}
				
				<Stack spacing={2} direction="row">
					<Button variant="outlined" style={{marginLeft: '20px'}} onClick={addRow}>Add Row</Button>
					{/*<Button variant="outlined" style={{marginLeft: '20px'}} onClick={addColumn}>Add Column</Button>*/}
					<Button variant="outlined" style={{marginLeft: '20px'}} onClick={downloadTableData}>Download Contacts .csv</Button>
					<Button variant="outlined" style={{marginLeft: '20px'}} onClick={resetTable}>Reload</Button>
				</Stack>
			</div>
			<div style={{
				marginBottom: '100px', border: '1px solid grey',
				// borderCollapse: 'collapse',
				borderCollapse: 'separate !important',
				borderRadius: '20px', overflow: 'hidden', marginTop: '10px',
				width: 'fit-content', maxHeight: '1000px', overflowY: 'scroll'
			}}>
				<table style={{margin: '20px'}}>
					{/*Do not delete this code*/}
					{/*<thead>*/}
					{/*/!*Don't delete following code*!/*/}
					{/*<tr>*/}
					{/*	<th></th>*/}
					{/*	{data[0] && data[0].map((_, columnIndex) => (*/}
					{/*		<th key={columnIndex}>*/}
					{/*			<button onClick={() => removeColumn(columnIndex)}*/}
					{/*			        style={{marginBottom: '10px'}}*/}
					{/*			>*/}
					{/*				Delete Column*/}
					{/*			</button>*/}
					{/*		</th>*/}
					{/*	))}*/}
					{/*</tr>*/}
					{/*</thead>*/}
					<tbody>
					{data.map((row, rowIndex) => (
						<tr key={rowIndex} style={{
							background: '#ece8e894', border: '1px solid black',
							borderRadius: '30px'
						}}>
							<td style={{minWidth: '50px'}}>{rowIndex == 0 ? 'Row' : rowIndex}</td>
							{
								row.map((cell, columnIndex) => (
									<td key={columnIndex}>
										<input
											style={{
												lineHeight: '30px',
												marginLeft: '5px',
												marginBottom: '5px',
												minWidth: getCellWidth(columnIndex),
												paddingLeft: '5px'
											}}
											disabled={rowIndex === 0}
											type="text"
											value={cell}
											onChange={(e) => editCell(rowIndex, columnIndex, e.target.value)}
										/>
									</td>
								))}
							{getSaveDeleteButtonTags(row, rowIndex)}
							{/*<td>*/}
							{/*	<input type="checkbox" id="vehicle1"*/}
							{/*	       name="vehicle1" value="Bike" style={{*/}
							{/*		margin: '10px'*/}
							{/*	}}/>*/}
							{/*</td>*/}
						</tr>
					))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default EditableTable;
