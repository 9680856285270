import EmailVerify from "../../EmailVerify";
import {useEffect, useState} from "react";


export default function Registration({parentDocId, emails}) {
	const [data, setData] = useState(sessionStorage.getItem('signedin') || false);
	
	useEffect(() => {
		function handleStorageChange(event) {
			if (event.key === 'signedin') {
				setData(event.newValue); // Update the state with the new value
				window.location.reload();
			}
		}
		
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);
	
	return (
		<div className="BookingSteps" style={{
			boxShadow: "10px 10px 12px rgba(0,0,0,0.1)",
			padding: "10px",
			border: "1px solid lightgray",
			marginTop: "20px",
			borderRadius: "30px",
			backgroundColor: 'rgba(92, 159, 145, 0.8)',
			display: data ? 'none' : 'block'
		}}>
			<p style={{textAlign: 'left', marginTop: 0}}><label
				style={{color: 'white', fontSize: 25, marginLeft: "30px", fontWeight:"bolder"}}>Registration:</label></p>
			<EmailVerify/>
			<label style={{color: 'black', paddingLeft: "30px"}}>Go to your gmail / email / outlook
				etc. client and check for an email from Amazon.com AWS <b>click
					on verification link</b>.</label>
		</div>)
}