import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ImageComponent from "./ImageComponent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InstagramCard from './InstagramCard'
import {CircularProgress} from '@mui/material';
import {ToastContainer, toast} from 'react-toastify';
import {CopyToClipboard as ReactCopyToClipboard} from 'react-copy-to-clipboard';

const MediaUpload = () => {
	const [file, setFile] = useState('');
	const [imgFile, setimgFile] = useState('');
	const [images, setImages] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isVideoUploading, setisVideoUploading] = useState(false);
	const [value, setValue] = useState('Text to copy');
	const [copied, setCopied] = useState(false);
	
	useEffect(() => {
		async function getAllImages() {
			try {
				axios.defaults.baseURL = 'http://localhost:8080';
				axios.get('/images').then(response => {
						setImages(response.data);
					}
				).catch(err => console.log(err));
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		
		getAllImages();
	}, []);
	
	const onSubmit = async (e) => {
		e.preventDefault();
		setisVideoUploading(true);
		const formData = new FormData();
		formData.append('video', file);
		try {
			// console.log('file selected, ', file);
			//TODO Changge this when deploying on the server if i need to use it on the server
			axios.defaults.baseURL = 'http://localhost:8080';
			const res = await axios.post('/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			console.log('File Uploaded', res);
		} catch (err) {
			//TODO catch errors by errocode and notify
			console.log(err);
		}
		setisVideoUploading(false);
	};
	
	const onDeleteImageSubmit = async (e) => {
		toast.error("IMPLEMENT ME")
		//TODO
	}
	
	const onImageSubmit = async (e) => {
		e.preventDefault();
		setIsUploading(true);
		const formData = new FormData();
		// console.log(imgFile);
		formData.append('imageFile', imgFile);
		try {
			//TODO Changge this when deploying on the server if i need to use it on the server
			axios.defaults.baseURL = 'http://localhost:8080';
			const res = await axios.post('/uploadimg', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			console.log('File Uploaded', res);
		} catch (err) {
			console.log(err);
		} finally {
		}
		setIsUploading(false);
		setimgFile(''); //TODO this is not working
	};
	
	const onImageChange = (e) => {
		setimgFile(e.target.files[0]);
	};
	const onChange = (e) => {
		setFile(e.target.files[0]);
	};
	return (
		<div style={{minHeight: '1000px', display: 'block', justifyContent: 'center', marginTop: '50px', marginLeft: "40px"}}>
			{/*<input value={value} onChange={(e) => setValue(e.target.value)}/>*/}
			{/*<ReactCopyToClipboard text={value} onCopy={() => {*/}
			{/*	setCopied(true);*/}
			{/*	setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds*/}
			{/*}}>*/}
			{/*	<button>Copy to Clipboard</button>*/}
			{/*</ReactCopyToClipboard>*/}
			{/*{copied && <span style={{color: 'green'}}>Copied!</span>}*/}
			<div>
				<label style={{float: 'left', marginRight: '20px'}}>Upload your video here ... </label>
				<form onSubmit={onSubmit} style={{marginTop: '20px'}}>
					<input type="file" onChange={onChange} disabled={isVideoUploading}/>
					<button type="submit" disabled={isVideoUploading} className='btn' style={{backgroundColor: 'lightgrey'}}>
						{isVideoUploading ? <CircularProgress size={24}/> : "Upload Video"}
					</button>
				</form>
			</div>
			<hr/>
			<div>
				<label style={{float: 'left', marginRight: '20px'}}>Upload your image here ... </label>
				<form onSubmit={onImageSubmit} method="post" encType="multipart/form-data">
					{/*<input type="text" name="name" placeholder="Enter image name"/>*/}
					<input type="file" name="image" onChange={onImageChange} disabled={isUploading}/>
					<button type="submit" disabled={isUploading} className='btn' style={{backgroundColor: 'lightgrey'}}>
						{isUploading ? <CircularProgress size={24}/> : "Upload Image"}
					</button>
				</form>
			</div>
			<hr/>
			<div>
				<label style={{float: 'left', marginRight: '20px'}}>Delete image ... </label>
				{/*<form onSubmit={onDeleteImageSubmit} method="post" encType="multipart/form-data">*/}
				<form onSubmit={onDeleteImageSubmit}>
					{/*<input type="file" name="image" onChange={onImageChange} disabled={isUploading}/>*/}
					<input type="text" name="deletefilename" placeholder='image url' style={{width: '300px', lineHeight: '30px'}}/>
					<button type="submit" className='btn' style={{backgroundColor: 'lightgrey', marginLeft: '30px'}}>
						Delete Image
						{/*{isUploading ? <CircularProgress size={24}/> : "Delete Image"}*/}
					</button>
					{/*onChange={onImageChange} disabled={isUploading}/>*/}
					{/*<button type="submit" disabled={isUploading} className='btn' style={{backgroundColor: 'lightgrey'}}>*/}
					{/*	{isUploading ? <CircularProgress size={24}/> : "Delete Image"}*/}
					{/*</button>*/}
				</form>
			</div>
			<hr/>
			<h5 style={{textAlign: 'center'}}>Image Catalogue</h5>
			<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px'}}>
				{images.map((item, index) => (
					//{/*<img src={item.img.data}/> TODO check why this is not working*/}
					<ImageComponent imageName={item.name}/>
					//{/*//This is heavy, because the data is already fetched in the item, i should populate from there*/}
				))}
				{/*<ImageComponent imageName='588122814'/>*/}
			</div>
		
		</div>
	);
};

export default MediaUpload;