import React, {Component} from 'react'

export default class HowWeDoIt extends Component {
	state = {
		confirming: true
	}
	componentDidMount = () => {
		this.setState({confirming: false})
	}
	render = () => {
		return (
			<div className='row' style={{marginTop: 10, display: "flex", justifyContent: "center"}}>
				<div
					className='col-sm'
					style={{
						fontFamily: 'Roboto, sans-serif',
						fontWeight: 500,
						border: '0.2px solid lightgray',
						padding: '10px 20px',
						boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',
						borderRadius: '20px',
						margin: '0 20px'
					}}
				>
					<p style={{fontFamily: 'Roboto,sans-serif', color: 'gray', fontSize: 20}}>
						Steps</p>
					<li style={{listStyleType: 'none', fontSize: 16}}>Bring your <label style={{color: '#1473e6'}}>contacts</label> or <label
						style={{color: '#1473e6'}}>we
						find</label> the leads for you.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> You provide us
						the target <label>market</label>.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> We help you identify the
						right audience and <label style={{color: '#1473e6'}}>winning formula</label>.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> We help you send <label>bulk</label> <label
						style={{color: '#1473e6'}}>campaign</label> and interpret your success.
					</li>
				</div>
				<div
					className='col-sm'
					style={{
						fontFamily: 'Roboto, sans-serif',
						fontWeight: 500,
						border: '0.2px solid lightgray',
						padding: '10px 20px',
						boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',
						borderRadius: '20px',
						margin: '0 20px',
					}}
				>
					<p style={{
						fontFamily: 'Roboto,sans-serif',
						color: 'gray',
						fontSize: 20,/* marginBottom: 10, *//* marginTop: 10, */
						margin: 0,
						// marginTop: 10,
						// marginBottom: 10
					}}>
						What do you get ?</p>
					<li style={{listStyleType: 'none', fontSize: 16}}> Email / Msg / Call
						from prospective customers.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> Increase <label
						style={{color: '#1473e6'}}> Brand Awareness </label>.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> Higher <label style={{color: '#1473e6'}}>web
						/ social traffic</label>.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> Very High <label
						style={{color: '#1473e6'}}>ROI</label>.
					</li>
				
				</div>
				<div
					className='col-sm'
					style={{
						fontFamily: 'Roboto, sans-serif',
						fontWeight: 500,
						border: '0.2px solid lightgray',
						padding: '10px 20px',
						boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',
						borderRadius: '20px',
						margin: '0 20px',
					}}
				>
					<p style={{
						fontFamily: 'Roboto,sans-serif',
						color: 'gray',
						fontSize: 20,/* marginBottom: 10, */
						margin: 0,
						// marginTop: 10,
						// marginBottom: 10
					}}>
						Deep Dive</p>
					<li style={{listStyleType: 'none', fontSize: 16}}> Your ideal market segment details.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> Technical deep dive for higher engagement.
					</li>
					<li style={{listStyleType: 'none', fontSize: 16}}> Competition Analysis.
					</li>
				</div>
			</div>)
	}
}