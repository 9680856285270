
export const NavButtons = [
    {
        name: "Home", url: "/"
    },
    // {
    //     name: "Flows", url: "/flows"
    // },
    {
        name: "Blogs", url: "/blog"
    },
    // {
    //     name: "Pricing", url: "/pricing"
    // },
    {
        name: "About", url: "/about"
    },
    {
        name: "Login", url: "/"
    },
    {
        name: "Signup", url: "/"
    }

];


export const HeroTexts = {
    firstText: "EMAIL CAMPAIGNS MADE EASY",
    secondText: "Reach Your Audience And Grow",
    thirdText: "Welcome to MailChamp – where your success knows no bounds! Dive into the world of stunning email templates, powerful campaigns, and dynamic email marketing, all designed to skyrocket your business. Let's unleash the full potential of your email strategy with MailChamp's innovative and result-driven solutions. Get ready for growth beyond limits!",
    firstButton: "START FOR FREE"
}


export const ServiceTexts = {
    firstText: "CATEGORY",
    secondText: "Why choose us?",
    cards: [
        {
            firstText: "Designer Templates",
            secondText: "Choose from our catalogue ready to go designer templates."
        },
        {
            firstText: "AB Tested Reachout",
            secondText: "We help you deliver the right message with most impact to your leads. We have advanced email warming, customer segmentation, lead finders and automations for landing inboxes and delivery high Open Rate, Click Rate."
        },
        {
            firstText: "Easy to use",
            secondText: "Our tools are very intuitive and easy to use. Get started in 5 minutes."
        },
        {
            firstText: "Personal touch",
            secondText: "We are experts who will help you personally along your journey."
        }
    ]
}

export const TopBlogsTexts = {
    secondText: "Top Blogs",
    cards: [
        {
            country: "Rome, Italy",
            price: "5.42k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            country: "London, UK",
            price: "4.2k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            country: "Paris, France",
            price: "15k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            country: "New York, USA",
            price: "4.5k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            country: "Warsaw, Poland",
            price: "3.9k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            country: "Dubai, UAE",
            price: "9.2k",
            duration: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
    ]
}

export const OnboardingTexts = {
    firstText: "MailChamp Onboarding Flow",
    listOfSteps: [
        {
            text: "Signup with your Email"
        },
        {
            text: "You choose template from our pool"
        },
        {
            text: "You bring your contacts and add in our system"
        },
        {
            text: "We help you launch a mini campaign"
        },
        {
            text: "Review dashboard and stats"
        },
        {
            text: "We work with you on finding the right content"
        },
        {
            text: "We launch bigger campaign for you"
        }
    ]
}

export const BookDemoTexts = {
    firstText: "Book a demo with our Specialists",
    listOfSteps: [
        {
            text: "Get a tour of the platform catered to your needs."
        },
        {
            text: "See real examples of successfull automation and Support"
        },
        {
            text: "Discover effective tactics to boost email marketing"
        },
        {
            text: "Understand which pricing plan is best for you"
        }
    ]
}

export const TemplatesTexts = {
    firstText: "Choose a Template",
    description: "Browse through collections of our catalogue or request us for your needs. Click to edit the template you want to send to your audience. Drag and drop the required modifications and preview the desktop version, mobiile version and send test email to verify the output. Go to campaigns and run. You can edit, pause, delete, run your campaigns. Look at the stats how your campaign is doing. State of the art dashboard to monitor your clients actions and use feedback to create more appealing and engaging campaigns."

}


export const TestimonialTexts = {
    firstText: "TESTIMONIALS",
    secondText: "What people say about Us.",
    feedBacks: [
        {
            text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
            person: "Mike taylor",
            location: "Lahore, Pakistan"
        },
        {
            text: "Jadoo was great with the entire process from planning to updates during the trip. We had 11 people and everything was perfectly executed. We appreciate all of her hard work. It was truly the trip of a lifetime. Thank you!",
            person: "Gina",
            location: "Owerri, Nigeria"
        },
        {
            text: "Booking through you was very easy and made our lives so much easier. I have nothing bad to say! Thank you for giving us tips and guidance before we left on what to bring and such, that was very helpful!",
            person: "Emma",
            location: "Vancouver, Canada"
        },
        {
            text: "Thank you for your recommendation and putting the trip together. The resort was absolutely beautiful. The infinity pools, the palm trees in the main pool, the infinity pool in out preferred area overlooking the gulf and the golf course were exceptional....",
            person: "Loveth",
            location: "Lagos, Nigeria"
        },
    ],
}


export const ContactUsTexts = {
    firstText: "Book a demo with our Specialists",
    emailPlaceholderText: "Your email",
    phonePlaceholderText: "Your phone number",
    namePlaceholderText: "Your Full Name",
    messagePlaceholderText: "Your Message",
    buttonText: "Submit"
}


export const RegisterModalTexts = {
    emailPlaceholderText: "Your email",
    buttonText: "Register"
}

export const SignInModalTexts = {
    emailPlaceholderText: "Enter verification ID",
    buttonText: "Login"
}

export const FooterTexts = {
    underLogoText: "Our (CIN) company identification number is U72900HR2020PTC091245.",
    quickLinks: {
        caption: "Quick Links",
        links: [
            {
                name: "About",
                url: "/"
            },
            {
                name: "Features",
                url: "/"
            },
            {
                name: "Blogs",
                url: "/"
            },
            {
                name: "Flows",
                url: "/"
            },
            {
                name: "Pricing",
                url: "/"
            }
        ]
    },
    contacts: {
        caption: "Contacts",
        links: [
            {
                name: "FAQs",
                url: "/"
            },
            {
                name: "Help",
                url: "/"
            },
            {
                name: "Policies",
                url: "/"
            },
            {
                name: "Terms & Conditions",
                url: "/"
            }
        ]
    },
    more: {
        caption: "More",
        links: [
            {
                name: "Career",
                url: "/"
            },
            {
                name: "Airlines",
                url: "/"
            },
            {
                name: "Airline Fees",
                url: "/"
            },
            {
                name: "Low Fare Tips",
                url: "/"
            }
        ]
    }
}