import React, {useEffect, useState} from 'react';
import './BlogPage.css'; // Make sure to create a corresponding CSS file for styling
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {getBaseUrlNodeServer} from "../../config";

// A simple functional component to display individual articles as clickable blocksh
const ArticleBlock = ({article}) => {
	const {id, title, summary} = article; // Destructure the needed properties from the article
	
	return (
		<div className="article-block" onClick={() => window.location.href = `/blog/article/${id}`}
		     style={{minHeight: '100px', maxHeight: '200px'}}>
			<h4>{title}</h4>
			<p>{summary}</p>
		</div>
	);
};

// The main BlogPage component that displays all articles
const BlogPage = () => {
	const [articles, setarticles] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		async function getBlogArticles() {
			try {
				//TODO Optimize this to load in parallel
				const response = await fetch(getBaseUrlNodeServer() + 'getblogarticles', {
					method: 'post', headers: {'Content-Type': 'application/json'}
				})
				const mgmlTxt1 = await response.json();
				const transformedArticles = mgmlTxt1.map(article => {
						const data = {
							id: article.id,
							title: article.subject,
							summary: article.subject,
							// html: article.html,
							// creDate: article.creDate,
							username: article.username
						};
						return data;
					}
				);
				setarticles(transformedArticles);
				setIsLoading(false);
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		
		getBlogArticles();
	}, []);
	
	if (isLoading) {
		return (
			<Box sx={{
				display: 'flex', minHeight: '1000px',
				justifyContent: 'center', marginTop: '50px'
			}}>
				<CircularProgress/>
				{/*<LinearProgress/>*/}
			</Box>
		)
	} else {
		return (
			<div style={{minHeight: '1000px', marginTop:'200px'}}>
				<div className="blog-container">
					{articles.map(article => (
						<ArticleBlock key={article.id} article={article}/>
					))}
				</div>
			</div>
		)
	}
	
};

export default BlogPage;
