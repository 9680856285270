import React, {useEffect, useState} from "react";
import ImageList from "@mui/material/ImageList";
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import CategorySelectionButtons from "../Campaign/CategorySelectionButtons";

export default function AllTemplates() {
	const [isLoading, setIsLoading] = useState(true);
	const [templateIdToHtmlDict, settemplateIdToHtmlDict] = useState({})
	const [templateIdToHtmlDictFiltered, settemplateIdToHtmlDictFiltered] = useState({})
	const [showCursorText, setShowCursorText] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({x: 0, y: 0});
	const navigate = useNavigate()
	const [selectedCategories, setSelectedCategories] = useState(['all']);//don't leave the first time to be empty, otherwise the first clck won't work
	useEffect(() => {
		function reseetFilteredList() {
			if (selectedCategories != undefined && selectedCategories.length > 0) {
				if (selectedCategories[0] == 'all') {
					settemplateIdToHtmlDictFiltered(templateIdToHtmlDict)
				} else {
					const filteredTemplates = Object.entries(templateIdToHtmlDict)
					.filter(([_, templateData]) =>
						templateData.templateCategories &&
						templateData.templateCategories.includes(selectedCategories[0])
					)
					.reduce((acc, [key, value]) => {
						acc[key] = value;
						return acc;
					}, {});
					settemplateIdToHtmlDictFiltered(filteredTemplates)
				}
			} else {
				settemplateIdToHtmlDictFiltered(templateIdToHtmlDict)
			}
		}
		
		reseetFilteredList();
	}, selectedCategories);
	
	useEffect(() => {
		window.scrollTo(0, 0);
		
		async function getCampaigns() {
			axios.defaults.baseURL = getBaseUrlNodeServer()
			const response = await axios.post('getusertemplate')
			const usertemplates = response.data
			const sortedTemplates = usertemplates.map(template => ({
				...template,
				rank: template.rank || 10  // Assign a default rank of 10 if none exists
			}))
			.sort((a, b) => a.rank - b.rank);  // Sort based on the rank
			
			const promises = sortedTemplates.map(async (template) => {
				const responseJson = await fetch(getBaseUrlNodeServer() + 'mjml2html', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({'mgml': template.content})
				})
				var templateCategories = []
				if (template.selectedCategories != undefined) {
					// console.log(template.selectedCategories);
					templateCategories = template.selectedCategories
				}
				const htmlJson = await responseJson.json();
				return {
					[template._id]: {
						'subject': template.subject,
						'html': htmlJson.html,
						'rank': template.rank,
						'templateCategories': templateCategories
					}
				};
			})
			const results = await Promise.all(promises);
			const finalResult = Object.assign({}, ...results);
			settemplateIdToHtmlDict(finalResult);
			settemplateIdToHtmlDictFiltered(finalResult);
			setIsLoading(false);
		}
		
		getCampaigns();
	}, []);
	const [isHovered, setIsHovered] = useState(false);
	
	const style = {
		minHeight: "1000px",
		overflowX: isHovered ? 'scroll' : 'hidden', // Change based on hover state
		// width: '90vw',
		borderRadius: '20px',
		// backgroundColor: 'lightgrey',
		dropshadow: "5px 10px 8px rgba(0,0,0,0.1)",
		WebkitOverflowScrolling: 'touch',  // iOS momentum scrolling
		// scrollbarWidth: 'none',  // Firefox
		// '&::-webkit-scrollbar': {
		// 	display: 'none',  // Hide the scrollbar in WebKit browsers
		// },
	};
	
	const containerStyle = {
		display: 'flex',
		// gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', // Responsive grid columns
		// gap: '20px', // Adjust the gap between templates
		marginTop: '40px',
		// flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
	};
	
	async function userWantsToEdit(templateId) {
		var signedin = sessionStorage.getItem("signedin");
		const verifiedemail = sessionStorage.getItem("verifiedemail");
		if (signedin) {
			if (verifiedemail == 'admin@mailchamp.ai') { //Let this account modify the all templates //Special priviledges
				navigate('/editor/' + templateId);
			} else {
				const responseJson = await fetch(getBaseUrlNodeServer() + 'createtemplateduplicate', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({'templateId': templateId, 'verifiedemail': verifiedemail})
				})
				const result = await responseJson.json()
				const newTemplateId = result.insertedId.toString();
				// sessionStorage.setItem('selectedMenuItem', 'editor');
				navigate('/editor/' + newTemplateId);
			}
		} else {
			toast.error("Login to Edit the Template")
		}
	}
	
	function getStyleForButton() {
		return {
			// backgroundColor:"white",
			border: "1px solid lightblue", color: 'black'
		}
	}
	
	return (
		<div>
			<div style={{
				border: "0px solid lightgrey",
				// marginTop: "-20px",
				// borderRadius: "20px",
				padding: "10px",
				paddingBottom: "4px",
				marginRight: "10px",
				marginLeft: "-20px",
				backgroundColor: "rgb(247,248,251",
				position: 'fixed',
				top: 0,
				left: 250,
				width: '90%',
				zIndex: 100
			}}>
				<div style={{textAlign: "left", fontSize: "17px"}}>Top {Object.entries(templateIdToHtmlDict).length} email templates.</div>
				<Button key="all"
				        variant="outlined"
				        style={{backgroundColor: selectedCategories.includes('all') ? 'lightblue' : 'lightgrey', margin: '5px', height: "25px"}}
				        onClick={() => setSelectedCategories(['all'])}>
					All
				</Button>
				<CategorySelectionButtons selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories}
				                          singleSelectionOnly={true}/>
				{/*<div style={{marginTop: "10px"}}>*/}
				{/*	<input defaultValue="Search ...."></input>*/}
				{/*	<Button variant="outlined" style={{marginLeft: '20px', height: "30px"}}>Search</Button>*/}
				{/*</div>*/}
			</div>
			<div style={{marginTop: "100px"}}>
				<ImageList sx={{width: '100%', height: "100%", marginTop: "0px"}} cols={4}>
					{Object.entries(templateIdToHtmlDictFiltered).map(([templateId, record], index) => (<div style={{
						marginLeft: "5px", // overflow: 'hidden',
						transform: 'scale(0.6)', transformOrigin: '0 0', maxWidth: "300px", maxHeight: "500px",
					}}>
						<div onClick={() => userWantsToEdit(templateId)}>
							<div
								style={{minWidth: '600px', marginBottom: "5px", maxHeight: "800px", overflow: "hidden"}}
								dangerouslySetInnerHTML={{__html: record.html}}></div>
						</div>
					</div>))}
				</ImageList>
			</div>
		</div>
	
	)
	
}