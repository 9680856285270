import React, {Component, useState} from 'react'
import ReactDOM from 'react-dom';
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from 'draft-js';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
import {convertFromRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {convertToRaw} from 'draft-js';
import parse from 'html-react-parser';
import {API_URL, getAxiosBaseUrl} from "../config";
import IframeForTemplate from "./Carousel/IframeForTemplate";
import * as ReactDOMServer from "react-dom/server";
import axios from "axios";
import Grid from "@mui/material/Grid";
import CardContent from '@mui/material/CardContent';
import {Card as MuiCard, Button, Typography, Stack} from '@mui/material';

export default class EditorWrapper extends Component {
	state = {
		requestingVerificationId: false,
		serverMessage: "Invalid Verification Id",
		sendingEmailCampaign: false,
		clickedEmails: {}
	}
	currentContent = 'Write something in the text';
	
	state = {editorState: EditorState.createEmpty()}
	
	validEmailAddress(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	
	componentDidMount() {
	}
	
	onChange = (editorState) => {
		this.setState({editorState});
		this.currentContent = "<div>" + draftToHtml(convertToRaw(editorState.getCurrentContent())) + "</div>"
		ReactDOM.render(parse(this.currentContent), document.getElementById('labelShow'));
	}
	
	emailContentSubmit = () => {
		const sendFrom = document.getElementById('sendFromField').value.trim()
		const sendToList = document.getElementById('sendToList').innerText.trim()
		const parentDocIdFromHtml = document.getElementById('templateidField').innerText
		document.getElementById('EmailSent').value = "Sending Emails ...";
		if (parentDocIdFromHtml.length < 5 || sendToList.length < 1 || sendFrom.length < 1 || !this.validEmailAddress(sendFrom)) {
			this.setState({sendingEmailCampaign: false})
			document.getElementById('emailDataUsrMsg').innerText = 'Please fill in all the fields VERIFICATION ID, Send TO, Select a Valid template from above...'
			document.getElementById("emailDataUsrMsg").focus()
			//document.getElementById("emailDataUsrMsg").scrollIntoView()
			// window.scrollTo(0, 0);
			document.getElementById('EmailSent').innerText = "Sending Emails Failed";
		} else {
			this.setState({sendingEmailCampaign: true})
			axios.defaults.baseURL = getAxiosBaseUrl()
			axios.post('/emailcampaign', {
				templateIdSelected: parentDocIdFromHtml,
				// htmlEmailMsg: htmlEmailMsg,
				// subject: subject,
				sendToList: sendToList,
				sendFrom: sendFrom
			})
			.then(response => {
					this.setState({sendingEmailCampaign: false})
					document.getElementById('sendToList').value = "";
					document.getElementById('EmailSent').value = "Sending Emails Success";
				}
			).catch(err => console.log(err));
		}
	};
	
	verificationIdEntered = () => {
		const varId = document.getElementById('verificationId').value.trim()
		if (varId.length > 20) {
			this.setState({serverMessage: "Requesting from server ..."})
			this.setState({requestingVerificationId: true})
			axios.defaults.baseURL = getAxiosBaseUrl()
			axios.post('/idtoemail', {'verification_id': varId})
			.then(response => {
					this.setState({requestingVerificationId: false})
					this.setState({serverMessage: response.data.msg})
					if (this.validEmailAddress(response.data.msg)) {
						document.getElementById('sendFromField').value = response.data.msg
						document.getElementById('sendFromField').style.color = 'green'
					} else {
						document.getElementById('sendFromField').value = response.data.msg
						document.getElementById('sendFromField').style.color = 'red'
					}
				}
			).catch(err => console.log(err));
		} else {
			this.setState({requestingVerificationId: false})
			document.getElementById('sendFromField').value = this.serverMessage
		}
	}
	
	getBackgroundColor(email) {
		if (this.state.clickedEmails === undefined) {
			return '#545864'
		}
		if (this.state.clickedEmails[email] === undefined) {
			return '#545864'
		}
		if (this.state.clickedEmails[email]) {
			return 'secondary.main'
		} else {
			return '#545864'
		}
	}
	
	getSendButtonText() {
		if (this.state.clickedEmails === undefined) {
			return 'Sent to 0'
		} else {
			const count = Object.entries(this.state.clickedEmails)
			.filter(([email, isClicked]) => isClicked).length
			return 'Send to ' + count
		}
	}
	
	fillInEmailList() {
		if (this.state.clickedEmails === undefined) {
			return ''
		} else {
			return Object.entries(this.state.clickedEmails)
			.filter(([email, isClicked]) => isClicked)
			.map(([email]) => email).join(',')
		}
	}
	
	
	handleEmailClick = (email) => {
		if (this.state.clickedEmails === undefined) {
			const clickedEmails = {}
			clickedEmails[email] = true
			this.setState({clickedEmails: clickedEmails})
		} else {
			if (this.state.clickedEmails[email] === undefined) {
				this.state.clickedEmails[email] = true
				this.setState({clickedEmails: this.state.clickedEmails})
			} else {
				this.state.clickedEmails[email] = !this.state.clickedEmails[email]
				this.setState({clickedEmails: this.state.clickedEmails})
			}
		}
	}
	
	
	render = () => {
		const {parentDocId} = this.props
		let {emails} = this.props
		const {sendingEmailCampaign} = this.state
		
		if (emails.length == 0) {
			emails = ['admin@mailchamp.ai', 'admin@edenboard.com'];
		}
		return (
			<div>
				<Grid item xs={6} style={{marginLeft: '50px', marginTop: '-15px', marginBottom: '12px'}}>
					{emails.map((email) => (
						<Button
							key={email}
							variant="contained"
							onClick={() => this.handleEmailClick(email)}
							sx={{
								backgroundColor: this.getBackgroundColor(email),
								// ':hover': {
								// 	backgroundColor: this.state.clickedEmails[email] === undefined? 'primary.dark' : 'secondary.dark',
								// },
								margin: '5px',
								height: '30px',
								textTransform: 'none'
							}}
						>
							<p style={{fontSize: '13px'}}>{email}</p>
						</Button>
					))}
				</Grid>
				<div className='container' style={{marginTop: -10}}>
					<div className='row' style={{paddingBottom: 10}}>
						<div className='col-sm'>
							<div><p id='emailDataUsrMsg' style={{color: "red"}}></p></div>
							<form onSubmit={this.emailContentSubmit} ref={form => this.form = form}>
								<div className='row'
								     style={{textAlign: 'center', display: 'block', paddingLeft: "30px"}}>
									<div className='row' style={{marginBottom: 5}}>
										<label style={{width: 100}}>VERIFICATION ID: </label>
										<input type='text' name='verificationId' id='verificationId'
										       placeholder='Enter your verification id sent to your email'
										       style={{lineHeight: 2, textAlign: "left", marginLeft: 5, width: 350}}
										       onChange={this.verificationIdEntered}
										/>
									</div>
									<div style={{textAlign: 'left'}}>
										<label style={{color: 'gray', paddingLeft: "30px"}}>Use the <b>VERIFICATION
											ID</b> we sent in your email.</label> <br/>
										<label style={{color: 'gray', paddingLeft: "30px"}}>If you dont see these 2 emails in
											your inbox please check your
											Spam or Junk folder.</label>
									</div>
									<div className='row' style={{marginBottom: 5}}>
										<label style={{width: 100}}>From: </label>
										<input type='email' name='sendFromField' id='sendFromField' disabled='true'
										       placeholder='from email id'
										       style={{
											       lineHeight: 2,
											       textAlign: "left",
											       marginLeft: 5,
											       width: 350,
											       fontSize: 14
										       }}/>
									</div>
									<div className='row' style={{marginBottom: 5}}>
										<label style={{width: 100}}>Send To: </label>
										<label type='email' name='sendToList' id='sendToList'
											// required
											   placeholder='emails , separated'
											   style={{
												   lineHeight: 2,
												   textAlign: "left",
												   marginLeft: 5,
												   width: '80%',
												   height: 50,
												   marginTop: 0,
												   backgroundColor: 'rgb(238, 238, 238)',
											   }}>
											{this.fillInEmailList()}
										</label>
									</div>
									<div className='row'>
										<label htmlFor='templateid' style={{width: 100}}>Template Id: </label>
										<label type='text' name='templateidField' id='templateidField'
										       required
										       style={{
											       textAlign: "left",
											       marginLeft: 5,
											       marginBottom: 5,
											       backgroundColor: 'rgb(238, 238, 238)',
											       width: 350,
											       // border: '1px solid rgb(117 118 117)',
											       height: '30px',
											       disabled: 'true'
										       }}>{parentDocId}</label></div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col text-center" style={{marginLeft: "95px"}}>
							<button type='submit' className='btn' disabled={sendingEmailCampaign}
							        style={{
								        backgroundColor: "darkgray", marginLeft: 10,
								        lineHeight: 1.5, width: '200px'
							        }}
							        onClick={() => this.emailContentSubmit()}>
								{sendingEmailCampaign
									? "Sending Email ... "
									: this.getSendButtonText()
								}
							</button>
						</div>
					</div>
				</div>
				<div style={{clear: 'both', textAlign: 'center'}}>
					<text style={{textAlign: 'center', fontWeight: 600}} id="EmailSent"></text>
				</div>
			</div>
		)
	}
}
