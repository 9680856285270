import React, {useState} from "react";

export default function QuickReplyButton({uniqueKey, handleInputOnChange, initializedValue = ''}) {
	console.log('initializedValue', initializedValue);
	const [localValue, setlocalValue] = useState(initializedValue);
	
	function handleInputOnChangeLocal(e) {
		setlocalValue(e.target.value)
		handleInputOnChange(e)
	}
	
	return (<div style={{marginTop: "5px", marginLeft: "-10px"}}>
			<div style={{fontWeight: "normal", padding: "0px", marginLeft: "14px"}}>Quick Reply</div>
			<div style={{
				border: "1px solid lightgrey",
				borderRadius: "10px",
				marginTop: "0px",
				minHeight: "80px",
				backgroundColor: "rgb(248,250,251)",
				marginLeft: "10px",
				marginRight: "10px",
				marginBottom: "10px"
			}}>
				<table style={{margin: "10px"}}>
					<tr style={{fontWeight: "bold"}}>
						<td>Type</td>
						<td>Button Text</td>
					</tr>
					<tr>
						<td style={{minWidth: "100px"}}>
							<p style={{
								border: "1px solid lightgrey", minHeight: "40px", padding: "5px", borderRadius: "10px",
								marginRight: "20px", marginLeft: "0px", textAlign: "center", verticalAlign: "middle"
							}}>
								Custom
							</p>
						</td>
						<td><input id={uniqueKey}
						           value={localValue}
						           onChange={(e) => handleInputOnChangeLocal(e)}
						           style={{
							           minHeight: "40px",
							           borderRadius: "10px",
							           border: "1px solid lightgrey",
							           padding: "5px"
						           }}/>
						</td>
					</tr>
				</table>
			</div>
		</div>
	)
}