var hostPath = window.location.host
hostPath = hostPath.replace(/:.*/, "");
const isLocalHost = ['127.0.0.1', 'localhost'].includes(hostPath)

export function getAxiosBaseUrl() {
	if (isLocalHost) {
		return 'http://127.0.0.1:' + getPythonPort();
	} else {
		return 'https://mailchamp.ai:' + getPythonPort();
	}
}

export function getBaseUrlNodeServer() {
	if (isLocalHost) {
		return 'http://127.0.0.1:8080/'
	} else {
		return 'https://mailchamp.ai:8080/'
	}
}



export function getPythonPort() {
	return '5001'
}

export const API_URL = process.env.NODE_ENV === 'production'
  ? 'http://quantussoftware.in:8080'
  : 'http://localhost:8080'