import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import HtmlFromMjML from "../Editor/HtmlFromMjML";
import {MjmlToJson} from "easy-email-extensions";
import {BasicType, BlockManager} from "easy-email-core";

export default function HorizontalCarousel({handleState}) {
	const [content, setContent] = useState(null); // State for your content
	const [isLoading, setIsLoading] = useState(true);
	const [parentDocId, setparentDocId] = useState('');
	const [initialValues, setInitialValues] = useState({
		subject: 'Welcome to MailChamp.Ai',
		subTitle: '',
		content: BlockManager.getBlockByType(BasicType.PAGE).create({})
	});//This initial values also needs to be shared with the parent
	
	const pages = [
		// Add your page content here. Each item represents a page.
		{content: <HtmlFromMjML idx={0} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={1} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={2} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={3} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={4} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={5} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={6} setTrigger={handleState} style={{marginTop: '0px'}}/>},
		{content: <HtmlFromMjML idx={7} setTrigger={handleState} style={{marginTop: '0px'}}/>},
	];
	const [isHovered, setIsHovered] = useState(false);
	
	const style = {
		overflowX: isHovered ? 'scroll' : 'hidden', // Change based on hover state
		width: '90vw',
		borderRadius: '20px',
		// backgroundColor: 'lightgrey',
		dropshadow: "5px 10px 8px rgba(0,0,0,0.1)",
		WebkitOverflowScrolling: 'touch',  // iOS momentum scrolling
		scrollbarWidth: 'none',  // Firefox
		'&::-webkit-scrollbar': {
			display: 'none',  // Hide the scrollbar in WebKit browsers
		},
	};
	
	
	return (
		<div border='1' style={style} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<Box
				style={{marginLeft: '0px', padding: "0 auto"}}
				sx={{
					display: 'flex',
					flexWrap: 'nowrap', // Allow multiple rows
					overflowX: 'auto', // Enable horizontal scrolling
					width: '90vw', // Take full view width
					height: '75vh', // Double the height for two full rows,
					alignContent: "center",
				}}>
				{pages.map((page, index) => (
					<Box
						key={index}
						sx={{
							minWidth: '20vw', // Half view width for 2 pages per row
							height: '65vh', // Half of container's height, representing one row height
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							background: index % 2 === 0 ? '#f0f0f0' : '#e0e0e0', // Alternate background for visibility
							boxShadow: 3, // Add some shadow for each page
							marginLeft: '30px',
							marginTop: '20px',
							borderRadius: '20px',
						}}
					>
						{page.content}
					</Box>
				))}
			</Box></div>)
	
}