import {Menu, MenuItem, Sidebar} from "react-pro-sidebar";
import {Link, useNavigate} from "react-router-dom";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TheatersIcon from '@mui/icons-material/Theaters';
import React, {useEffect, useState} from "react";
import shopifyLogo from '../../assets/shopifylogo.png'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LayersIcon from '@mui/icons-material/Layers';
import GroupIcon from '@mui/icons-material/Group';
import logo from '../../assets/logo.jpg'

export default function MCSideBar({signedin, verifiedemail, isAdmin}) {
	const navigate = useNavigate()
	const [selectedMenuItem, setselectedMenuItem] = useState('');
	
	const handleMenuItemClick = (itemName) => {
		sessionStorage.setItem('selectedMenuItem', itemName);
		setselectedMenuItem(itemName);
	};
	const ColoredLine = ({color}) => (
		<hr
			style={{
				color: color,
				backgroundColor: color,
				height: 1
			}}
		/>
	);
	
	useEffect(() => {
		if (sessionStorage.getItem('selectedMenuItem') != null) {
			setselectedMenuItem(sessionStorage.getItem('selectedMenuItem'))
		} else {
			sessionStorage.setItem('selectedMenuItem', '')
			setselectedMenuItem('');
		}
	}, []);
	const getActiveCss = {
		background: '#d3d3d38f', color: 'rgb(55,122,95)', borderRadius: "10px",
		fontSize: "13px", marginBottom: "0px", marginTop: "5px",
		paddingBottom: "0px", marginLeft: "-10px",
		height: "40px", fontWeight: "bold"
	};
	const getInActiveCss = {
		fontSize: "13px", marginBottom: "0px", marginTop: "5px",
		paddingBottom: "0px", marginLeft: "-10px",
		height: "40px"
	};
	
	function logOutClicked() {
		sessionStorage.removeItem("signedin");
		sessionStorage.removeItem("verificationid");
		sessionStorage.removeItem("verifiedemail");
		sessionStorage.removeItem('isAdmin');
		sessionStorage.removeItem('selectedMenuItem');
		navigate('/');
		window.location.reload();
	}
	
	if (signedin) {
		return (
			<Sidebar className="app" style={{display: "flex", minWidth: "200px", width: "230px", position: "relative"}}>
				<Menu>
					<Link to={'/'}>
						<div style={{
							textAlign: 'center',
							paddingBottom: "10px",
							paddingTop: "10px", paddingLeft: "20px", backgroundColor: "white",
							display: "flex"
						}}>
							<img src={logo} style={{maxHeight: "50px", borderRadius: "10px", opacity: "100%"}}/>
							<div style={{marginTop: "5px", marginLeft: "10px", fontSize: "12px"}}>MailChamp
								<div style={{
									textWrap: "wrap", fontWeight: "normal", fontStyle: "italic",
									marginTop: "2px", fontSize: "12px"
								}}>{verifiedemail}</div>
							</div>
							<div></div>
						</div>
					</Link>
					<MenuItem icon={<AccountCircleRoundedIcon style={{color:"rgb(93,95,98)"}}/>}
					          onClick={() => handleMenuItemClick('account')}
					          style={selectedMenuItem === 'account' ? getActiveCss : getInActiveCss}
					          component={<Link to="account" className="link"/>}>
						Account</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('contacts')}
						style={selectedMenuItem === 'contacts' ? getActiveCss : getInActiveCss}
						component={<Link to="contacts" className="link"/>}
						icon={<ContactPageIcon style={{color:"rgb(93,95,98)"}}/>}> Contacts </MenuItem>
					
					<ColoredLine color="lightgrey" style={{marginTop: '0px'}}/>
					<MenuItem
						onClick={() => handleMenuItemClick('dashboard')}
						style={selectedMenuItem === 'dashboard' ? getActiveCss : getInActiveCss}
						component={<Link to="dashboard" className="link"/>}
						icon={<GridViewRoundedIcon style={{color:"rgb(93,95,98)"}}/>}
					>
						Dashboard
					</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('')}
						style={selectedMenuItem === '' ? getActiveCss : getInActiveCss}
						component={<Link to="/" className="link"/>}
						className="menu1"
						icon={<RocketLaunchIcon style={{color:"rgb(93,95,98)"}}/>}
					>
						<h2>Campaigns</h2>
					</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('editor')}
						style={selectedMenuItem === 'editor' ? getActiveCss : getInActiveCss}
						component={<Link to="editor" className="link"/>}
						icon={<BorderColorIcon style={{color:"rgb(93,95,98)"}}/>}> Editor </MenuItem>
					<MenuItem icon={<TimelineRoundedIcon style={{color:"rgb(93,95,98)"}}/>}
					          onClick={() => handleMenuItemClick('savedtemplates')}
					          style={selectedMenuItem === 'savedtemplates' ? getActiveCss : getInActiveCss}
					          component={<Link to="savedtemplates" className="link"/>}
					>My Templates</MenuItem>
					<MenuItem icon={<WorkspacePremiumIcon style={{color:"rgb(93,95,98)"}}/>}
					          onClick={() => handleMenuItemClick('alltemplates')}
					          style={selectedMenuItem === 'alltemplates' ? getActiveCss : getInActiveCss}
					          component={<Link to="alltemplates" className="link"/>}
					>Premium Templates</MenuItem>
					{isAdmin && (
						<MenuItem
							icon={<AdminPanelSettingsIcon style={{color:"rgb(93,95,98)"}}/>}
							onClick={() => handleMenuItemClick('adminpanel')}
							style={selectedMenuItem === 'adminpanel' ? getActiveCss : getInActiveCss}
							component={<Link to="adminpanel" className="link"/>}
						>Admin Panel</MenuItem>
					)}
					<ColoredLine color="lightgrey"/>
					{/*<SubMenu label="Templates" icon={<BarChartRoundedIcon/>}>*/}
					{/*</SubMenu>*/}
					{/*<SubMenu label="Wallets" icon={<WalletRoundedIcon/>}>*/}
					{/*	<MenuItem icon={<AccountBalanceRoundedIcon/>}>*/}
					{/*		Current Wallet*/}
					{/*	</MenuItem>*/}
					{/*	<MenuItem icon={<SavingsRoundedIcon/>}>Savings Wallet</MenuItem>*/}
					{/*</SubMenu>*/}
					{/*<MenuItem*/}
					{/*	component={<Link to="transactions" className="link"/>}*/}
					{/*	icon={<MonetizationOnRoundedIcon/>}*/}
					{/*>*/}
					{/*	Transactions*/}
					{/*</MenuItem>*/}
					<MenuItem
						onClick={() => handleMenuItemClick('JobPool')}
						style={selectedMenuItem === 'JobPool' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="jobpool" className="link"/>}
						icon={<LayersIcon style={{color: "rgb(50,102,194)", fontSize: "30px"}}/>}
					>JobPool</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('MyApplications')}
						style={selectedMenuItem === 'MyApplications' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="myapplications" className="link"/>}
						icon={<GroupIcon style={{color: "rgb(50,102,194)", fontSize: "30px"}}/>}
					>My Applications</MenuItem>
					<ColoredLine color="lightgrey"/>
					<MenuItem
						onClick={() => handleMenuItemClick('Shopify')}
						style={selectedMenuItem === 'Shopify' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="shopify" className="link"/>}
						icon={<img style={{height: "25px", marginLeft: "4px"}} src={shopifyLogo}/>}>Shopify</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('Whatsapp')}
						style={selectedMenuItem === 'Whatsapp' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="whatsapp" className="link"/>}
						icon={<WhatsAppIcon style={{color: "#25D366"}}/>}>Broadcast / Chat</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('WhatsappEditor')}
						style={selectedMenuItem === 'WhatsappEditor' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="WhatsappEditor" className="link"/>}
						icon={<WhatsAppIcon style={{color: "#25D366"}}/>}>Editor</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('MyTemplates')}
						style={selectedMenuItem === 'MyTemplates' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="MyTemplates" className="link"/>}
						icon={<WhatsAppIcon style={{color: "#25D366"}}/>}>My Templates</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('WhatsappTemplates')}
						style={selectedMenuItem === 'WhatsappTemplates' ? getActiveCss : getInActiveCss}
						disabled={false}
						component={<Link to="WhatsappTemplates" className="link"/>}
						icon={<WhatsAppIcon style={{color: "#25D366"}}/>}>Sample Templates</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('Editor Old')}
						style={selectedMenuItem === 'Editor Old' ? getActiveCss : getInActiveCss}
						disabled={true}
						component={<Link to="demo" className="link"/>}
						icon={<BorderColorIcon/>}>Editor Old</MenuItem>
					<MenuItem
						onClick={() => handleMenuItemClick('Media Upload')}
						style={selectedMenuItem === 'Media Upload' ? getActiveCss : getInActiveCss}
						disabled={true}
						component={<Link to="mediaupload" className="link"/>}
						icon={<TheatersIcon/>}>Media Upload</MenuItem>
					<ColoredLine color="lightgrey"/>
					{/*<SubMenu label="Settings" icon={<SettingsApplicationsRoundedIcon/>} defaultOpen={true} disabled={true}>*/}
					{/*	<MenuItem icon={<ShieldRoundedIcon/>}> Privacy </MenuItem>*/}
					{/*	<MenuItem icon={<NotificationsRoundedIcon/>}>*/}
					{/*		Notifications*/}
					{/*	</MenuItem>*/}
					{/*</SubMenu>*/}
					<MenuItem icon={<LogoutRoundedIcon/>} onClick={logOutClicked}
					          style={getInActiveCss}
					> Logout </MenuItem>
					{/*<img src={logo} style={{borderRadius: "40px", height: "80px", display: "block", marginLeft: "auto", marginRight: "auto"}}/>*/}
				</Menu>
			</Sidebar>)
	} else {
		return (<></>)
	}
}