import CarouselSection from '../../Carousel/Carousel';
import EmailVerify from "../../EmailVerify";
import EditorWrapper from "../../EditorWrapper";
import Registration from './Registration'

export default function EmailVerificationOnboard({parentDocId, emails}) {
	return (
		<div className='container'>
			<div className='Home-Area'>
				{/*<Registration/>*/}
				<div className="BookingSteps" style={{
					boxShadow: "10px 10px 12px rgba(0,0,0,0.1)",
					padding: "10px",
					border: "1px solid lightgray",
					marginTop: "20px",
					borderRadius: "30px",
					backgroundColor: 'rgb(183 213 206)'
				}}>
					<p style={{textAlign: 'left', marginTop: 10, paddingLeft: "30px"}}><label
						style={{color: 'green', fontSize: 20, clear: 'both'}}>Send Campaign:</label> <label
						style={{color: 'gray'}}>Click on Emails to Add to "Send To" list.</label></p>
					<EditorWrapper parentDocId={parentDocId} emails={emails}/>
				</div>
			</div>
		</div>);
}