import React, {useEffect, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import {upsertToDb} from "../Whatsapp/dbUtils/simpleDbUtils";
import Button from '@mui/material/Button';
import axios from "axios";
import {getAxiosBaseUrl} from "../../config";
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import ProgressBar from "./ProgressBar";

// import stemmer from 'stemmer';
// const natural = require('natural');
// const porterStemmer = natural.PorterStemmer;

export default function JobPool() {
	const [joburls, setjoburls] = useState([]);
	const [alldatalength, setAllDataLength] = useState(0);
	const [fltrdjoburls, setfltrdjoburls] = useState([]);
	// const fltrdjoburls1 = []
	var signedin = sessionStorage.getItem("signedin");
	const [companyChartData, setcompanyChartData] = useState([]);
	const [companyChartDataProcessed, setcompanyChartDataProcessed] = useState({});
	const [jobSummaryCount, setjobSummaryCount] = useState(0);
	const useFlexSearch = (joburls) => {
		// const [index, setIndex] = useState(null);
		// useEffect(() => {
		// 	const newIndex = new FlexSearch.Index({
		// 		// tokenize: 'forward',
		// 		tokenize: "full", matcher: "simple", cache: true
		// 	});
		//
		// 	joburls.forEach((job, id) => {
		// 		newIndex.add(id, job.jobSummary.toLowerCase() + ' ' + job.keywords.join(' ').toLowerCase());
		// 	});
		//
		// 	setIndex(newIndex);
		// }, [joburls]);
		//
		// return index;
	};
	const index = useFlexSearch(joburls);
	
	function getDictionaryForCompanyData(allData) {
		const count = {};
		const countProcessed = {};
		allData.forEach(rec => {
			const name = extractCompanyName(rec['jobUrl']);
			count[name] = (count[name] || 0) + 1;
			if ('date_posted' in rec) {
				countProcessed[name] = (countProcessed[name] || 0) + 1;
			}
		});
		
		const companyChartData = Object.keys(count).map(key => {
				if (key == 'Amazon') {
					return ({name: key, availableCount: 0, processedCount: 0})
				}
				return {
					name: key,
					processedCount: countProcessed[key] || 0,
					availableCount: count[key] - (countProcessed[key] || 0),
				};
			}
		)
		
		
		return companyChartData;
		
		// const count = {};
		// const countProcessed = {}
		// allData.map(rec => {
		// 	const name = extractCompanyName(rec['jobUrl']);
		// 	count[name] = (count[name] || 0) + 1;
		// 	if ('date_posted' in rec) {
		// 		countProcessed[name] = (countProcessed[name] || 0) + 1;
		// 	}
		// })
		// const companyChartData = Object.keys(count).map(key => {
		// 	if (key == 'Amazon') {
		// 		// return ({name: key, count: 0})
		// 		return ({name: key, availableCount: 0, processedCount: 0})
		// 	} else {
		// 		return ({name: key, availableCount: count[key], processedCount: countProcessed[key]})
		// 	}
		// })
		// return companyChartData
	}
	
	function getAndSetJobData() {
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('fetchJobUrlsEs', {
			'verifiedemail': sessionStorage.getItem("verifiedemail")
		}).then(response => {
			// console.log(response.data);
			const allData = response.data
			setAllDataLength(allData.length)
			const filteredData = allData.filter(rec => 'date_posted' in rec)
			
			let companyChartData = getDictionaryForCompanyData(allData)
			setcompanyChartData(companyChartData)
			// setjobSummaryCount(companyChartData.length);
			
			// const filteredData = allData
			setjoburls(filteredData);
			setfltrdjoburls(filteredData);
		})
		// axios.post('getJobUrls', {
		// 	'verifiedemail': sessionStorage.getItem("verifiedemail")
		// }).then(response => {
		// 	const allData = response.data
		// 	const filteredData = allData.filter(rec => 'date_posted' in rec)
		// 	setjoburls(filteredData);
		// 	setfltrdjoburls(filteredData);
		// }).catch(err => console.log(err));
	}
	
	function capitalizeFirst(word) {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
	}
	
	function getTitleText(record) {
		if ('title' in record) {
			return record.title
		}
		return '-'
	}
	
	function extractCompanyName(url) {
		let hostname = ''
		try {
			hostname = new URL(url).hostname;
		} catch {
			return '-'
		}
		if (url.includes('Samsung')) {
			return 'Samsung'
		}
		if (hostname.includes('intel')) {
			return capitalizeFirst('intel')
		}
		if (hostname.includes('zebra')) {
			return capitalizeFirst('zebra')
		}
		if (hostname.includes('t-mobile')) {
			return capitalizeFirst('tmobile')
		}
		if (hostname.includes('comcast')) {
			return capitalizeFirst('comcast')
		}
		if (hostname.includes('cisco')) {
			return capitalizeFirst('cisco')
		}
		if (hostname.includes('verizon')) {
			return capitalizeFirst('verizon')
		}
		if (hostname.includes('fa-evmr-saasfaprod1.fa.ocs')) {
			return capitalizeFirst('Nokia')
		}
		if (hostname.includes('americantower')) {
			return capitalizeFirst('americantower')
		}
		if (hostname.includes('assurant')) {
			return capitalizeFirst('assurant')
		}
		if (hostname.includes('qualcomm')) {
			return capitalizeFirst('qualcomm')
		}
		if (hostname.includes('netflix')) {
			return capitalizeFirst('netflix')
		}
		
		if (hostname.includes('ebay')) {
			return capitalizeFirst('eBay')
		}
		
		if (hostname.includes('apple')) {
			return capitalizeFirst('apple')
		}
		if (hostname.includes('walmart')) {
			return capitalizeFirst('walmart');
		}
		try {
			const parts = hostname.split('.');
			const companyName = parts[0] === 'www' ? parts[1] : parts[0];
			return capitalizeFirst(companyName)
		} catch {
			return '-'
		}
	}
	
	useEffect(() => {
		function getAccessToken() {
			getAndSetJobData();
		}
		
		getAccessToken();
	}, []);
	
	function addJobUrl() {
		const jobUrl = document.getElementById('joburl').value
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('submitJobUrlEs', {
			'verifiedemail': sessionStorage.getItem("verifiedemail"), 'jobUrl': jobUrl
		}).then(response => {
			document.getElementById('joburllabel').innerText = response.data.data
			document.getElementById('joburl').value = ''
			// getAndSetJobData();
		}).catch(err => console.log(err));
	}
	
	function getLocationsInfo(record) {
		if ('locations' in record) {
			const locs = record.locations
			if (Array.isArray(locs)) {
				return locs.map(loc => capitalizeFirst(loc)).join(', ')
			} else {
				return capitalizeFirst(locs);
			}
		} else {
			return '-'
		}
	}
	
	function getPageSource(record) {
		if ('pg_source' in record) {
			return record['pg_source']
		}
		return '-'
	}
	
	function getKeywords(record) {
		if ('keywords' in record) {
			return record['keywords'].map(rec => <div style={{
				padding: "2px", wordWrap: "break-word", wordBreak: "break-word",
				wordSpacing: "0.01em", display: "flex"
			}}>{rec},</div>)
		}
		return '-'
	}
	
	function getJobSummary(record) {
		if ('jobSummary' in record) {
			if (record['jobUrl'].includes('apple')) {
				return record['jobSummary'] + ' ' + record['keyQualifications'] + ' ' + record['educationAndExperience']
			} else {
				return record['jobSummary']
			}
		}
		return '-'
	}
	
	function getDatePosted(record) {
		if ('date_posted' in record) {
			return record['date_posted']
		} else {
			return '-'
		}
	}
	
	function copyText(text) {
		navigator.clipboard.writeText(text);
		// console.log(text);
	}
	
	function handleInputChange(e) {
		const localToken = e.target.value
		setaccessToken(localToken);
		//Sync to the database as well
		upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'shopifyStoreName': localToken})
	}
	
	
	function filterBySearch(e) {
		// if (!index) {
		// 	setfltrdjoburls(joburls);
		// 	return;
		// }
		// if (e.target.value.length < 1) {
		// 	setfltrdjoburls(joburls);
		// 	return
		// }
		// const words = e.target.value.toLowerCase().split(' ').filter(rec => rec.length > 0);
		// const stemmedWords = words //.map(word => stemmer(word));
		// const searchQuery = stemmedWords.join(" "); // Join the stemmed words for the search query
		// const resultIds = index.search(searchQuery, {limit: 1000}); // Adjust limit as needed
		// const docMatchedWQuery = resultIds.map(id => joburls[id]);
		// setfltrdjoburls(docMatchedWQuery);
		// // const stemmedWords = words.map(word => stemmer(word));
		// // const docMatchedWQuery = joburls.filter(rec => {
		// // 	const jdText = rec['jobSummary'].toLowerCase().split(' ').map(word => stemmer(word))
		// // 	let matchCount = 0;
		// //
		// // 	stemmedWords.forEach(token => {
		// // 		if (jdText.includes(token)) {
		// // 			matchCount++;
		// // 		}
		// // 	})
		// // 	return matchCount > 0
		// // })
		// // setfltrdjoburls(docMatchedWQuery);
	}
	
	function getFormatted(jobCount) {
		const formattedNumber = new Intl.NumberFormat('en-US',
			{style: 'decimal', maximumFractionDigits: 0}).format(jobCount);
		return formattedNumber
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div style={{
			marginTop: "10px", marginLeft: "0px", minHeight: "0px",
		}}>
			<div style={{fontSize: "20px", display: "flex"}}>Jobs Pool</div>
			<div style={{marginBottom: "0px", display: "flex"}}>
				<div style={{fontSize: "5x"}}> Available Jobs: {getFormatted(alldatalength)}</div>
				<div style={{fontSize: "5x", marginLeft: "10px"}}> Job Summary Available for: {getFormatted(joburls.length)}</div>
			</div>
			<ProgressBar totalJobs={alldatalength} summaryAvailable={joburls.length}/>
			<div style={{marginTop: "5px", marginBottom: "20px", display: "flex"}}>
				<BarChart width={600} height={300} data={companyChartData} margin={{right: 30, left: 20, top: 20}}>
					<CartesianGrid strokeDasharray="3 3"/>
					<XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} height={70}/>
					<YAxis/>
					<Tooltip/>
					<Legend wrapperStyle={{position: 'relative', top: -5}}/>
					<Bar dataKey="processedCount" fill="#8884d8" stackId="a" name="Processed"/>
					<Bar dataKey="availableCount" fill="#82ca9d" stackId="a" name="Processing"/>
				</BarChart>
			</div>
			<div style={{
				display: "flex", borderRadius: "10px", padding: "10px",
			}}>
				<input id='joburl' type='text' placeholder='paste job link here' style={{
					border: "1px solid grey",
					height: "40px",
					width: "500px",
					borderRadius: "10px",
					padding: "5px",
					color: "grey"
				}}/>
				<Button type='submit' variant='outlined' style={{
					marginLeft: "20px", height: "30px", marginTop: "5px"
				}} onClick={addJobUrl}>Add Job</Button>
				<div id='joburllabel' style={{marginLeft: "20px"}}>< /div>
				
				<div style={{display: "flex", alignItems: "center"}}>
					{/*padding: "10px", */}
					<div style={{display: "flex"}}>
						<input id='userSearchString' placeholder='search' onChange={filterBySearch} style={{
							border: "1px solid grey", height: "40px", width: "300px", borderRadius: "10px", padding: "5px", color: "grey"
						}}/>
						<label style={{marginTop: "10px", marginLeft: "10px"}}>Filtered : {getFormatted(fltrdjoburls.length)}</label>
					</div>
				</div>
			</div>
			
			<div style={{
				maxHeight: "700px", overflow: "scroll", border: "1px solid lightgrey", borderRadius: "10px",
				padding: "15px",
				fontSize: "14px"
			}}>
				<table style={{width: "100%"}}>
					<th style={{
						maxWidth: "30px", overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px",
						fontSize: "14px"
					}}>Row
					</th>
					<th style={{
						maxWidth: "100px", overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px",
						fontSize: "14px"
					}}>Job Url
					</th>
					<th style={{
						maxWidth: "80px", overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px",
						fontSize: "14px"
					}}>Company Name
					</th>
					<th style={{
						maxWidth: "200px", overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px",
						fontSize: "14px"
					}}>Title
					</th>
					<th style={{
						overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px",
						fontSize: "14px"
					}}>Date Posted
					</th>
					<th style={{
						overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px", fontSize: "14px"
					}}>Locations
					</th>
					<th style={{
						overflow: "scroll", maxHeight: "20px", border: "1px solid lightgrey", borderRadius: "10px", padding: "5px", fontSize: "14px"
					}}>Job Summary
					</th>
					<th style={{
						overflow: "scroll", maxHeight: "20px",
						border: "1px solid lightgrey",
						borderRadius: "10px", padding: "5px",
						fontSize: "14px", height: "20px"
					}}>Keywords
					</th>
					{/*<th style={{*/}
					{/*	overflow: "scroll", maxHeight: "20px",*/}
					{/*	border: "1px solid lightgrey",*/}
					{/*	borderRadius: "10px", padding: "5px",*/}
					{/*	fontSize: "14px", height: "20px"*/}
					{/*}}>Page Source*/}
					{/*</th>*/}
					{fltrdjoburls.map((record, index) => {
						return (<tr key={index} style={{}}>
							<td style={{
								minWidth: "50px",
								textAlign: "center",
								fontSize: "12px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								padding: "5px"
							}}>{index + 1}</td>
							<td style={{
								minWidth: "50px",
								overflow: "scroll",
								fontSize: "12px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								padding: "5px"
							}}>
								<a href={record.jobUrl.trim()} target='_blank'>
									{record.jobUrl}
								</a>
							</td>
							<td style={{
								minWidth: "50px",
								overflow: "scroll",
								fontSize: "12px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								padding: "5px",
								textAlign: "center"
							}}>
								{extractCompanyName(record.jobUrl)}
							</td>
							<td style={{
								minWidth: "200px",
								overflow: "scroll",
								fontSize: "12px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								padding: "5px",
								textAlign: "center"
							}}>
								{getTitleText(record)}
							</td>
							<td style={{
								minWidth: "120px",
								overflow: "scroll",
								fontSize: "12px",
								border: "1px solid lightgrey", borderRadius: "10px",
								padding: "5px",
								textAlign: "center"
							}}>
								{getDatePosted(record)}
							</td>
							<td style={{
								minWidth: "120px",
								overflow: "scroll",
								fontSize: "12px",
								border: "1px solid lightgrey",
								borderRadius: "10px",
								padding: "5px",
								textAlign: "center"
							}}>
								{getLocationsInfo(record)}
							</td>
							<td style={{
								minWidth: "220px", overflow: "scroll", fontSize: "12px", border: "1px solid lightgrey", borderRadius: "10px",
							}}>
								<div style={{
									marginLeft: "10px", textAlign: "left", maxHeight: "200px", display: "block"
								}}>
									{getJobSummary(record)}
								</div>
							</td>
							<td style={{
								minWidth: "220px", overflow: "scroll", fontSize: "12px", border: "1px solid lightgrey", borderRadius: "10px",
							}}>
								<div style={{
									marginLeft: "10px", textAlign: "left", maxHeight: "200px", display: "block"
								}}>
									{getKeywords(record)}
								</div>
							</td>
							{/*<td style={{*/}
							{/*	minWidth: "200px",*/}
							{/*	overflow: "scroll",*/}
							{/*	fontSize: "12px",*/}
							{/*	border: "1px solid lightgrey",*/}
							{/*	// borderRadius: "10px",*/}
							{/*	padding: "5px",*/}
							{/*	textAlign: "center"*/}
							{/*}}>*/}
							{/*	<div style={{maxHeight: "100px", overflowY: "auto", display: "block"}}>*/}
							{/*		<Button variant='outlined' style={{height: "20px"}} onClick={() => copyText(record.page_text)}>Copy Txt</Button>*/}
							{/*		{record.page_text}*/}
							{/*	</div>*/}
							{/*</td>*/}
							{/*<td style={{*/}
							{/*	// width: "200px",*/}
							{/*	overflow: "scroll",*/}
							{/*	fontSize: "12px",*/}
							{/*	border: "1px solid lightgrey", borderRadius: "10px", padding: "10px",*/}
							{/*	textAlign: "center"*/}
							{/*}}>*/}
							{/*	<div style={{*/}
							{/*		maxHeight: "200px", overflowY: "auto", display: "block",*/}
							{/*		maxWidth: "300px", textAlign: "left", minWidth: "150px"*/}
							{/*	}}>*/}
							{/*		<Button variant='outlined' style={{height: "20px", fontSize: "13px"}} onClick={() => copyText(record.pg_source)}>Copy*/}
							{/*			Txt</Button>*/}
							{/*		{getPageSource(record)}*/}
							{/*	</div>*/}
							{/*</td>*/}
						</tr>)
					})}
				</table>
			</div>
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
			{/*<div style={{marginTop: "20px"}}></div>*/}
			{/*<p style={{fontWeight: "bold"}}>Ready for application</p>*/}
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
			{/*<div style={{marginTop: "20px"}}></div>*/}
			{/*<p style={{fontWeight: "bold"}}>Discarded</p>*/}
			{/*<EditableTable style={{marginTop: "20px"}}/>*/}
		</div>)
	}
}