// HorizontalSubMenuComponent.jsx
import React, {useState} from 'react';
import './HorizontalSubMenuComponent.css';
import UploadCsv from "./UploadCsv";
import ShowContacts from "./ShowContacts";

const HorizontalSubMenuComponent = ({userContacts}) => {
	const [activeIndex, setActiveIndex] = useState(0); // Start with the first item active
	// console.log(userContacts);
	const ContentComponentOne = () => <ShowContacts userContacts={userContacts}/>;
	const ContentComponentTwo = () => <UploadCsv userContacts={userContacts}/>
	
	const submenuItems = [
		{
			title: 'All Contacts',
			content: ContentComponentOne,
		},
		// {
		// 	title: 'Upload CSV',
		// 	content: ContentComponentTwo,
		// },
		// {
		// 	title: 'Connect to Google',
		// 	content: ContentComponentTwo,
		// },
	];
	const handleItemClick = (index) => {
		setActiveIndex(index);
	};
	
	const ActiveContentComponent = submenuItems[activeIndex].content;
	
	return (
		<div>
			<div className="submenu-container" style={{marginTop: '5px'}}>
				{submenuItems.map((item, index) => (
					<div
						style={{marginLeft: '10px', textAlign: 'left'}}
						key={index}
						className={`submenu-item ${index === activeIndex ? 'active' : ''}`}
						onClick={() => handleItemClick(index)}
					>
						{item.title}
					</div>
				))}
			</div>
			<div className="content-container">
				{/* Render the active component */}
				<ActiveContentComponent/>
			</div>
		</div>
	);
};

export default HorizontalSubMenuComponent;