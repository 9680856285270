import React, {useEffect, useState} from "react";
import ImageList from "@mui/material/ImageList";
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router-dom';
import './savedTemplates.css'

export default function SavedTemplates() {
	const [isLoading, setIsLoading] = useState(true);
	const [templateIdToHtmlDict, settemplateIdToHtmlDict] = useState({})
	const navigate = useNavigate()
	
	useEffect(() => {
		window.scrollTo(0, 0);
		
		async function getCampaigns() {
			var verifiedemail = sessionStorage.getItem("verifiedemail");
			axios.defaults.baseURL = getBaseUrlNodeServer();
			const response = await axios.post('getusersavedtemplates', {'verifiedemail': verifiedemail})
			const usertemplates = response.data
			const promises = usertemplates.map(async (template) => {
				const responseJson = await fetch(getBaseUrlNodeServer() + 'mjml2html', {
					method: 'post',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({'mgml': template.content})
				})
				const htmlJson = await responseJson.json();
				return {
					[template._id]: {
						'subject': template.subject,
						'html': htmlJson.html
					}
				};
			})
			const results = await Promise.all(promises);
			const finalResult = Object.assign({}, ...results);
			settemplateIdToHtmlDict(finalResult);
			setIsLoading(false);
		}
		
		getCampaigns();
	}, templateIdToHtmlDict.length);
	
	async function userWantsToDelete(templateId) {
		toast.success("Deleted the template");
		var signedin = sessionStorage.getItem("signedin");
		const verifiedemail = sessionStorage.getItem("verifiedemail");
		axios.defaults.baseURL = getBaseUrlNodeServer();
		const response = await axios.post('deleteusertemplate',
			{'verifiedemail': verifiedemail, 'templateId': templateId})
		delete templateIdToHtmlDict[templateId];
		// window.location.reload();
	}
	
	async function userWantsToEdit(templateId) {
		var signedin = sessionStorage.getItem("signedin");
		const verifiedemail = sessionStorage.getItem("verifiedemail");
		if (signedin) {
			//don't duplicate let them edit it
			navigate('/editor/' + templateId);
		} else {
			toast.error("Login to Edit the Template")
		}
	}
	
	return (
		<div>
			<div style={{margin: "5px"}}>My saved templates, {Object.entries(templateIdToHtmlDict).length}</div>
			<hr/>
			<ImageList sx={{width: '100%', height: "100%", marginTop: "5px"}} cols={4}>
				{Object.entries(templateIdToHtmlDict).map(([templateId, record], index) => (
					<div
						className='image-container'
						style={{
							marginLeft: "5px", // overflow: 'hidden',
							transform: 'scale(0.6)', transformOrigin: '0 0', maxWidth: "300px", maxHeight: "500px",
						}}>
						<div>
								{/*<Link to={`/editor/${templateId}`}>*/}
								{/*<h4 style={{*/}
								{/*	marginLeft: "20px",*/}
								{/*	textAlign: "left",*/}
								{/*	flex: '1',*/}
								{/*	fontSize: "20px",*/}
								{/*	marginRight: "100px",*/}
								{/*	width: "400px"*/}
								{/*}}>{record.subject}</h4>*/}
							<div
									style={{minWidth: '600px', marginBottom: "5px", maxHeight: "800px", overflow: "hidden"}}
									dangerouslySetInnerHTML={{__html: record.html}}></div>
								{/*</Link>*/}
							</div>
						<div id='specialbutton' style={{overflow: "scroll"}}>
							<label id='specialbuttonChild'
							       style={{backgroundColor: "green"}}
							       onClick={() => userWantsToEdit(templateId)}>
								Edit</label>
							<label id='specialbuttonChild' onClick={() => userWantsToDelete(templateId)} style={{
								backgroundColor: "#e05b5b",
							}}>Delete</label>
						</div>
					</div>
					)
				)}
			</ImageList>
		</div>
	
	)
	
}