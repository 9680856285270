import React, {Component} from 'react'
import {Card} from 'react-bootstrap'
import logo from '../assets/logo.jpg'
import aiImage from '../assets/dalle_img_1.png'

export default class AboutUs extends Component {
	state = {
		confirming: true
	}
	componentDidMount = () => {
		this.setState({confirming: false})
	}
	render = () => {
		return (
			<div style={{marginBottom: 20, marginTop: "50px"}}>
				<div style={{width: "600px", margin: "0 auto"}}><br/>
					<h2 style={{textAlign: "center"}}>Empowering businesses with innovative CRM solutions.</h2>
					{/*<p style={{textAlign: "center"}}>Boosting Private Sector Growth by providing state of the art Software Solutions & Services.</p>*/}
				</div>
				<div style={{marginTop: "0px"}}>
					<div className='row' style={{padding: "20px", justifyContent: "center", alignContent: "center", display: "flex"}}>
						<div style={{marginTop: 10, marginRight: 20, justifyContent: "center"}}>
							<h1>VISION</h1>
							<div>
								<p style={{fontSize: '16px'}}>Provide expert solutions with utmost care for the customer.</p>
								<p style={{fontSize: '16px'}}>Unlock the potential of high-quality communications between companies.</p>
								<p style={{fontSize: '16px'}}>Provide smart customer matching, hence save everyone's time.</p>
								<p style={{fontSize: '16px'}}>Provide a single platform for all modes of communication.</p>
								<p style={{fontSize: '16px'}}>Automate communication with highly intelligent AI services.</p>
							</div>
						</div>
						<div style={{marginLeft: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
							<img
								src={aiImage}
								alt='Description'
								style={{
									width: '400px',
									height: '350px',
									objectFit: 'cover',
									borderRadius: '8px',
									boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)'
								}}
							/>
						</div>
						{/* <p style={{marginTop:"30px", textAlign:"center"}}>Our (CIN) company identification number is U72900HR2020PTC091245.</p> */}
					</div>
					<div style={{display: "flex", justifyContent: "center"}}>
						{/*<div style={{paddingLeft: "30px", clear: "right"}}>*/}
							<h1>Team Members</h1>
						<div className='row' style={{padding: "20px", justifyContent: "center", alignContent: "center", display: "contents"}}>
							<Card style={{
								borderRadius: "5px",
								padding: "10px",
								margin: "10px",
								boxShadow: "none",
								width: "100%",
								maxWidth: "300px",
								height: "420px",
								position: "relative",
								border: "0.1px solid lightgray"
							}}>
								<div style={{height: "200px", overflow: "hidden", marginBottom: "10px"}}>
									<img
										className="img-fluid"
										src={logo}
										style={{width: "100%", height: "100%", objectFit: "cover"}}
									/>
								</div>
								<div className="card-body">
									<h4 className="card-title">Bijender Kumar</h4>
									<p className="card-text">Founding Director</p>
									<a
										href="https://www.linkedin.com/in/b-kumar-877755204/"
										target="_blank"
										style={{
											color: "rgb(45,100,188)",
											fontWeight: "bolder",
											position: "absolute",
											bottom: "10px",
											left: "30px",
											textDecoration: "none",  // Remove default underline
											transition: "background 0.3s, color 0.3s, border-radius 0.3s",  // Add transition effect for background, text color, and border-radius
											display: "inline-block",  // Ensure the link takes only necessary space
											padding: "8px 16px",  // Add padding for better appearance
											borderRadius: "4px",  // Optional: Add initial border-radius
											border: "1px solid rgb(45,100,188)",  // Optional: Add initial border for better visibility
										}}
										onMouseOver={(e) => {
											e.currentTarget.style.backgroundColor = "rgb(45,100,188)";
											e.currentTarget.style.color = "#fff";  // Change text color on hover
											e.currentTarget.style.borderRadius = "8px";  // Change border-radius on hover
										}}
										onMouseOut={(e) => {
											e.currentTarget.style.backgroundColor = "transparent";
											e.currentTarget.style.color = "rgb(45,100,188)";  // Revert text color on mouse out
											e.currentTarget.style.borderRadius = "4px";  // Revert border-radius on mouse out
										}}
									>
										Linkedin
									</a>
								
								</div>
							</Card>
							
							<Card style={{
								borderRadius: "5px",
								padding: "10px",
								margin: "10px",
								boxShadow: "none",
								width: "100%",
								maxWidth: "300px",
								height: "420px",
								position: "relative",
								border: "0.1px solid lightgray"
							}}>
								<div style={{height: "200px", overflow: "hidden", marginBottom: "10px"}}>
									<img
										className="img-fluid"
										src="https://media.licdn.com/dms/image/C4E03AQEFHq3OD5Jn-Q/profile-displayphoto-shrink_800_800/0/1585545634666?e=1709164800&v=beta&t=xzKdHqfvbzYLH-3yCGJKmgIzqolrSeCYsxvI3hKHgIg"
										style={{width: "100%", height: "100%", objectFit: "cover"}}
									/>
								</div>
								<div className="card-body">
									<h4 className="card-title">Bhagyashri Tushir</h4>
									<p className="card-text">Founding Director</p>
									<p className="card-text">Phd Computer Science, Santa Clara University, USA</p>
									<a
										href='https://www.linkedin.com/in/btushir/'
										target="_blank"
										style={{
											color: "rgb(45,100,188)",
											fontWeight: "bolder",
											position: "absolute",
											bottom: "10px",
											left: "30px",
											textDecoration: "none",  // Remove default underline
											transition: "background 0.3s, color 0.3s, border-radius 0.3s",  // Add transition effect for background, text color, and border-radius
											display: "inline-block",  // Ensure the link takes only necessary space
											padding: "8px 16px",  // Add padding for better appearance
											borderRadius: "4px",  // Optional: Add initial border-radius
											border: "1px solid rgb(45,100,188)",  // Optional: Add initial border for better visibility
										}}
										onMouseOver={(e) => {
											e.currentTarget.style.backgroundColor = "rgb(45,100,188)";
											e.currentTarget.style.color = "#fff";  // Change text color on hover
											e.currentTarget.style.borderRadius = "8px";  // Change border-radius on hover
										}}
										onMouseOut={(e) => {
											e.currentTarget.style.backgroundColor = "transparent";
											e.currentTarget.style.color = "rgb(45,100,188)";  // Revert text color on mouse out
											e.currentTarget.style.borderRadius = "4px";  // Revert border-radius on mouse out
										}}
									>
										LinkedIn
									</a>
								
								</div>
							</Card>
							
							<Card style={{
								borderRadius: "5px",
								padding: "10px",
								margin: "10px",
								boxShadow: "none",
								width: "100%",
								maxWidth: "300px",
								height: "420px",
								position: "relative",
								border: "0.1px solid lightgray"
							}}>
								<div style={{height: "200px", overflow: "hidden", marginBottom: "10px"}}>
									<img
										className="img-fluid"
										src="https://media.licdn.com/dms/image/D5603AQFh1ghieGVbXw/profile-displayphoto-shrink_800_800/0/1695741941537?e=1709164800&v=beta&t=AUX4iY2nRSZuQFMOboF-YM4xctW6uCuVPrdD-EwElWc"
										style={{width: "100%", height: "100%", objectFit: "cover"}}
									/>
								</div>
								<div className="card-body">
									<h4 className="card-title">Nishant Dalal</h4>
									<p className="card-text">UI Design</p>
									<p>Backend</p>
									<a
										href='https://www.linkedin.com/in/nishant-dalal-70274012a/'
										target="_blank"
										style={{
											color: "rgb(45,100,188)",
											fontWeight: "bolder",
											position: "absolute",
											bottom: "10px",
											left: "30px",
											textDecoration: "none",  // Remove default underline
											transition: "background 0.3s, color 0.3s, border-radius 0.3s",  // Add transition effect for background, text color, and border-radius
											display: "inline-block",  // Ensure the link takes only necessary space
											padding: "8px 16px",  // Add padding for better appearance
											borderRadius: "4px",  // Optional: Add initial border-radius
											border: "1px solid rgb(45,100,188)",  // Optional: Add initial border for better visibility
										}}
										onMouseOver={(e) => {
											e.currentTarget.style.backgroundColor = "rgb(45,100,188)";
											e.currentTarget.style.color = "#fff";  // Change text color on hover
											e.currentTarget.style.borderRadius = "8px";  // Change border-radius on hover
										}}
										onMouseOut={(e) => {
											e.currentTarget.style.backgroundColor = "transparent";
											e.currentTarget.style.color = "rgb(45,100,188)";  // Revert text color on mouse out
											e.currentTarget.style.borderRadius = "4px";  // Revert border-radius on mouse out
										}}
									>
										Linkedin
									</a>
									<a
										href='https://github.com/nishant0207'
										target="_blank"
										style={{
											color: "rgb(17, 68, 128)",
											fontWeight: "bolder",
											position: "absolute",
											bottom: "10px",
											right: "30px",
											textDecoration: "none",  // Remove default underline
											transition: "background 0.3s, color 0.3s, border-radius 0.3s",  // Add transition effect for background, text color, and border-radius
											display: "inline-block",  // Ensure the link takes only necessary space
											padding: "8px 16px",  // Add padding for better appearance
											borderRadius: "4px",  // Optional: Add initial border-radius
											border: "1px solid rgb(17, 68, 128)",  // Optional: Add initial border for better visibility
										}}
										onMouseOver={(e) => {
											e.currentTarget.style.backgroundColor = "rgb(17, 68, 128)";
											e.currentTarget.style.color = "#fff";  // Change text color on hover
											e.currentTarget.style.borderRadius = "8px";  // Change border-radius on hover
										}}
										onMouseOut={(e) => {
											e.currentTarget.style.backgroundColor = "transparent";
											e.currentTarget.style.color = "rgb(17, 68, 128)";  // Revert text color on mouse out
											e.currentTarget.style.borderRadius = "4px";  // Revert border-radius on mouse out
										}}
									>
										Github
									</a>
								
								</div>
							</Card>
						</div>
					</div>
				</div>
			</div>
		)
	}
}