import {Text} from '../atoms/Text'
import {HeroTexts, TemplatesTexts} from '../particles/DataLists'
import {Image} from "../atoms/Image"
import {Slide} from "react-awesome-reveal"
import templateImage from "../../assets/templates1.png"
import * as React from 'react'
import {useState} from 'react'
import {Button} from "../atoms/Button.tsx";
import {ModalPopup} from "../molecules/ModalPopup.tsx";
import {SigninForm} from "../molecules/SigninForm.tsx";
import {RegisterForm} from "../molecules/RegisterForm.tsx";
import './template.css'

const Templates = () => {
    const [activeModal, setActiveModal] = useState<string>('')
    return (
        <section
            className="w-full h-auto flex flex-col items-start justify-center relative lg:px-24 md:px-10 px-6 my-28 gap-5">
            <main className='w-full grid md:grid-cols-2 lg:gap-6 gap-12 md:gap-5'>
                {/* Text and Steps Container  */}
                <div className='w-full flex flex-col gap-6 order-2 md:order-1' style={{
                    border: "1px solid grey",
                    paddingLeft: "30px", paddingRight: "30px",
                    borderRadius: "50px"
                }}>
                    <Text as="h1" className="lg:text-5xl md:text-3xl text-4xl text-gray-600 font-medium mb-0">
                        {TemplatesTexts.firstText}
                    </Text>
                    {/*<Text as="h1" className="lg:text-5xl md:text-3xl text-4xl text-gray-900 font-medium mt-0 mb-0">*/}
                    <span className="gradient-text">Start Editing</span>
                    {/*</Text>*/}
                    <Text as='p' className='text-sm text-gray-900 font-light text-justify'>
                        {TemplatesTexts.description}
                    </Text>
                    {/*<Text as='p' className='text-sm text-color3 font-light text-justify'>*/}
                    {/*    {TemplatesTexts.description}*/}
                    {/*</Text>*/}
                    {/*<Text as='p' className='text-sm text-color3 font-light text-justify'>*/}
                    {/*    {TemplatesTexts.description}*/}
                    {/*</Text>*/}
                </div>
                <div
                    className='w-full flex flex-col justify-center lg:items-center items-start relative order-1 md:order-2'
                    style={{
                        border: "1px solid grey",
                        padding: "30px",
                        borderRadius: "50px",
                        background: `
                            radial-gradient(circle at left top, lightblue, transparent 50%),
                            radial-gradient(circle at right top, teal, transparent 50%),
                            radial-gradient(circle at left bottom, lightblue, transparent 50%),
                            radial-gradient(circle at right bottom, lightgreen, transparent 50%)
                        `
                    }}>
                    <Slide direction="right">
                        <Image image={templateImage} alt="Hero Image"
                               style={{borderRadius: "10px", overflow: "hidden"}}
                               className=" lg:h-[100%] lg:w-[100%] md:h-[100%] md:w-full w-[90%] h-[50vh]"/>
                    </Slide>
                </div>
            </main>

            <div style={{justifyContent: "center", display: "flex", marginTop: "20px", width: "100%"}}>
                <Button type="button"
                        className="outline-none border-none lg:px-9 px-5 py-3 bg-color2 text-white font-light rounded-lg"
                        onClick={() => setActiveModal('Signup')}
                        style={{
                            paddingTop: '1rem', paddingBottom: '1rem', fontSize: '1rem', fontWeight: "bold",
                            letterSpacing: '2px', marginBottom: "20px", marginTop: "-10px"
                        }}>
                    {HeroTexts.firstButton}
                </Button>
                {activeModal != '' && <ModalPopup onClose={() => setActiveModal('')}
                                                  children={activeModal == 'login' ?
                                                      <SigninForm onClose={() => setActiveModal('')}/> :
                                                      <RegisterForm onClose={() => setActiveModal('')}/>}
                                                  heading={activeModal == 'login' ? "SignIn" : "Signup"}
                                                  description={activeModal == 'login' ? "Enter verification ID sent from MAilChamp.Ai" : "Enter your email to register."}/>}
            </div>

        </section>
    )
}

export default Templates