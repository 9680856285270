import React, {useEffect, useState} from 'react';
import CampaignHorizontalSubMenuComponent from './CampaignHorizontalSubMenuComponent'
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";

const fileSizeLimitInMb = 4.0

const Campaign = () => {
	const verifiedemail = sessionStorage.getItem("verifiedemail");
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([['First Name', 'Last Name', 'contact@mailchamp.ai']]);
	const [userCampaigns, setuserCampaigns] = useState([]);
	
	function fillTableWithData(data, fileInfo) {
		if (fileInfo.size / (1024 * 1024) <= fileSizeLimitInMb) {
			console.log('File Size ', fileInfo.size / (1024 * 1024))
			console.log('I have the data here: ', fileInfo)
			console.log('I have the data here: ', data)
			setData(data)
		} else {
			console.log('File Size more than ' + fileSizeLimitInMb + ' Mb')
		}
	}
	
	useEffect(() => {
		async function updateContacts() {
			try {
				axios.defaults.baseURL = getBaseUrlNodeServer()
				axios.post('getcampaigns', {'verifiedemail': verifiedemail}).then(response => {
						const usercontacts = response.data
						setuserCampaigns(usercontacts);
						setIsLoading(false);
					}
				).catch(err => console.log(err));
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		updateContacts();
	}, userCampaigns);
	
	if (isLoading) {
		return <div> .... </div>
	} else {
		return (
			<div style={{minHeight: '1000px', textAlign: 'center', marginTop: '10px', marginLeft: "50px"}}>
				{/*Do not delete this code*/}
				{/*<Grid container spacing={20}>*/}
				{/*	<Grid item xs={8} sm={4} md={2}>*/}
				{/*		<MuiCard sx={{*/}
				{/*			width: 200,*/}
				{/*			margin: 0,*/}
				{/*			height: 90,*/}
				{/*			marginLeft: 0,*/}
				{/*			backgroundColor: "white",*/}
				{/*			boxShadow: "1px 1px 1px rgba(0,0,0,0.2)"*/}
				{/*		}}>*/}
				{/*			<CardContent>*/}
				{/*				<Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>*/}
				{/*					Campaigns Count*/}
				{/*				</Typography>*/}
				{/*				<Typography variant="h5" component="h2" style={{color: 'purple'}}>{userCampaigns != null ? userCampaigns.length : 0}*/}
				{/*				</Typography>*/}
				{/*			</CardContent>*/}
				{/*		</MuiCard>*/}
				{/*	</Grid>*/}
				{/*</Grid>*/}
				<CampaignHorizontalSubMenuComponent index={0}/>
			</div>
		)
	}
};

export default Campaign;
