import React, {Component, useRef, useState} from 'react'
import './Sidebar.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {Icon1Circle, Icon2Circle, Icon3Circle, Icon4Circle} from 'react-bootstrap-icons';
import logo from "../main3.png";
import SubmitDemoButton from "./SubmitDemoButton";
import Dashboard from "../Dashboard/dashboard";
import CarouselSection from "../Carousel/Carousel";
import Flows from "../Flows/Flows";
import Registration from "../Demo/EmailOnboarding/Registration";
import Login from '../Demo/EmailOnboarding/Login'
import campaignSummary from './campaignSummary.png'
// import ParallaxComponent from "../Parallax/ParallaxComponent";
import SlideInImage from './SlideInImage';
import './sidestyle.css'
import SubMenusComponent from "../Contacts/SubMenusComponent";

const LandingPage = () => {
	return (
		<div>
			<div className='heading-tile' style={{fontFamily: 'Roboto,sans-serif', fontWeight: 50}}>
				<div className='col'>
					<p style={{
						textAlign: 'center',
						color: '#d98c35',
						marginTop: '0%',
						marginBottom: '15px',
						fontSize: 40,
						fontWeight: 'bolder'
					}}>Limitless Growth,
						<span style={{
							backgroundImage: 'linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))',
							color: 'transparent',
							WebkitBackgroundClip: 'text',
							backgroundClip: 'text',
							// fontSize: '3em',
							fontFamily: 'Arial'
						}}>Fuel Your Success</span>
					</p>
					{/*<p style={{*/}
					{/*    fontSize: 23,*/}
					{/*    margin: 0,*/}
					{/*    marginTop: 0,*/}
					{/*    textAlign: 'left',*/}
					{/*    color: 'green',*/}
					{/*    fontSize: 30,*/}
					{/*    fontWeight: 'bold'*/}
					{/*}}>Engage with Customers.</p>*/}
					{/*<p style={{fontSize: 23, margin: 0, marginTop: 0, color: '#292d7f', textAlign: 'left'}}>Increase*/}
					{/*    Brand Awareness.</p>*/}
					{/*<p style={{paddingLeft: '0%', marginTop: 10, textAlign: 'left', width: 400, fontSize: 14}}>Reach*/}
					{/*    the right audience using data science and AI.</p>*/}
					{/*<p style={{paddingLeft: '0%', marginTop: 0, textAlign: 'left', width: 400, fontSize: 14}}>Engage*/}
					{/*    with your personal touch.</p>*/}
					{/*<p style={{paddingLeft: '0%', marginTop: 0, textAlign: 'left', width: 400, fontSize: 14}}>Grow*/}
					{/*    your client base with our scalable infrastructure.</p>*/}
				</div>
				{/*/!* Image for home area tile *!/*/}
				{/*<div className='col' style={{textAlign: 'right'}}>*/}
				{/*    <img src={logo} alt="Email Solutions" style={{width: '80%'}}></img>*/}
				{/*</div>*/}
			</div>
			<div class="container-fluid p-lg-2">
				<div class="row d-flex"
				     style={{"box-shadow": "0px 8px 8px rgba(0, 0, 0, 0.1)", "border-radius": "20px"}}>
					<div class="w-50 left-side p-7"
					     style={{
						     alignItems: "center",
						     display: "flex",
						     flexDirection: "column",
						     paddingBottom: "10px",
					     }}>
						<SubmitDemoButton/>
					</div>
					<div class="w-50 right-side p-4"
					     style={{
						     "background-color": "rgb(252,238,225)",
						     "border-radius": "20px",
						     "display": "flex",
						     "flex-direction": "column",
						     "justify-content": "center",
						     "align-content": "center"
					     }}>
						<div class="text_area" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
							<h1 style={{fontSize: "32px", fontWeight: "bolder"}}>Book a demo with our Specialists</h1>
							<p style={{"font-size": "large"}}><Icon1Circle></Icon1Circle> Get a tour of the platform
								catered
								to your needs </p>
							<p style={{"font-size": "large"}}><Icon2Circle></Icon2Circle> See real examples of
								successfull automation and Support </p>
							<p style={{"font-size": "large"}}><Icon3Circle></Icon3Circle> Discover effective tactics to
								boost email marketing </p>
							<p style={{"font-size": "large"}}><Icon4Circle></Icon4Circle> Understand which pricing plan
								is best for you </p>
						</div>
					</div>
				</div>
			</div>
			
			<h3 style={{textAlign: 'center', marginTop: '20px'}}>Demo</h3>
			<div style={{
				justifyContent: 'center', display: 'flex',
				border: '1px solid black', borderRadius: '50px', alignItems: 'center',
				alignContent: 'center'
			}}>
				<video controls autoPlay style={{height: '400px'}}>
					<source src="http://localhost:8080/video/ab632ec89e6b66db5395b3ebcd7431ec.mov" type="video/mp4"/>
					{/* Add sources for other video formats if needed */}
					Your browser does not support the video tag.
				</video>
			</div>
			{/*<ParallaxComponent/>*/}
			<div style={{
				width: "70vw",
				alignContent: "center",
				margin: "0 auto",
				display: "flex",
				justifyContent: "center",
				marginTop: "50px",
				flexDirection: "row"
			}}>
				<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
					<h1>Elevate Your Campaigns: Visualize Success with Data-Driven Email Insights</h1>
				</div>
				<img src={campaignSummary} style={{
					height: 300,
					width: "auto",
					boxShadow: "10px 10px 10px rgba(0,0,0,0.2)",
					border: "1px solid lightgray",
					borderRadius: "20px"
				}}></img>
			</div>
			{/* Content before the image */}
			{/*<div style={{height: '100vh'}}>Scroll Down</div>*/}
			{/* The image */}
			<SlideInImage/>
			{/* Content after the image */}
			{/*<div style={{height: '200vh'}}>More Content</div>*/}
			<br/>
			<SubMenusComponent/>
			<p style={{fontSize: "20px"}}>Register and take demo in few steps</p>
			<Registration/>
			<Login/>
			{/*<div style={{*/}
			{/*      height: '3em',*/}
			{/*      backgroundImage: 'linear-gradient(90deg, rgb(0 255 74 / 22%), rgb(71 0 246 / 98%), rgb(255 255 255))',*/}
			{/*      borderRadius: '20px',*/}
			{/*    }}>*/}
			{/*      <div style={{*/}
			{/*        backgroundImage: 'linear-gradient(rgb(0 255 179), rgb(0 246 144), rgb(255 255 255))',*/}
			{/*        color: 'transparent',*/}
			{/*        backgroundClip: 'text',*/}
			{/*        fontSize: '1.5em',*/}
			{/*        fontFamily: 'Arial',*/}
			{/*        placeContent: 'center',*/}
			{/*        display: 'flex',*/}
			{/*        alignItems: 'center',*/}
			{/*      }}>*/}
			{/*        AI + Great Designers = Best Email Design*/}
			{/*      </div>*/}
			{/*</div>*/}
			
			{/*<CarouselSection/>*/}
			{/*<br/><br/>*/}
			{/*<div style={{textAlign: "center", marginBottom: "-20px"}}>*/}
			{/*	<table style={{width: "100%", backgroundColor: "rgb(220,205,245,64%)"}}>*/}
			{/*		<td style={{width: "30%"}}>*/}
			{/*			<div style={{*/}
			{/*				border: "none",*/}
			{/*				alignItems: "center",*/}
			{/*				display: "flex",*/}
			{/*				justifyContent: "center",*/}
			{/*				height: "60px",*/}
			{/*				verticalAlign: "center",*/}
			{/*				color: "black",*/}
			{/*				borderRadius: "10px"*/}
			{/*			}}><p style={{fontFamily: "monospace", fontSize: "15px", marginTop: "25px"}}>Sample*/}
			{/*				Dashboard</p>*/}
			{/*			</div>*/}
			{/*		</td>*/}
			{/*		<td style={{backgroundColor: "rgb(220,205,210,64%)"}}>*/}
			{/*			/!*<table border="1" style={{width:"100%", borderColor:"rgb(220,205,210,34%)"}}>*!/*/}
			{/*			/!*    <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>*!/*/}
			{/*			/!*    <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>*!/*/}
			{/*			/!*    <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>*!/*/}
			{/*			/!*</table>*!/*/}
			{/*		</td>*/}
			{/*	</table>*/}
			{/*</div>*/}
			{/*<Dashboard/>*/}
		</div>
	)
}

export default LandingPage