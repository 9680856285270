// SlideInImage.js
import React, {useEffect, useState} from 'react';
import './sidestyle.css';
import yourImage from '../../assets/dalle_img_1.png'; // import your image

const SlideInImage = () => {
	const [isVisible, setIsVisible] = useState(false);
	
	const listenToScroll = () => {
		let heightToTriggerAnimation = 650; // Adjust as needed
		const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		if (winScroll > heightToTriggerAnimation) { // Trigger animation
			setIsVisible(true);
		} else { // Reset animation
			setIsVisible(false);
		}
	};
	
	useEffect(() => {
		window.addEventListener("scroll", listenToScroll);
		return () => window.removeEventListener("scroll", listenToScroll);
	}, []);
	
	return (
		<img
			src={yourImage}
			className={`image-slide-in ${isVisible ? 'slide-in' : ''}`}
			alt="Slide-in"
			style={{width: '400px'}}
		/>
	);
};

export default SlideInImage;
