import * as React from "react"
import {useEffect, useState} from "react"
import {Image} from "../atoms/Image";
import {Button} from "../atoms/Button"
// @ts-ignore
import Logo from "../../assets/logo.jpg"
import {NavButtons} from "../particles/DataLists"
import {List} from "../atoms/List";
// @ts-ignore
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {ArrowCircleRight} from "@phosphor-icons/react";
// @ts-ignore
import {Slide} from "react-awesome-reveal";
// @ts-ignore
import {ModalPopup} from "../molecules/ModalPopup.tsx";
// @ts-ignore
import {RegisterForm} from "../molecules/RegisterForm.tsx";
// @ts-ignore
import {SigninForm} from "../molecules/SigninForm.tsx";

// @ts-ignore
const NavBar = ({signedin, verifiedemail}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [activeModal, setActiveModal] = useState<string>('')
    // const [scrollY, setScrollY] = useState(0)
    const [navBarColor, setNavBarColor] = useState(false)

    const handleToggle = () => {
        setOpen(!open)
    }

    const listenScrollEvent = () => {
        window.scrollY > 10 ? setNavBarColor(true) : setNavBarColor(false);
    };
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);

    function buttonClickState(activeModal: string, navbutton: { name: any; url: any; }) {
        if (activeModal == '' && (navbutton.name == 'Login' || navbutton.name == 'Signup')) {
            if (navbutton.name === "Signup") {
                setActiveModal('signup')
            } else {
                setActiveModal('login')
            }
        } else {
            setActiveModal('');
            navigate(navbutton.url)
        }
    }

    if (signedin) {
        return <></>
    } else {
        return (<header className="w-full h-auto bg-transparent overflow-x-hidden fixed z-50 top-0 left-0">
            <Slide direction="down">
                <nav
                    className={`w-full md:h-20 h-20 ${navBarColor ?
                        "backdrop-blur-sm border-b shadow-2xl" :
                        // backdrop-blur-xl 
                        "bg-transparent"
                    } lg:px-24 md:px-12 px-8 flex justify-between items-center`}>
                    <div><a className="text-2xl font-bold text-gray-400" href="/">Mail<span
                        className="text-orange-300">Champ</span>.Ai</a></div>
                    <div className="lg:flex hidden items-center gap-20">
                        <ul className="flex items-center justify-center gap-0">
                            {NavButtons.map((navbutton, index) => (<List className="w-full" key={index}>
                                <Button
                                    onClick={() => buttonClickState(activeModal, navbutton)} type="button"
                                    className={`${navbutton.name === "Signup" ?
                                        "border-2 border-gray-950 before:top-0" :
                                        "before:bottom-0 border-b-2 border-transparent hover:border-gray-950"}
                                    ${navbutton.name === "Home" ? 'text-color2 border-b-gray-950' : 'text-gray-400'} 
                                    py-2 px-4 relative z-10 before:content-[''] before:absolute before:left-0 before:w-full 
                                    before:h-0 before:bg-color2 before:-z-10 hover:before:h-full before:transition-all 
                                    before:duration-300 before:ease-in text-base`}>
                                    {navbutton.name}</Button>
                            </List>))}
                        </ul>
                    </div>
                </nav>
            </Slide>


            {/* Mobile Nav  */}
            <nav
                className={`flex justify-end lg:hidden h-screen w-full bg-gray-950/90 fixed top-0  ${open ? "right-0" : "-right-[120vw]"} transition-all duration-500 ease-out`}>
                <div
                    className={`w-[70%] h-screen bg-white flex flex-col justify-between items-center relative ${open ? "right-0" : "-right-[120vw]"} transition-all duration-500 ease-out delay-300`}>
                    <section className="w-full px-4 py-6 flex flex-col gap-16">
                        <div className="w-full flex justify-between items-center">
                            <Image as="a" href="/" className="md:h-10 h-8" image={Logo} alt="Logo"/>
                            <div className="hamburger text-gray-950 cursor-pointer" onClick={handleToggle}>
                                <ArrowCircleRight size={25} color="currentColor" weight="fill"/>
                            </div>
                        </div>
                    </section>
                    <ul className="w-full flex items-center justify-center pb-24 gap-4">
                        {NavButtons.map((navbutton, index) => (<List className="w-auto" key={index}>

                            <Button
                                onClick={() => buttonClickState(activeModal, navbutton)} type="button"
                                className={`${navbutton.name === "Signup" ? "border-2 border-gray-950 before:top-0" : "before:bottom-0 border-b-2 border-white hover:border-gray-950"}${navbutton.name === "Home" ? 'text-red-600 border-b-gray-950' : ''} py-1.5 px-5 relative z-10 before:content-[''] before:absolute before:left-0 before:w-full before:h-0 before:bg-color2 before:-z-10 hover:before:h-full before:transition-all before:duration-300 before:ease-in text-base`}>{navbutton.name}</Button>
                        </List>))}
                    </ul>
                </div>
            </nav>
            {activeModal != '' && <ModalPopup onClose={() => setActiveModal('')}
                                              children={activeModal == 'login' ?
                                                  <SigninForm onClose={() => setActiveModal('')}/> :
                                                  <RegisterForm onClose={() => setActiveModal('')}/>}
                                              heading={activeModal == 'login' ? "SignIn" : "Signup"}
                                              description={activeModal == 'login' ? "Enter verification ID sent from MAilChamp.Ai" : "Enter your email to register."}/>}
        </header>)
    }
}

export default NavBar