import React, {useEffect, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import './editor.css'
import DropdownList from "./TemplateBuilder/DropdownList";
import Button from '@mui/material/Button';
import './whatsapp.css'
import WhatsappPreview from "./TemplateBuilder/WhatsappPreview";
import QuickReplyButton from "./TemplateBuilder/QuickReplyButton";
import CallToActionButton from "./TemplateBuilder/CallToActionButton";
import axios from "axios";
import {getBaseUrlNodeServer} from "../../config";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {upsertToDb} from "./dbUtils/simpleDbUtils";

export default function WhatsappEditor() {
	const {templateId} = useParams();
	const accessToken = 'EAAZAQxne5hCMBOZC9jkJQ5CNG8ZBo4pjCxod7eiX5o7zWJvDy0fzBkiVDUhKUEZC2ZARHUQZBIIdQQftN6PJ47yZCTH11j0DlwPmGtSWv3LQmlHZCHio7g7r6D7T7TFaowfJS0ICfEU1vydv8jo2uk5DeJeqv3et6NlFZBAOyhpZBw91ySSX1DR4IfUr7psEzYQRLKhfynSCpAy3SPAp1B54ES5ZAr5wM0ZD'
	var signedin = sessionStorage.getItem("signedin");
	const [selectedValue, setSelectedValue] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [inputBodyValue, setinputBodyValue] = useState('');
	const [inputFtrValue, setinputFtrValue] = useState('');
	const [buttons, setButtons] = useState([{}]);
	const [qckBtnText, setqckBtnText] = useState({});
	const [templateName, setTemplateName] = useState(getSomeNameForTemplate())
	const [whatsappBusinessId, setWhatsappBusinessId] = useState('')
	
	function saveWhatsappTemplate() {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		axios.post('/saveWhatsappTemplate', {
			verifiedemail: sessionStorage.getItem("verifiedemail"),
			selectedValue: selectedValue,
			inputValue: inputValue,
			inputBodyValue: inputBodyValue,
			inputFtrValue: inputFtrValue,
			// buttons: JSON.stringify(buttons),
			qckBtnText: qckBtnText,
			templateName: templateName
		});
		toast.success('saved template ', templateName)
	}
	
	function getSomeNameForTemplate() {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
		const day = currentDate.getDate().toString().padStart(2, '0');
		const hours = currentDate.getHours().toString().padStart(2, '0');
		const minutes = currentDate.getMinutes().toString().padStart(2, '0');
		const seconds = currentDate.getSeconds().toString().padStart(2, '0');
		const timestamp = `${year}${month}${day}_${hours}${minutes}${seconds}`;
		const name = `Template_${timestamp}`;
		return name;
	}
	
	const headerOptions = [{value: 'Text', label: 'Text'}, {value: 'Media', label: 'Media'}
	];
	
	function handleInputOnChange2() {setInputValue(document.getElementById('headerTextOrUrlInput').value) }
	
	function handleBodyInputOnChange() {
		setinputBodyValue(document.getElementById('bodyTextlInput').value.replace(/\n/g, '<br/>'))
	}
	
	function handleFooterInputOnChange() {setinputFtrValue(document.getElementById('footerTextlInput').value) }
	
	
	function customSort(a, b) {
		const numericA = parseInt(a.match(/\d+$/)[0]);
		const numericB = parseInt(b.match(/\d+$/)[0]);
		
		if (numericA < numericB) {
			return -1;
		} else if (numericA > numericB) {
			return 1;
		} else {
			return 0;
		}
	}
	
	function getButtonNumber(inputString) {
		const number = inputString.match(/\d+/);
		const extractedNumber = number ? number[0] : -1;
		return extractedNumber
	}
	
	
	function requestApprovalToWhatsapp() {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		axios.post('/requestWhatsappTemplateApproval', {
			verifiedemail: sessionStorage.getItem("verifiedemail"),
			templateId: templateId,
			accessToken: accessToken,
			whatsappBusinessAccountId: '243644282146980',
			templateName:templateName,
			inputValue: inputValue,
			inputBodyValue: inputBodyValue,
			inputFtrValue: inputFtrValue,
			qckBtnText:qckBtnText
		}).then((response) => {
			console.log(response);
		})
	}
	
	useEffect(() => {
		async function getAccessToken() {
			axios.defaults.baseURL = getBaseUrlNodeServer();
			const response = await axios.post('getCollectionRecData', {
				collectionName: 'userdata',
				idQuery: {'email': sessionStorage.getItem("verifiedemail")}
			});
			setWhatsappBusinessId(response.data['whatsappBusinessId']);
		}
		
		getAccessToken();
	});
	
	useEffect(() => {
		function initializeState() {
			if (templateId != undefined) {
				axios.defaults.baseURL = getBaseUrlNodeServer()
				axios.post('/getWhatsappTemplateById', {
					verifiedemail: sessionStorage.getItem("verifiedemail"), templateId: templateId
				}).then((response) => {
					try {
					if (response != undefined && response.data != undefined) {
						const savedTmplt = response.data
						setTemplateName(savedTmplt.templateName);
						setInputValue(savedTmplt.inputValue);
						setinputBodyValue(savedTmplt.inputBodyValue);
						if (document.getElementById('bodyTextlInput') != undefined) {
							document.getElementById('bodyTextlInput').value = savedTmplt.inputBodyValue;
						}
						setinputFtrValue(savedTmplt.inputFtrValue);
						if (document.getElementById('footerTextlInput') != undefined) {
							document.getElementById('footerTextlInput').value = savedTmplt.inputFtrValue;
						}
						setSelectedValue(savedTmplt.selectedValue);
						
						const sortedKeys = Object.keys(savedTmplt.qckBtnText).sort(customSort);
						const sortedDictionary = {};
						sortedKeys.forEach((key) => {
							sortedDictionary[key] = savedTmplt.qckBtnText[key];
						});
						setqckBtnText(sortedDictionary);
						let newButtons = [...buttons];
						sortedKeys.map(key => {
							let newButton;
							if (key.startsWith('call_phone_btn_')) {
								const uniqueKeyUrl = 'phone_no_' + getButtonNumber(key)
								const initializedValue0 = savedTmplt.qckBtnText[key]
								const initializedValue = savedTmplt.qckBtnText[uniqueKeyUrl]
								newButton = ({
									'reactiveComp': <CallToActionButton uniqueKey={key} uniqueKeyUrl={uniqueKeyUrl}
									                                    deleteCurrentButton={handleDeleteCallToAction}
									                                    handleInputOnChange={handleInputOnChange}
									                                    initializedValue0={initializedValue0}
									                                    initializedValue={initializedValue}/>
								});
							} else if (key.startsWith('call_to_action_')) {
								const uniqueKeyUrl = 'url_call_to_action_' + getButtonNumber(key)
								const initializedValue0 = savedTmplt.qckBtnText[key]
								const initializedValue = savedTmplt.qckBtnText[uniqueKeyUrl]
								newButton = ({
									'reactiveComp': <CallToActionButton uniqueKey={key} uniqueKeyUrl={uniqueKeyUrl}
									                                    deleteCurrentButton={handleDeleteCallToAction}
									                                    handleInputOnChange={handleInputOnChange}
									                                    initializedValue0={initializedValue0}
									                                    initializedValue={initializedValue}/>
								});
							} else if (key.startsWith('copy_code_btn_')) {
								const uniqueKeyUrl = 'coupon_code_' + getButtonNumber(key)
								const initializedValue0 = savedTmplt.qckBtnText[key]
								const initializedValue = savedTmplt.qckBtnText[uniqueKeyUrl]
								newButton = ({
									'reactiveComp': <CallToActionButton uniqueKey={key} uniqueKeyUrl={uniqueKeyUrl}
									                                    deleteCurrentButton={handleDeleteCallToAction}
									                                    handleInputOnChange={handleInputOnChange}
									                                    initializedValue0={initializedValue0}
									                                    initializedValue={initializedValue}/>
								});
							} else if (key.startsWith('quick_reply_button_')) {
								const initializedValue = savedTmplt.qckBtnText[key]
								newButton = ({
									'reactiveComp': <QuickReplyButton uniqueKey={key}
									                                  handleInputOnChange={handleInputOnChange}
									                                  initializedValue={initializedValue}/>
								});
							}
							if (newButton) {
								newButtons.push(newButton);
							}
						});
						setButtons(newButtons);
					}
				} catch (e) {
					console.log('error ', e)
				}
				})
			}
		}
		
		initializeState();
	}, [])
	
	useEffect(() => {
		function getTextChange() {
			// console.log('qckBtnText', qckBtnText);
			// Object.keys(qckBtnText).map((key) => {
			// 	if (key.startsWith('quick_reply_button_')) {
			// 		addQuickReplyButtonFromSaved(qckBtnText, key);
			// 	}
				// else if (key.startsWith('call_to_action_')) {
				// 	addCallToActionButtonSaved(qckBtnText, key)
				// } else if (key.startsWith('call_phone_btn_')) {
				// 	addCallPhoneButtonSaved(qckBtnText, key);
				// } else if (key.startsWith('copy_code_btn_')) {
				// 	addCopyCodeButtonSaved(qckBtnText, key)
				// }
			// })
		}
		getTextChange();
		// const intervalId = setInterval(fetchDataFromMongoDB, 20000); // Polling every 5 seconds
		// Cleanup the interval when the component unmounts
		// return () => clearInterval(intervalId);
	}, []);
	
	function addKeyValuePair(key, value) {
		setqckBtnText(prevState => ({
			...prevState,
			[key]: value
		}));
	}
	
	function handleDeleteCallToAction(keyName) {
		// const parentDivName = keyName+'_parent'
		// console.log({qckBtnText});
		// console.log({buttons});
		console.log('I am called to delete', keyName)
	}
	function handleInputOnChange(e) {
		addKeyValuePair(e.target.id, e.target.value)
	}
	
	function addQuickReplyButton() {
		const uniqueKey = `quick_reply_button_${buttons.length + 1}`;
		const newButton = ({
			'reactiveComp': <QuickReplyButton uniqueKey={uniqueKey} handleInputOnChange={handleInputOnChange}/>
		});
		setButtons([...buttons, newButton]);
	}
	
	function addCallToActionButton() {
		const uniqueKey = `call_to_action_${buttons.length + 1}`;
		const uniqueKeyUrl = `url_call_to_action_${buttons.length + 1}`;
		const newButton = ({
			'reactiveComp': <CallToActionButton uniqueKey={uniqueKey}
			                                    uniqueKeyUrl={uniqueKeyUrl}
			                                    deleteCurrentButton={handleDeleteCallToAction}
			                                    qckBtnText={qckBtnText}
			                                    handleInputOnChange={handleInputOnChange}/>
		});
		setButtons([...buttons, newButton]);
	}
	
	function addCopyCodeButton() {
		const uniqueKey = `copy_code_btn_${buttons.length + 1}`;
		const uniqueKeyUrl = `coupon_code_${buttons.length + 1}`;
		const newButton = ({
			'reactiveComp': <CallToActionButton uniqueKey={uniqueKey}
			                                    uniqueKeyUrl={uniqueKeyUrl}
			                                    deleteCurrentButton={handleDeleteCallToAction}
			                                    qckBtnText={qckBtnText}
			                                    handleInputOnChange={handleInputOnChange}/>
		});
		setButtons([...buttons, newButton]);
	}
	
	function addCallPhoneButton() {
		const uniqueKey = `call_phone_btn_${buttons.length + 1}`;
		const uniqueKeyUrl = `phone_no_${buttons.length + 1}`;
		const newButton = ({
			'reactiveComp': <CallToActionButton uniqueKey={uniqueKey}
			                                    uniqueKeyUrl={uniqueKeyUrl}
			                                    deleteCurrentButton={handleDeleteCallToAction}
			                                    qckBtnText={qckBtnText}
			                                    handleInputOnChange={handleInputOnChange}/>
		});
		setButtons([...buttons, newButton]);
	}
	
	function handleInputChange(e) {
		const localVal = e.target.value
		setWhatsappBusinessId(localVal)
		upsertToDb('userdata',
			{'email': sessionStorage.getItem("verifiedemail")}, {'whatsappBusinessId': localVal})
	}
	
	function handleTemplateNameChange(e) {
		setTemplateName(e.target.value);
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div style={{marginLeft: "10px"}}>
				<div style={{color: "grey"}}>Edit / Create Template</div>
				<div>
					<label>Whtspp Business Id:</label>
					<input id='whatsappbusinessid' placeholder='enter whatsappbusinessid'
					       onChange={handleInputChange}
					       value={whatsappBusinessId}
					       style={{
						       border: "1px solid grey", borderRadius: "10px", marginTop: "10px",
						       padding: "10px", marginLeft: "10px", minWidth: "200px"
					       }}/>
				</div>
				{/*<hr style={{marginTop: "10px"}}/>*/}
				<div style={{display: 'flex', marginTop: "10px"}}>
					<div style={{flex: 1, maxWidth: "710px"}}>
						<p style={{textAlign: "center"}}>Category: Marketing</p>
						<div style={{border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", maxWidth: "710px"}}>
							<div style={{marginTop: "10px"}}>
								<label>Name:</label>
								<input id={'templateNameInput'} value={templateName}
								       onChange={handleTemplateNameChange}
								       style={{
									       border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginLeft: "60px", minWidth: "370px"
								       }}/>
							</div>
							<hr style={{margin: "10px"}}/>
							<div style={{display: "flex", marginTop: "-10px"}}><DropdownList labelText='Header Type:' selectedValue={selectedValue}
							                                                                 setSelectedValue={setSelectedValue}
							              options={headerOptions}/>
								<label style={{marginTop: "15px", marginLeft: "30px"}}>Languages: en</label>
							</div>
							<div style={{marginTop: "10px", display: "flex"}}>
								<label style={{width: "80px", wordWrap: "break-word"}}>Header Text / Url:</label>
								<input id='headerTextOrUrlInput' onChange={handleInputOnChange2}
								       value={inputValue}
								       style={{
									       border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginLeft: "27px",
									       minWidth: "454px"
								       }}/>
								<label style={{marginLeft: "10px", marginTop: "10px"}}>{inputValue.length}/60 chars</label>
							</div>
							{/*<hr style={{margin: "5px"}}/>*/}
							<div style={{verticalAlign: "middle", display: "flex"}}>
								<div style={{marginTop: "50px", minWidth: "70px"}}>Body Text:</div>
								<textarea id='bodyTextlInput' onChange={handleBodyInputOnChange} style={{
									border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginLeft: "40px", marginTop: "10px",
									minWidth: "500px", minHeight: "100px"
								}}/>
								<div style={{marginTop: "50px", marginLeft: "10px"}}>{inputBodyValue.length}/1024 chars</div>
							</div>
							<div style={{verticalAlign: "middle", display: "flex"}}>
								<div style={{marginTop: "19px"}}>Footer Text:</div>
								<textarea id='footerTextlInput' onChange={handleFooterInputOnChange} style={{
									border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginLeft: "33px", marginTop: "10px",
									minWidth: "385px", maxHeight: "40px"
								}}/>
								<label style={{marginLeft: "10px", marginTop: "20px"}}>{inputFtrValue.length}/60 chars</label>
							</div>
							<hr style={{margin: "5px"}}/>
							<div style={{marginTop: "10px"}}>
								<Button variant='outlined' style={{fontSize: "12px"}} onClick={addQuickReplyButton}>+ Quick Reply</Button>
								<Button variant='outlined' style={{fontSize: "12px", marginLeft: "20px"}} onClick={addCallToActionButton}>+ External
									Link</Button>
								<Button variant='outlined' style={{fontSize: "12px", marginLeft: "20px"}} onClick={addCopyCodeButton}>+ Copy
									Code</Button>
								<Button variant='outlined' style={{fontSize: "12px", marginLeft: "20px"}} onClick={addCallPhoneButton}>+ Call
									Phone</Button>
							</div>
							
							{buttons.map((button) => (button.reactiveComp))}
						</div>
					</div>
					<div style={{minWidth: "410px"}}>
						<WhatsappPreview inputValue={inputValue} inputBodyValue={inputBodyValue} inputFtrValue={inputFtrValue}
						                 qckBtnText={qckBtnText}
						/>
						<div style={{marginTop: '20px', marginLeft: "20px"}} onClick={saveWhatsappTemplate}>
							<Button variant='outlined' style={{fontSize: "12px"}}>Save</Button>
						</div>
						<div style={{marginTop: '20px', marginLeft: "20px"}}>
							<Button variant='outlined' style={{fontSize: "12px"}} onClick={requestApprovalToWhatsapp}>Request Approval</Button>
						</div>
					</div>
				</div>
				
				{/*<DndProvider backend={HTML5Backend}>*/}
				{/*	<div style={{*/}
				{/*		display: 'flex', justifyContent: 'space-between', paddingBottom: "20px",*/}
				{/*		marginTop: "200px", marginRight: "20px",*/}
				{/*		minHeight: "80vh"*/}
				{/*	}}>*/}
				{/*		<LeftMenu/>*/}
				{/*		<RightPane droppedItems={droppedItems} handleDrop={handleDrop}/>*/}
				{/*	</div>*/}
				{/*</DndProvider>*/}
			</div>
		)
	}
}