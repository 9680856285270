import React, {Component} from 'react'
import rise2020 from "../Blogs/images/2020rise.png";

export default class CovidImpact extends Component {
	state = {
		confirming: true
	}
	componentDidMount = () => {
		this.setState({confirming: false})
	}
	render = () => {
		return (
			
			<div className='row' style={{
				display: "flex",
				justifyContent: "center",
				// maxWidth: "70%",
			}}>
				<div
					className='col-sm'
					style={{
						display: "flex",
						// justifyContent: "center",
						fontFamily: 'Roboto, sans-serif',
						fontWeight: 500,
						border: '0.2px solid lightgray',
						padding: '10px 20px',
						boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',
						borderRadius: '20px',
						margin: '0 20px',
						// width: '40%',
					}}
				>
					<div style={{display: "flex"}}>
						<div>
							<p style={{fontFamily: 'Roboto,sans-serif', color: 'gray', fontSize: 20}}>
								Covid'19 Impact in 2020 Company Registrations</p>
							<li style={{listStyleType: 'none', fontSize: 16}}>Government data shows there is a 200% jump in new company registrations
								in
								many
								sectors.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}> Business services, Trading, Community kind of services jumped in
								offerings
								during Covid'19.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>While the Transport sector increased marginally, this poses a socpe of
								high
								increase in this sector in 2021.
							</li>
							<li style={{listStyleType: 'none', fontSize: 16}}>Following graph shows sector by sector breakdown of the increase.
							</li>
						</div>
						<img
							width={600}
							height={400}
							className="mr-3"
							src={rise2020}
							alt="Rise of Registrations in 2020 by Industrial Sector, Covid\'19'"
						/>
					</div>
					<div>
					
					</div>
				</div>
				{/*<div*/}
				{/*	className='col-sm'*/}
				{/*	style={{*/}
				{/*		fontFamily: 'Roboto, sans-serif',*/}
				{/*		fontWeight: 500,*/}
				{/*		border: '0.2px solid lightgray',*/}
				{/*		padding: '10px 20px',*/}
				{/*		boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.2)',*/}
				{/*		borderRadius: '20px',*/}
				{/*		margin: '0 20px',*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<img*/}
				{/*		width={600}*/}
				{/*		height={400}*/}
				{/*		className="mr-3"*/}
				{/*		src={rise2020}*/}
				{/*		alt="Rise of Registrations in 2020 by Industrial Sector, Covid\'19'"*/}
				{/*	/>*/}
				{/*</div>*/}
			</div>
		)
	}
}