import React, {Component} from 'react'
import axios from 'axios';
import {getAxiosBaseUrl} from "../config";

export default class EmailVerify extends Component {
	state = {
		confirming: true, sendingEmail: false, serverMessage: ""
	}
	componentDidMount = () => {
		this.setState({ confirming: false })
		this.setState({ serverMessage: "" })
	}

	onSubmit = event => {
		this.setState({ serverMessage: "" })
		event.preventDefault()
		this.setState({ sendingEmail: true })
		axios.defaults.baseURL = getAxiosBaseUrl();
		axios.post('/onboard_email', { 'user_email': this.email.value })
			.then(response => {
				// console.log(response.data);
				this.setState({ sendingEmail: false });
				this.setState({ serverMessage: response.data.identities })
			}
			).catch(err => console.log(err));
	}

	render = () => {
		const { sendingEmail } = this.state
		return (<div style={{ marginTop: 0, marginLeft: 0 }}>
			<div>
				<form id='emailverifyform' onSubmit={this.onSubmit} ref={form => this.form = form} style={{ marginBottom: "10px" }}>
					<div className='row' style={{ textAlign: 'center', marginLeft: "30px" }}>
						{/*<label htmlFor='email'>Enter</label>*/}
						<input type='email' name='email' ref={input => this.email = input} placeholder='Email ...'
							required style={{ lineHeight: 2, textAlign: "center", marginLeft: 10, width: 300, border: "1px solid gray", borderRadius: "10px"}} />
						<button type='submit' className='btn' disabled={sendingEmail}
							style={{ backgroundColor: "#e8f1ff", marginLeft: 10, lineHeight: 1.5 }}>
							{sendingEmail ? "Sending Email ... "
								// <Spinner size='lg' spinning='spinning'/>
								: "Register"}
						</button>
					</div>
				</form>
			</div>
			<div style={{ textAlign: 'center', color: 'blue' }}><span>{this.state.serverMessage}</span></div>
		</div>)
	}
}