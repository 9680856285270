import './App.css';
import React, {Component} from 'react'
import Spinner from './Components/Spinner'
import Pricing from './Components/Pricing'
import {Route, Routes} from 'react-router-dom'
import DataContext from './Components/Context/DataContext';
import {ToastContainer} from 'react-toastify';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {animateScroll} from "react-scroll";
import NavBar from "./Components/organs/NavBar.tsx"
import NewFooter from "./Components/organs/Footer.tsx"
import Home from "./Components/pages/Home";
import AboutUs from "./Components/AboutUs";
import Flows from "./Components/Flows/Flows";
import BlogPage from "./Components/Blogs/BlogPage";
import Demo from "./Components/Demo/Demo";
import EditorAlone from "./Components/Demo/EditorAlone";
import Confirm from "./Components/Confirm";
import Article from "./Components/Blogs/Article";
import Landing from "./Components/Landing";
import Dashboard from "./Components/Dashboard/dashboard";
import Contacts from "./Components/Contacts/Contacts";
import Campaign from "./Components/Campaign/Campaign";
import MediaUpload from "./Components/MediaUpload/MediaUpload";
import UnSubscribe from "./Components/Subscriptions/UnSubscribe";
import Subscribe from "./Components/Subscriptions/Subscribe";
import LandingPage from "./Components/GetADemo/landingPage";
import BlogHome from "./Components/Blogs/BlogHome";
import MCSideBar from "./Components/SideBar/MCSideBar";
import AllTemplates from "./Components/Demo/AllTemplates";
import Account from "./Components/account/account";
import SavedTemplates from "./Components/Demo/SavedTemplates";
import AdminPanel from "./Components/account/adminpanel";
import Whatsapp from './Components/Whatsapp/whatsapp.js'
import WhatsappTemplates from './Components/Whatsapp/whatsappTemplates'
import WhatsappEditor from "./Components/Whatsapp/whatsappEditor";
import MyTemplates from "./Components/Whatsapp/myTemplates";
import Shopify from "./Components/Integrations/Shopify";
import JobPool from "./Components/business-solutions/JobPool";
import MyApplications from "./Components/business-solutions/MyApplications";

export default class App extends Component {
	state = {
		loading: true, contextData: null, signedin: false, verificationid: '', verifiedemail: '', isAdmin: false,
		adminList: ['admin@mailchamp.ai']
	}
	setContextData = (newData) => {
		this.setState({contextData: newData});  // Update method to set contextData
	};
	componentDidMount = () => {
		this.setState({loading: false});
		animateScroll.scrollToTop({
			duration: 0,
		});
		var signedin = sessionStorage.getItem("signedin");
		var verificationid = sessionStorage.getItem("verificationid");
		var verifiedemail = sessionStorage.getItem("verifiedemail");
		
		if (signedin == null || signedin == false) {
			this.setState({signedin: false})
		} else {
			this.setState({signedin: true})
			this.setState({verificationid: verificationid})
			if (this.state.adminList.includes(verifiedemail)) {
				sessionStorage.setItem('isAdmin', true)
				this.setState({isAdmin: true})
			}
			this.setState({verifiedemail: verifiedemail})
		}
	}
	
	
	handleLogOut() {
		//Delete user data //must
		sessionStorage.removeItem("signedin");
		sessionStorage.removeItem("verificationid");
		sessionStorage.removeItem("verifiedemail");
		window.location.reload()
	}
	
	render = () => {
		const {contextData} = this.state;
		const commonContent = () => {
			if (this.state.loading) {
				return <Spinner size='8x' spinning='spinning'/>
			}
			return (<DataContext.Provider value={{contextData, setContextData: this.setContextData}}>
				<Routes>
					{/*<Route exact path='/' element={<Home/>}/>*/}
					<Route exact path='/demo' element={<Demo/>}/>
					<Route exact path='/whatsapp' element={<Whatsapp/>}/>
					<Route exact path='/shopify' element={<Shopify/>}/>
					<Route exact path='/jobpool' element={<JobPool/>}/>
					<Route exact path='/jobpool' element={<JobPool/>}/>
					<Route exact path='/myapplications' element={<MyApplications/>}/>
					<Route exact path='/whatsapptemplates' element={<WhatsappTemplates/>}/>
					<Route exact path='/MyTemplates' element={<MyTemplates/>}/>
					<Route exact path='/whatsappeditor' element={<WhatsappEditor/>}/>
					<Route exact path='/whatsappeditor/:templateId' element={<WhatsappEditor/>}/>
					<Route exact path='/editor' element={<EditorAlone/>}/>
					<Route exact path='/editor/:templateId' element={<EditorAlone/>}/>
					<Route exact path='/account' element={<Account/>}/>
					<Route exact path='/pricing' element={<Pricing/>}/>
					<Route exact path='/about' element={<AboutUs/>}/>
					<Route exact path='/flows' element={<Flows/>}/>
					<Route exact path='/blogs' element={<BlogPage/>}/>
					<Route exact path='/blog' element={<BlogHome/>}/>
					{/*/!*articles={yourArticlesArray}*!/*/}
					<Route exact path='/confirm/:id' element={<Confirm/>}/>
					<Route exact path='/blog/article/:id' element={<Article/>}/>
					<Route exact path='/landing' element={<Landing/>}/>
					<Route exact path='/dashboard' element={<Dashboard verifiedemail={this.state.verifiedemail}/>}/>
					{/*<Route exact path='/features' element={<Features/>}/>*/}
					<Route exact path='/contacts' element={<Contacts/>}/>
					<Route exact path='/campaign' element={<Campaign/>}/>
					<Route exact path='/mediaupload' element={<MediaUpload/>}/>
					
					<Route exact path='/unsubscribe' element={<UnSubscribe/>}/>
					<Route exact path='/subscribe' element={<Subscribe/>}/>
					<Route exact path='/alltemplates' element={<AllTemplates/>}/>
					<Route exact path='/savedtemplates' element={<SavedTemplates/>}/>
					<Route exact path='/adminpanel' element={<AdminPanel verifiedemail={this.state.verifiedemail}/>}/>
					
					{/*/!*<Redirect from='*' to='/'/>*!/*/}
					<Route path="/lp" element={<LandingPage/>}/>
				</Routes>
			</DataContext.Provider>)
		}
		const loggedInContent = () => {
			if (this.state.loading) {
				return <Spinner size='8x' spinning='spinning'/>
			}
			return (<DataContext.Provider value={{contextData, setContextData: this.setContextData}}>
				<Routes>
					<Route exact path='/' element={<Campaign/>}/>
				</Routes>
			</DataContext.Provider>)
		}
		const notLoggedInContent = () => {
			if (this.state.loading) {
				return <Spinner size='8x' spinning='spinning'/>
			}
			return (<DataContext.Provider value={{contextData, setContextData: this.setContextData}}>
				<Routes>
					<Route exact path='/' element={<Home/>}/>
				</Routes>
			</DataContext.Provider>)
		}
		
		
		return (<div className='app-container'>
			<NavBar signedin={this.state.signedin} verifiedemail={this.state.verifiedemail}/>
			{/*<BasicExample/>*/}
			{/*<Sidebar>*/}
			{/*	<Menu>*/}
			{/*		<SubMenu label="Charts">*/}
			{/*			<MenuItem> Pie charts </MenuItem>*/}
			{/*			<MenuItem> Line charts </MenuItem>*/}
			{/*		</SubMenu>*/}
			{/*		<MenuItem> Documentation </MenuItem>*/}
			{/*		<MenuItem> Calendar </MenuItem>*/}
			{/*	</Menu>*/}
			{/*</Sidebar>;*/}
			{this.state.signedin ? null :
				<main style={{marginTop: 0}}> {notLoggedInContent()} </main>
			}
			<div style={this.state.signedin ? {display: "flex", height: "100vh", marginTop: "0px"} : {}}>
				<div style={{}}>
					<MCSideBar signedin={this.state.signedin} verifiedemail={this.state.verifiedemail}
					           isAdmin={this.state.isAdmin}/>
				</div>
				<section style={this.state.signedin ? {flex: 1, overflowY: "auto"} : {}}>
					{this.state.signedin ? <main style={{marginTop: 0}}>{loggedInContent()} </main> : <div style={{display: "none"}}/>}
					<main style={{marginTop: 0, marginBottom: "0px", marginRight: "40px", marginLeft: "10px"}}> {commonContent()} </main>
				</section>
			</div>
			
			<ToastContainer/>
			{this.state.signedin ? null : <NewFooter/>}
			{/*<div className="w-full text-gray-950 font-poppins">*/}
			{/*<div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-black to-blue-200">*/}
			{/*	<h1 className="text-white text-4xl">Welcome to Our Landing Page</h1>*/}
			{/*	<p className="text-white text-lg mt-4">This is a simple Tailwind CSS-based landing page.</p>*/}
			{/*</div>*/}
			{/*<button type='submit' onClick={this.handleLogOut} className='btn' style={{*/}
			{/*	//Must pass onclick as a function, otherwise the rendering will keep logging the user out*/}
			{/*	backgroundColor: "#e8f1ff",*/}
			{/*	marginLeft: 10,*/}
			{/*	lineHeight: 1.5,*/}
			{/*	width: '100px',*/}
			{/*	display: sessionStorage.getItem("signedin") ? 'block' : 'none',*/}
			{/*	position: 'absolute',*/}
			{/*	top: 70,*/}
			{/*	right: 300,*/}
			{/*	zIndex: 10000*/}
			{/*}}> Logout*/}
			{/*</button>*/}
			
			{/*<BlogStripe/>*/}
			{/*<Footer/>*/}
			{/*</div>*/}
		</div>)
	}
}