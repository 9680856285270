import React, {useEffect, useState} from 'react';
import axios from "axios";
import {toast} from 'react-toastify';
import './Campaign.css'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {getBaseUrlNodeServer} from "../../config";
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

const NewCampaign = ({onChildAction, setcurrentCampaign}) => {
	const verifiedemail = sessionStorage.getItem("verifiedemail");
	const [isLoading, setIsLoading] = useState(true);
	const [usercampaigns, setusercampaigns] = useState([]);
	
	useEffect(() => {
		async function getCampaigns() {
			try {
				axios.defaults.baseURL = getBaseUrlNodeServer()
				axios.post('getcampaigns', {'verifiedemail': verifiedemail}).then(response => {
						const usercampaigns = response.data
						setusercampaigns(usercampaigns);
						setIsLoading(false);
						// console.log('usercampaigns', usercampaigns);
					}
				).catch(err => console.log(err));
			} catch (error) {
				console.error('Error during fetch:', error.message);
			}
		}
		
		getCampaigns();
	}, []);
	
	async function getSubjectForTmpltId(templateId) {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		const response = axios.post('getusertemplate/' + templateId)
		return response.data[0].subject;
	}
	
	function onSubmit(event) {
		event.preventDefault();
		axios.defaults.baseURL = getBaseUrlNodeServer()
		const campaignname = document.getElementById('campaignname').value
		axios.post('addcampaign', {'verifiedemail': verifiedemail, 'campaignname': campaignname})
		.then(response => {
				// console.log(response.data);
			}
		).catch(err => console.log(err));
		axios.post('getcampaigns', {'verifiedemail': verifiedemail}).then(response => {
				const usercampaigns = response.data
				setusercampaigns(usercampaigns);
			}
		).catch(err => console.log(err));
		toast.success('saving campaign');
	}
	
	function getCampaignStatuscolor(campaignStatus) {
		if (campaignStatus === undefined || campaignStatus === null) {
			return 'black';
		}
		switch (campaignStatus.toString()) {
			case 'Paused':
				return 'grey';
			case 'Ready':
				return 'red'
			case 'Running':
				return 'blue'
			default:
				return 'black';
		}
	}
	
	function deleteCampaign(campaign) {
		axios.defaults.baseURL = getBaseUrlNodeServer()
		axios.post('deletecampaign', {'verifiedemail': verifiedemail, 'campaignname': campaign.campaignname})
		.then(response => {
				console.log(response.data);
			}
		).catch(err => console.log(err));
		axios.post('getcampaigns', {'verifiedemail': verifiedemail}).then(response => {
				const usercampaigns = response.data
				setusercampaigns(usercampaigns);
			}
		).catch(err => console.log(err));
	}
	
	function clickedCampaign(campaign) {
		setcurrentCampaign(campaign);
		onChildAction(1);
	}
	
	return (
		<div style={{textAlign: 'center', marginTop: '20px'}}>
			<div style={{display: "flex"}}>
				<form onSubmit={onSubmit} style={{marginBottom: "10px"}} id='newcampaignform'>
					<div style={{textAlign: 'center', marginLeft: "30px"}}>
						<Stack spacing={2} direction="row">
							<input type='text' id='campaignname' placeholder='Enter Campaign Name' required
							       style={{
								       lineHeight: 2,
								       textAlign: "center",
								       marginLeft: 10,
								       width: 300,
								       border: "1px solid gray",
								       borderRadius: "10px"
							       }}/>
							<Button variant="outlined" type='submit' className='btn'>
								Add Campaign</Button>
						</Stack>
					</div>
				</form>
			</div>
			<hr/>
			{/*<h5 className="text-sm text-center text">Your Campaigns</h5>*/}
			<div className="dynamic-table" style={{maxWidth: "1400px", marginTop: "0px"}}>
				<div className="table-row-header" style={{marginBottom: '-2px'}}>
					<label className="table-cell" style={{marginLeft: '-0px'}}>Campaign Name</label>
					<label className="table-cell" style={{}}>Status</label>
					<label className="table-cell" style={{}}>Leads</label>
					<label className="table-cell" style={{}}>Sent</label>
					<label className="table-cell" style={{}}>Open</label>
					<label className="table-cell" style={{}}>Click</label>
					<label className="table-cell" style={{}}>Bounce</label>
					<label className="table-cell" style={{width: '80px', wordWrap: 'break-word'}}>
						UnSubscribed</label>
					<label className="table-cell" style={{}}>Template Id</label>
					<label className="table-cell" style={{}}>Subject</label>
				</div>
				<div>
					{usercampaigns.map((campaign, rowIndex) => (
						<div style={{display: 'flex'}}>
							<div key={rowIndex} className="table-row" title="Click to Edit Campaign" onClick={() => clickedCampaign(campaign)}>
								<label className="table-cell" style={{fontWeight: 'bold'}}> {campaign.campaignname}</label>
								<label className="table-cell" style={{color: getCampaignStatuscolor(campaign.Status)}}>
									{campaign.Status}</label>
								<label className="table-cell" style={{color: campaign.targetLeads !== undefined ? 'blue' : 'black'}}>
									{campaign.targetLeads !== undefined && campaign.targetLeads != null ? campaign.targetLeads.length : 0}</label>
								<label className="table-cell"> {campaign.Sent !== undefined ? campaign.Sent : 0}</label>
								<label className="table-cell"> {campaign.Open}</label>
								<label className="table-cell"> {campaign.Click}</label>
								<label className="table-cell"> {campaign.Bounce}</label>
								<label className="table-cell"> {campaign.UnSubscribe}</label>
								<label className="table-cell" style={{
									maxWidth: '160px', fontSize: '11px',
									wordWrap: 'break-word'
								}}> {campaign.seletectTemplateId != undefined ? campaign.seletectTemplateId : '-'}</label>
								
								<label
									style={{marginTop: '20px'}}> {'-'}</label>
								{/*onChange={(e) => updateCell(rowIndex, cellIndex, e.target.value)}/>*/}
							</div>
							<Button type='submit' title='Delete Campaign' onClick={() => deleteCampaign(campaign)}
							        style={{marginTop: '13px', height: '30px', fontSize: '13px', marginLeft: '0px'}}>
								<DeleteOutlined/>
							</Button>
						</div>
					))}
				</div>
			</div>
		</div>
	)
};

export default NewCampaign;
