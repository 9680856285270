import React, {useEffect, useRef, useState} from "react";
import {SigninForm} from "../molecules/SigninForm";
import axios from "axios";
import {getAxiosBaseUrl, getBaseUrlNodeServer} from "../../config";
import {upsertToDb} from "../Whatsapp/dbUtils/simpleDbUtils";

export default function Shopify() {
	var signedin = sessionStorage.getItem("signedin");
	const [accessToken, setaccessToken] = useState('');
	const [isVisible, setIsVisible] = useState(false);
	const scrollableContainerRef = useRef(null);
	const [authUrl, setAuthUrl] = useState('');
	const [shopifyPermanentAccessToken, setshopifyPermanentAccessToken] = useState('')
	
	useEffect(() => {
		function getAccessToken() {
			axios.defaults.baseURL = getBaseUrlNodeServer();
			axios.post('getCollectionRecData', {
				collectionName: 'userdata',
				idQuery: {'email': sessionStorage.getItem("verifiedemail")}
			}).then(response => {
				if ('shopifyStoreName' in response.data) {
					setaccessToken(response.data['shopifyStoreName']);
					if ('shopifyPermanentAccessToken' in response.data) {
						setshopifyPermanentAccessToken(response.data['shopifyPermanentAccessToken'])
					} else {
						axios.defaults.baseURL = getAxiosBaseUrl();
						axios.post('/getShopifyAuthUrlForShopName', {
							'shopName': response.data['shopifyStoreName'],
							'verifiedemail': sessionStorage.getItem("verifiedemail")
						})
						.then(response => {
							setAuthUrl(response.data['auth_url']);
						}).catch(err => console.log(err));
					}
				} else {
					setaccessToken('add your shopify store name');
				}
				
			})
		}
		
		getAccessToken();
	}, []);
	
	function handleInputChange(e) {
		const localToken = e.target.value
		setaccessToken(localToken);
		//Sync to the database as well
		upsertToDb('userdata', {'email': sessionStorage.getItem("verifiedemail")}, {'shopifyStoreName': localToken})
	}
	
	if (!signedin) {
		return <div style={{marginTop: "300px"}}>
			<SigninForm/>
		</div>
	} else {
		return (<div ref={scrollableContainerRef} style={{
			marginTop: "10px", marginLeft: "0px", minHeight: "0px", overflow: isVisible ? "scroll" : "hidden",
		}}>
			<div style={{display: "flex"}}>
				<p>Connect Shopify Store:</p>
				<div style={{fontSize: "9px", marginTop: "6px", marginLeft: "100px"}}>Your information is private and secure</div>
			</div>
			
			<div style={{marginTop: "10px"}}>
				<div style={{width: "100px", float: "left", color: 'grey', marginTop: "8px"}}><label>Shop Name:</label></div>
				<input id='accessTokenHolderInputId' placeholder='enter your shopify store name' onChange={handleInputChange}
				       value={accessToken}
				       style={{
					       height: "40px",
					       minWidth: "350px",
					       border: "1px solid lightgrey",
					       textAlign: "center",
					       color: "grey",
					       borderRadius: "10px",
					       padding: "10px"
				       }} required={true}
				></input>
			</div>
			
			{/*<div style={{marginTop: "10px"}}>*/}
			{/*	<div style={{width: "100px", float: "left", color: 'grey', marginTop: "8px"}}><label>PhoneNo:</label></div>*/}
			{/*	<input id='PhoneNo' placeholder='phone number from whatsapp manager account'*/}
			{/*	       onChange={handlePhoneNoInputChange}*/}
			{/*	       value={phoneNo}*/}
			{/*	       style={{*/}
			{/*		       height: "40px",*/}
			{/*		       minWidth: "350px",*/}
			{/*		       border: "1px solid lightgrey",*/}
			{/*		       textAlign: "center",*/}
			{/*		       color: "grey",*/}
			{/*		       borderRadius: "10px"*/}
			{/*	       }} required={true}*/}
			{/*	></input>*/}
			{/*</div>*/}
			
			{/*<div style={{marginTop: "10px"}}>*/}
			{/*	<div style={{width: "100px", float: "left", color: 'grey', marginTop: "8px"}}><label>PhoneNoId:</label></div>*/}
			{/*	<input id='phoneNoId' placeholder='phone number id from whatsapp manager account'*/}
			{/*	       onChange={handlePhoneNoIdInputChange}*/}
			{/*	       value={phoneNoId}*/}
			{/*	       style={{*/}
			{/*		       height: "40px",*/}
			{/*		       minWidth: "350px",*/}
			{/*		       border: "1px solid lightgrey",*/}
			{/*		       textAlign: "center",*/}
			{/*		       color: "grey",*/}
			{/*		       borderRadius: "10px"*/}
			{/*	       }} required={true}*/}
			{/*	></input>*/}
			{/*</div>*/}
			
			{/*<div style={{display: "flex"}}>*/}
			{/*	<div style={{width: "100px", float: "left", color: 'grey', marginTop: "20px", marginRight: "20px"}}>*/}
			{/*		<label>TemplateName:</label>*/}
			{/*	</div>*/}
			{/*	<input id='templateNameFieldInput' type='text' onChange={changeTemplateNameFieldId} style={{*/}
			{/*		height: "40px",*/}
			{/*		minWidth: "200px",*/}
			{/*		border: "1px solid lightgrey",*/}
			{/*		textAlign: "center",*/}
			{/*		color: "grey",*/}
			{/*		borderRadius: "10px",*/}
			{/*		marginTop: "10px"*/}
			{/*	}} required={true}*/}
			{/*	></input>*/}
			{/*</div>*/}
			{/*<div style={{display: "flex"}}>*/}
			{/*	<div style={{width: "100px", float: "left", color: 'grey', marginTop: "20px", marginRight: "20px"}}><label>Send To:</label>*/}
			{/*	</div>*/}
			{/*	<input typeof='tel' id='sendToPhoneNo' type="phone" style={{*/}
			{/*		height: "40px",*/}
			{/*		minWidth: "200px",*/}
			{/*		border: "1px solid lightgrey",*/}
			{/*		textAlign: "center",*/}
			{/*		color: "grey",*/}
			{/*		borderRadius: "10px",*/}
			{/*		marginTop: "10px"*/}
			{/*	}} required={true}*/}
			{/*	></input>*/}
			{/*</div>*/}
			{/*<Button variant='outlined' style={{*/}
			{/*	border: "1px solid", marginTop: "10px", height: "30px", marginLeft: "120px",*/}
			{/*	textTransform: "none"*/}
			{/*}}*/}
			{/*        type="submit" disabled={false}*/}
			{/*        onClick={sendWhatsappMsg}*/}
			{/*>*/}
			{/*	Send Message*/}
			{/*</Button>*/}
			
			<div style={{marginLeft: "10px", marginTop: "10px"}}>Do Not Refresh Page when verifying</div>
			<div style={{marginLeft: "10px", marginTop: "10px"}}>Auth Url:
				<a href={authUrl} target="_blank" style={{
					marginLeft: "20px", color: "lightskyblue", fontWeight: "bold", textDecoration: "underline"
				}}>Integrate Your Store Now</a>
				
				<p>{shopifyPermanentAccessToken}</p>
			</div>
		</div>)
	}
}