// @ts-ignore
import {Envelope, Person, Phone} from '@phosphor-icons/react'
import {Input} from '../atoms/Input'
// import {Button} from '../atoms/Button'
import Button from '@mui/material/Button'
import {ContactUsTexts} from '../particles/DataLists'
import * as React from 'react'
import {toast} from "react-toastify";
import axios from "axios";
import {getAxiosBaseUrl} from "../../config";

export const ContactForm = () => {
    function submitDataForDemo(event) {
        event.preventDefault();
        let emailid = document.getElementById('emailid').value
        let fullname = document.getElementById('fullname').value
        let msg = document.getElementById('msg').value
        axios.defaults.baseURL = getAxiosBaseUrl()
        axios.post('/demorequest', {
            emailid: emailid,
            fullname: fullname,
            msg: msg
        });
        document.getElementById('emailid').value = ''
        document.getElementById('fullname').value = ''
        document.getElementById('msg').value = ''

        toast.success("Thank You !! We will reach back to you soon!");
    }
    return (
        <form id='demorequestform' className='flex flex-col gap-4' onSubmit={submitDataForDemo}>
            <div className='flex md:flex-row flex-col items-stretch gap-2'>
                <Input id='emailid' required={true} containerClass='relative'
                       inputClass='border rounded-lg outline-none w-[300px] h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1'
                       type="email" placeholder={ContactUsTexts.emailPlaceholderText}>
                <div className='absolute top-4 left-3 text-color3/80'>
                    <Envelope size={18} color="currentColor" weight="fill" />
                </div>
            </Input>
                <Input id='fullname' required={true} containerClass='relative'
                       inputClass='border rounded-lg outline-none w-[300px] h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1'
                       type="text" placeholder={ContactUsTexts.namePlaceholderText}>
                <div className='absolute top-4 left-3 text-color3/80'>
                    <Person size={18} color="currentColor" weight="fill" />
                </div>
            </Input>
            </div>
            <div className='flex md:flex-row flex-col items-stretch gap-2'>
                {/*<Input containerClass='relative' inputClass='border rounded-lg outline-none w-[300px] h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1' type="tel" placeholder={ContactUsTexts.phonePlaceholderText}>*/}
                {/*    <div className='absolute top-4 left-3 text-color3/80'>*/}
                {/*        <Phone size={18} color="currentColor" weight="fill" />*/}
                {/*    </div>*/}
                {/*</Input>*/}
                <Input id='msg' required={true} containerClass='relative'
                       inputClass='border rounded-lg outline-none w-[300px] h-[50px] focus:outline-none text-color3 pr-4 pl-9 py-1'
                       type="text" placeholder={ContactUsTexts.messagePlaceholderText}>
                <div className='absolute top-4 left-3 text-color3/80'>
                    <Envelope size={18} color="currentColor" weight="fill" />
                </div>
            </Input>
            </div>
            <div className='flex md:flex-row flex-col justify-center gap-2'>
                <Button variant="outlined" type='submit'
                        className='border outline-none bg-color1 py-2 px-6 text-white font-light text-base rounded-lg'
                >
                {ContactUsTexts.buttonText}
            </Button>
            </div>
        </form>
    )
}
